import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SupportRoutingModule } from '@modules/support/support-routing.module';
import { SharedModule } from '@shared/shared.module';

import { SupportPage } from './pages/support/support.page';
import { SupportService } from './support.service';

@NgModule({
  declarations: [SupportPage],
  imports: [CommonModule, SharedModule, SupportRoutingModule],
  providers: [SupportService],
})
export class SupportModule {}
