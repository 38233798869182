import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FaqRoutingModule } from '@modules/faq/faq-routing.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { SharedModule } from '@shared/shared.module';

import { FaqService } from './faq.service';
import { FaqPage } from './pages/faq/faq.page';

@NgModule({
  declarations: [FaqPage],
  imports: [CommonModule, SharedModule, FaqRoutingModule, NgSelectModule],
  providers: [FaqService],
})
export class FaqModule {}
