 <nav id="step4" class="sidebar" [ngClass]="isCollapsedNav ? 'state-collapsed' : ''">
   <ul class="sidebar-list" *ngIf="links" >
    <li class="sidebar-list-group" >
      <div class="sidebar-list-item">
        <a class="link" [routerLink]="['/dashboard']" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true, isActive: true }">
        <span class="link-class"><i class="nd-th-1"></i></span>
        <span [ngClass]="isCollapsedNav ? 'hidden' : ''">{{ 'sidebar.dashboard' | translate }}</span>
      </a>
      </div>
    </li>
    <li class="sidebar-list-group" *ngFor="let group of links" >
       <div class="sidebar-title">
         <span [ngClass]="isCollapsedNav ? 'hidden' : ''">{{group.key | translate}}</span>
       </div>
       <div *ngFor="let link of group.value" class="sidebar-list-item">
         <!--CASE 1: Check if link is external and has url and show this template-->
         <ng-container *ngIf="link.external && link.url">
          <a
          *ngIf="!isLinkShouldLooksLikeExternal(link.url)"
          class="link"
          [routerLink]="link.url"
          [routerLinkActiveOptions]="{ exact: true, isActive: true }"
          routerLinkActive="active"
        >
          <ng-container *ngIf="link.iconType ==='class'; else iconImage">
             <span class="link-class">
               <fa-icon [icon]="link.iconClass"></fa-icon>
             </span>
          </ng-container>
          <ng-template #iconImage>
            <ng-container *ngIf="link.iconUrl; else default">
              <div class="link-img-wrapper">
                 <img [src]="link.iconUrl"
                 [alt]="link.iconImage"
                 class="link-img">
              </div>
            </ng-container>
          </ng-template>
          <ng-template #default>
             <span *ngIf="isCollapsedNav; else defaultImage"
                   title="{{ link.translate ? (link.name | translate) : link.name }}">
               <span class="link-class"><i class="nd-folder"></i></span>
             </span>
          </ng-template>

          <span [ngClass]="isCollapsedNav ? 'hidden' : ''">
            {{ link.translate ? (link.name | translate) : link.name }}
         </span>
         </a>
           <a
            *ngIf="isLinkShouldLooksLikeExternal(link.url)"
             class="link"
             [href]="link.url"
             [target]="_blank"
           >
             <ng-container *ngIf="link.iconType ==='class'; else iconImage">
                <span class="link-class">
                  <fa-icon [icon]="link.iconClass"></fa-icon>
                </span>
             </ng-container>
             <ng-template #iconImage>
               <ng-container *ngIf="link.iconUrl; else default">
                 <div class="link-img-wrapper">
                    <img [src]="link.iconUrl"
                    [alt]="link.iconImage"
                    class="link-img">
                 </div>
               </ng-container>
             </ng-template>
             <ng-template #default>
                <span *ngIf="isCollapsedNav; else defaultImage"
                      title="{{ link.translate ? (link.name | translate) : link.name }}">
                  <span class="link-class"><i class="nd-folder"></i></span>
                </span>
             </ng-template>

             <span [ngClass]="isCollapsedNav ? 'hidden' : ''">
               {{ link.translate ? (link.name | translate) : link.name }}
               <i class="nd-open-in-new"></i>
            </span>
           </a>
         </ng-container>

         <!--CASE 2: Check if link is internal and has url and show this template-->
         <ng-container *ngIf="!link.external && link.url">
           <a
             class="link"
             [routerLink]="link.url"
             routerLinkActive="active"
             [routerLinkActiveOptions]="{ exact: true, isActive: true }"
           >
             <ng-container *ngIf="link.iconType ==='class'; else iconImage">
               <span class="link-class">
                <fa-icon [icon]="link.iconClass"></fa-icon>
              </span>
             </ng-container>
             <ng-template #iconImage>
               <ng-container *ngIf="link.iconUrl; else default">
                 <div class="link-img-wrapper">
                    <img [src]="link.iconUrl"
                    [alt]="link.iconImage"
                    class="link-img">
                 </div>
               </ng-container>
             </ng-template>
             <ng-template #default>
                <span *ngIf="isCollapsedNav; else defaultImage"
                      title="{{ link.translate ? (link.name | translate) : link.name }}">
                  <span class="link-class"><i class="nd-folder"></i></span>
                </span>
             </ng-template>

             <span [ngClass]="isCollapsedNav ? 'hidden' : ''">
               {{ link.translate ? (link.name | translate) : link.name }}
            </span>
           </a>
         </ng-container>

        <!--CASE 3: Check if link does not have url and show this template-->
         <ng-container *ngIf="!link.url">
           <a
             class="link collapsed-style"
             href="javascript:void(0)"
             (click)="click(link)"
           >
             <ng-container *ngIf="link.iconType ==='class'; else iconImage">
               <span class="link-class">
                <fa-icon [icon]="link.iconClass"></fa-icon>
              </span>
             </ng-container>
             <ng-template #iconImage>
               <ng-container *ngIf="link.iconUrl; else default">
                <div class="link-img-wrapper">
                 <img [src]="link.iconUrl"
                      [alt]="link.iconImage"
                      class="link-img">
                </div>
               </ng-container>
             </ng-template>
             <ng-template #default>
                <span *ngIf="isCollapsedNav; else defaultImage"
                      title="{{ link.translate ? (link.name | translate) : link.name }}">
                  <span class="link-class"><i class="nd-folder"></i></span>
                </span>
             </ng-template>

             <span [ngClass]="isCollapsedNav ? 'hidden' : ''">
               {{ link.translate ? (link.name | translate) : link.name }}
               <i *ngIf="link.external" class="nd-open-in-new"></i>
            </span>
           </a>
         </ng-container>
       </div>
     </li>
   </ul>
   <div class="sidebar-toggle-btn"
        (click)="toggleSidebar(!isCollapsedNav)"
        [ngClass]="isCollapsedNav ? 'active' : ''">

     <i class="nd-double-arrows"></i>
   </div>
  </nav>
