import { Injectable } from '@angular/core';
import { IToolTile } from '@shared/interfaces/tool-tiles.interface';
import { IUsefulLinks } from '@shared/interfaces/useful-links.interface';
import { Apollo } from 'apollo-angular';
import { map } from 'rxjs/operators';

import { INews, INewsItem, INewsQueryParams } from './news.interface';
import { newsItemQuery, newsQuery } from './news.query';

@Injectable()
export class NewsService {
  constructor(private apollo: Apollo) {}

  getNews(query: INewsQueryParams) {
    return this.apollo
      .watchQuery<{
        news: {
          items: INews[];
          totalCount: number;
        };
        topNewsItem: Partial<INews>;
      }>({
        query: newsQuery(query),
        variables: {
          ...query,
        },
      })
      .valueChanges.pipe(map(response => response.data));
  }

  getNewsItem(slug: String) {
    return this.apollo
      .watchQuery<{
        newsItem: INewsItem;
        toolTiles: IToolTile[];
        usefulLinks: IUsefulLinks[];
      }>({
        query: newsItemQuery,
        variables: { slug },
      })
      .valueChanges.pipe(map(response => response.data));
  }
}
