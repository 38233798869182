import { Injectable } from '@angular/core';
import { IToolTile } from '@shared/interfaces/tool-tiles.interface';
import { IUsefulLinks } from '@shared/interfaces/useful-links.interface';
import { Apollo } from 'apollo-angular';
import { map } from 'rxjs/operators';

import { ISupportItem } from './support.interface';
import { supportItemQuery } from './support.query';

@Injectable()
export class SupportService {
  constructor(private apollo: Apollo) {}

  getSupportPage(slug: string) {
    return this.apollo
      .watchQuery<{
        contentPage: ISupportItem;
        toolTiles: IToolTile[];
        usefulLinks: IUsefulLinks[];
      }>({
        query: supportItemQuery,
        variables: { slug },
      })
      .valueChanges.pipe(map(response => response.data));
  }
}
