<div class="container-fluid">
  <app-alerts></app-alerts>
</div>

<div class="support sidebar-margin" [ngClass]="{'is-nav-collapsed': isCollapsedNav}">
  <div class="breadcrumbs">
    <a class="breadcrumbs__link" [routerLink]="['/dashboard']">
      <i class="nd-arrow-left"></i>
      Dashboard
    </a>
    /
    <span class="breadcrumbs__text">Support</span>
  </div>
  <app-article
    *ngIf="article"
    [title]="article.title"
    [content]="article.flexible_content"
  ></app-article>
</div>

<app-sidebar [ngClass]="{'is-nav-collapsed': isCollapsedNav}">
  <div class="tiles-list" *ngIf="tiles?.length">
    <p class="tiles-list_title">
      {{ 'news_item_page.sidebar.relevant_tools' | translate }}
    </p>
    <ng-container *ngFor="let tile of tiles">
      <app-tool-card
        [tile]="tile"
        [boxShadow]=true
        class="mb-3 mr-3 mr-lg-0"
      ></app-tool-card>
    </ng-container>
  </div>


  <app-links-widget class="border-top-component" [data]="links" *ngIf="links?.length"></app-links-widget>
  <app-news-widget class="border-top-component"></app-news-widget>
  <app-contact-widget class="border-top-component"></app-contact-widget>
</app-sidebar>
