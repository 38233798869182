import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { alertsdQuery } from '@shared/components/alerts/alerts.query';
import { Apollo } from 'apollo-angular';
import findIndex from 'lodash-es/findIndex';
import { BehaviorSubject } from 'rxjs';
import { Observable, Subject } from 'rxjs/index';
import { map } from 'rxjs/operators';
import { v4 as uuid } from 'uuid';

import { IAppAlert } from './alerts.interface';

@Injectable({
  providedIn: 'root',
})
export class AlertsService {
  private internaAlerts: IAppAlert[] = [];

  private alertsSubject: BehaviorSubject<IAppAlert[]> = new BehaviorSubject(
    this.internaAlerts,
  );

  private _data = new Subject<IAppAlert>();

  get data$(): Observable<IAppAlert> {
    return this._data.asObservable();
  }

  get alerts$() {
    return this.alertsSubject.asObservable();
  }

  constructor(private apollo: Apollo) {}

  addHttpError(error: HttpErrorResponse) {
    const alert: IAppAlert = {
      id: uuid(),
      title: `${error.name}: ${error.statusText}`,
      type: 'danger',
      content: error.message,
      closable: true,
    };
    this.internaAlerts.push(alert);
    this.alertsSubject.next(this.internaAlerts);
  }

  addSuccess(event: { title: string; message: string }) {
    const alert: IAppAlert = {
      id: uuid(),
      title: event.title,
      type: 'success',
      content: event.message,
      closable: true,
    };
    this.internaAlerts.push(alert);
    this.alertsSubject.next(this.internaAlerts);
  }

  addInfo(event: { title: string; message: string; closable?: boolean }) {
    const alert: IAppAlert = {
      id: uuid(),
      title: event.title,
      type: 'info',
      content: event.message,
      closable: !!event.closable,
    };
    this.internaAlerts.push(alert);
    this.alertsSubject.next(this.internaAlerts);
  }

  closeError(id: string) {
    const errIdx = findIndex(this.internaAlerts, (e: IAppAlert) => e.id === id);
    this.internaAlerts.splice(errIdx, 1);
    this.alertsSubject.next(this.internaAlerts);
  }

  getAlertData(): Observable<IAppAlert> {
    return this.apollo
      .watchQuery<any>({
        query: alertsdQuery,
        fetchPolicy: 'network-only',
      })
      .valueChanges.pipe(
        map(({ data }) => {
          // check if alert is exceptional
          // danger for test
          // if more than 1 take last take pop()

          const exceptionalAlert = (data.alerts || [])
            .filter(item => item.type === 'exceptional')
            .pop();

          return exceptionalAlert;
        }),
      );
  }
}
