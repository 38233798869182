import { Component, Input, TemplateRef } from '@angular/core';
import { IFeedbackProductCompanyIncorrect } from '@modules/products/interfaces/dto.interface';
import { InsuranceCompany } from '@modules/products/interfaces/insurance-company.interface';
import { InsuranceProduct } from '@modules/products/interfaces/insurance-product.interface';
import { ProductsService } from '@modules/products/products.service';
import { KeycloakWrapperService } from '@shared/keycloak/keycloak-wrapper.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-incorrect-item',
  templateUrl: './incorrect-item.component.html',
  styleUrls: ['./incorrect-item.component.scss'],
})
export class IncorrectItemComponent {
  state = {
    isSent: false,
    type: '',
  };

  data: IFeedbackProductCompanyIncorrect = {
    userName: '',
    companyTitle: '',
    productTitle: undefined,
    type: undefined,
    message: '',
    url: '',
  };

  @Input() set company(company: InsuranceCompany) {
    this.state.type = 'company';
    this.data.companyTitle = company.title;
    this.data.url = `${location.origin}/products/company/${company.slug}`;
  }
  @Input() set product(product: InsuranceProduct) {
    this.state.type = 'product';
    this.data.companyTitle = product.insurance_company.title;
    this.data.productTitle = product.title;
    this.data.type = product.type;
    this.data.url =
      product.type === 'proxy'
        ? product.proxy_url
        : `${location.origin}/products/product/${product.slug}`;
  }

  modalRef: BsModalRef;

  constructor(
    private readonly modalService: BsModalService,
    private readonly keycloakWrapperService: KeycloakWrapperService,
    private readonly productsService: ProductsService,
  ) {
    const subscription = this.keycloakWrapperService
      .getUserProfile()
      .subscribe(res => {
        this.data.userName = `${res.firstName} ${res.lastName}`;
        if (subscription) {
          subscription.unsubscribe();
        }
      });
  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
  }

  submitForm() {
    const subscription = this.productsService
      .sendFeedback(this.data)
      .subscribe(res => {
        this.state.isSent = res;
        if (subscription) {
          subscription.unsubscribe();
        }
      });
  }
}
