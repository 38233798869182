import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DisplayFilterItem } from '@modules/products/components/products-filters/products-filters.interface';
import { TranslateService } from '@ngx-translate/core';
import { ILinkList } from '@shared/components/links-list/links-list.interface';
import { categoryName } from '@shared/interfaces/categories.interface';
import { CategoriesService } from '@shared/services/categories/categories.service';
import omit from 'lodash-es/omit';
import { Subscription } from 'rxjs';

import { IFaq, IFaqResponse } from '../../faq.interface';
import { FaqService } from '../../faq.service';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.page.html',
  styleUrls: ['./faq.page.scss'],
})
export class FaqPage implements OnInit, OnDestroy {
  translateSubscription: Subscription;
  public state = {
    category: null,
    search: '',
    startPage: true,
    total: 0,
  };
  public categories: ILinkList[];

  public data: IFaqResponse | IFaq[];

  displayFilters: DisplayFilterItem[] = [];
  categoryNames: any;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private faqService: FaqService,
    private categoriesService: CategoriesService,
    private translateService: TranslateService,
  ) {}

  ngOnInit() {
    this.route.queryParams.subscribe(res => {
      this.setSearch(res.s);
    });

    this.generateLinksList();
    this.translateSubscription = this.translateService
      .get('global.tool_type')
      .subscribe(res => {
        this.categoryNames = res;
      });
  }

  private generateLinksList() {
    const allCategoriesValue = {
      category: null,
      name: 'Alle vragen',
      translate: false,
    };
    const newCategories = this.categoriesService.categories.map(category => ({
      category,
      name: `global.tool_type.${category}`,
      translate: true,
    }));
    this.categories = [allCategoriesValue, ...newCategories];
  }
  public requestNewData() {
    this.state.startPage = !this.state.search && !this.state.category;
    this.faqService.getFaqs(omit(this.state, ['total'])).subscribe(res => {
      this.data = res['items'] ? res['items'] : res;
      this.state.total = res['total'];
    });
  }

  public setCategory(category: categoryName | null) {
    this.state.category = category;
    this.addFilterToDisplayFilters('category', category);
    this.requestNewData();
  }

  public setSearch(term?: string) {
    if (!term || (term && term.length > 2 && term !== this.state.search)) {
      this.state.search = !term ? null : term;
      if (!term) {
        this.removeFilter({ type: 'search' } as DisplayFilterItem);
      } else {
        this.addFilterToDisplayFilters('search', term);
      }
      this.requestNewData();
    }
  }

  public setSearchQuery(term?: string) {
    const queryParams = {};
    if (term) {
      queryParams['s'] = term;
    }
    this.router.navigate([], { queryParams, relativeTo: this.route });
  }

  isArray(data: any) {
    return Array.isArray(data);
  }

  addFilterToDisplayFilters(type: 'search' | 'category', value: string) {
    const newDisplayFilters = this.displayFilters.filter(f => f.type !== type);
    newDisplayFilters.push({
      type,
      value,
      label: type === 'category' ? this.categoryNames[value] : value,
    });
    this.displayFilters = newDisplayFilters;
  }

  removeFilter($event: DisplayFilterItem) {
    this.state[$event.type] = null;
    this.displayFilters = this.displayFilters.filter(
      f => f.type !== $event.type,
    );
    if ($event.type === 'search') {
      this.router.navigate([], { queryParams: {}, relativeTo: this.route });
    } else {
      this.requestNewData();
    }
  }
  removeSearch(): void {
    this.state.search = null;
    this.displayFilters = this.displayFilters.filter(
      item => item.type !== 'search',
    );
    this.requestNewData();
  }

  removeAllFilters() {
    this.state.search = '';
    this.state.category = null;
    this.displayFilters = [];
    this.requestNewData();
  }

  ngOnDestroy() {
    if (this.translateSubscription) {
      this.translateSubscription.unsubscribe();
    }
  }
}
