import { Component, Input } from '@angular/core';
import { InsuranceCompany } from '@modules/products/interfaces/insurance-company.interface';

@Component({
  selector: 'app-companies-list',
  templateUrl: './companies-list.component.html',
})
export class CompaniesListComponent {
  @Input() companies: InsuranceCompany[];
  @Input() isMutation: Boolean;
}
