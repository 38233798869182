<div class="row filter" *ngIf="values">
  <div class="col-12 col-md-12 col-xl-12">
    <div
      class="search-form search-form-secondary mb-4"
      [ngClass]="{ 'with-select': !isMutation }"
      style="max-width: 100%"
    >
      <div class="mb-0 form-group has-float-label">
        <ng-container *ngIf="!isMutation">
          <ng-select
            [items]="options.searchBy"
            [dropdownPosition]="'down'"
            [searchable]="false"
            [bindLabel]="'label'"
            [bindValue]="'value'"
            [ngModel]="values.searchBy"
            (ngModelChange)="filtersSearchByChanged($event)"
          ></ng-select>
        </ng-container>
        <input
          #inputSearch
          type="text"
          class="form-control"
          placeholder="{{ 'global.search_placeholder' | translate }}"
          [ngModel]="values.search"
          (keyup)="$event.keyCode === 13 && setSearchQuery(inputSearch.value)"
          (blur)="setSearchQuery(inputSearch.value)"
        />
        <label>{{ 'global.search_placeholder' | translate }}</label>
      </div>
      <button
        [disabled]="inputSearch.value.length < 2"
        class="submit-search"
        (click)="setSearchQuery(inputSearch.value)"
      >
        <i class="nd-magnify"></i>
      </button>
    </div>
  </div>
  <div class="col-12 col-md-6 col-xl-12" *ngIf="!isMutation">
    <h3 class="filter__sub-title">
      {{ 'products_page.filters.kind.private' | translate }}/
      {{ 'products_page.filters.kind.business' | translate }}/
      {{ 'products_page.filters.kind.income' | translate }}
    </h3>
    <div class="row mb-4">
      <div class="col">
        <app-checkboxes-list
          [columnClass]="'col-12 col-sm-4 col-md-4 col-xl-12'"
          [options]="options.kind"
          [ngModel]="values.kind"
          [propLabel]="'label'"
          [propValue]="'value'"
          (ngModelChange)="filtersKindChanged($event)"
        ></app-checkboxes-list>
      </div>
    </div>
  </div>
  <ng-container *ngIf="!isMutation">
    <div class="col-12 col-md-6 col-xl-12">
      <h3 class="filter__sub-title h3">
        {{ 'products_page.filters.type.proxy' | translate }}/
        {{ 'products_page.filters.type.non_proxy' | translate }}
      </h3>
      <div class="row mb-4">
        <div class="col">
          <app-checkboxes-list
            [columnClass]="'col-12 col-sm-4 col-md-6 col-lg-4 col-xl-12'"
            [options]="options.type"
            [ngModel]="values.type"
            [propLabel]="'label'"
            [propValue]="'value'"
            (ngModelChange)="filtersTypeChanged($event)"
          ></app-checkboxes-list>
        </div>
      </div>
    </div>
  </ng-container>
  <div class="col-12 col-md-12 col-xl-12">
    <h3 class="filter__sub-title">
      {{ 'products_page.filters.companies.title' | translate }}
    </h3>
    <div class="mb-4">
      <label class="label">{{
        'products_page.filters.companies.label' | translate
      }}</label>
      <app-checkbox-select
        [options]="options.companies | sortAlphabet: 'label'"
        [propLabel]="'label'"
        [propValue]="'value'"
        [placeholder]="
          'products_page.filters.companies.placeholder' | translate
        "
        [ngModel]="values.companiesIds"
        (ngModelChange)="filtersCompanyChanged($event)"
      ></app-checkbox-select>
    </div>
  </div>
  <div class="col-12 col-md-12 col-xl-12" *ngIf="!isMutation">
    <h3 class="filter__sub-title">
      {{ 'products_page.filters.categories.title' | translate }}
    </h3>
    <div class="mb-4 row">
      <div class="col-12 col-md-6 col-xl-12">
        <label class="label text-light h5 m-0">{{
          'products_page.filters.categories.category_label' | translate
        }}</label>
        <ng-select
          class="mb-4"
          [placeholder]="
            'products_page.filters.categories.category_placeholder' | translate
          "
          [items]="options.categories"
          [bindLabel]="'label'"
          [bindValue]="'value'"
          [dropdownPosition]="'down'"
          [searchable]="false"
          [ngModel]="values.categoryId"
          (ngModelChange)="filtersCategoryChanged($event)"
          [notFoundText]="
            'products_page.filters.categories.category_no_items' | translate
          "
        ></ng-select>
      </div>
      <div class="col-12 col-md-6 col-xl-12">
        <label class="label text-light h5 m-0">{{
          'products_page.filters.categories.subcategory_label' | translate
        }}</label>
        <ng-select
          [disabled]="!values.categoryId"
          class="mb-4"
          [placeholder]="
            'products_page.filters.categories.subcategory_placeholder'
              | translate
          "
          [items]="options.subcategories[values.categoryId] || []"
          [multiple]="true"
          [bindLabel]="'label'"
          [bindValue]="'value'"
          [dropdownPosition]="'down'"
          [searchable]="false"
          [closeOnSelect]="false"
          [ngModel]="values.subcategoriesIds"
          (ngModelChange)="filtersSubcategoryChanged($event)"
        ></ng-select>
      </div>
    </div>
  </div>
</div>
