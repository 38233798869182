// tslint:disable-next-line:import-name
import gql from 'graphql-tag';

export const headerQuery = gql`
  query header {
    toolTiles {
      id
      title
      url
      category
      link_type
    }
    usefulLinks {
      id
      type
      link_text
      link_url
    }
    dashboardCategories {
      name
      id
    }
  }
`;
