<div class="container-fluid">
  <app-alerts></app-alerts>
</div>

<div
  class="contact-head jumbotron"
  style="background-image: url(./assets/images/contact-header.jpg)"
>
  <div class="container-fluid">
    <h1 class="contact-head-title">
      {{ 'contact_page.title' | translate }}
    </h1>
  </div>
</div>

<div class="contact-departments">
  <div class="container-fluid">
    <div class="row">
      <div class="col-12 col-lg-4" *ngFor="let departmentColumn of departments">
        <ng-container *ngFor="let department of departmentColumn">
          <app-collapsible
            [multilineHeader]="false"
            [title]="department.title"
            [color]="'light'"
            class="d-block"
          >
            <div class="contact-office " *ngFor="let office of department.items">
              <p class="contact-office-title "> {{ office.title }}</p>
              <a class="link" [href]="'tel: ' + office.phone">
                <i class="nd-phone-outline"></i>{{ office.phone }}
              </a>
              <a
                class="link"
                [href]="'mailto: ' + office.email"
              >
                <i class="nd-envelope"></i>{{ office.email }}
              </a>
                <a
                class="link" 
                [href]="office.webadres" 
                target="_blank"
                >
                <i class="nd-globe" *ngIf="office.webadres !== ''"></i>
                    <ng-container *ngIf="office.webadrestekst !== '' else webadresempty" >{{office.webadrestekst}}</ng-container><ng-template #webadresempty>{{office.webadres}}</ng-template>
              </a>
            </div>
          </app-collapsible>
        </ng-container>
      </div>
    </div>
  </div>
</div>

<div class="contact-managers">
  <div class="container-fluid ">
    <div class="row">
      <div class="col-12 col-lg-6 col-xl-5 mb-4">
        <p class="contact-managers-title">
          {{ 'contact_page.accountmanagers.title' | translate }}
        </p>
        <p *ngIf="!isBSB" class="contact-managers-subtitle">
          {{ 'contact_page.accountmanagers.subtitle' | translate }}
          <a
            class="contact-managers-link"
            href="mailto:sales@nedasco.nl"
          >
            {{ 'contact_page.accountmanagers.link' | translate }}</a
          >
          {{ 'contact_page.accountmanagers.subtitle_2' | translate }}
          <a
            class="contact-managers-link"
            href="tel:0334670910"
          >
            {{ 'contact_page.accountmanagers.link_2' | translate }}</a
          >.
          {{ 'contact_page.accountmanagers.subtitle_3' | translate }}
          <a
          class="contact-managers-link"
          href="https://nedasco.nl/mijn-rayon"
          target=_blank
        >{{ 'contact_page.accountmanagers.link_3' | translate }}</a>
          </p>
        <div
          class="contact-card card card-am"
          *ngFor="let manager of accountManagers"
        >
          <div class="contact-card-item">
            <img
              class="media-image"
              [src]="manager.image"
              alt="media"
            />
            <div class="contact-card-content">
              <p class="contact-card-title">
                <span>{{ manager.name }}</span>
                <a [href]="manager.linkedin" target="_blank">
                  <i class="nd-linkedin"></i>
                </a>
              </p>
              <a [href]="'mailto: ' + manager.email" class="link">
                <i class="nd-envelope"></i>
                {{ manager.email }}
              </a>
              <a [href]="'tel: ' + manager.phone" class="link">
                <i class="nd-phone-outline"></i>
                {{ manager.phone }}
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-lg-6 offset-xl-1">
        <div class="card contact-contactform">
          <p class="contact-contactform-title">
            {{ 'contact_page.contactform.title' | translate }}
          </p>
          <div id="contactform-container" *ngIf="contactPageFormUrl">
            <iframe [src]="contactPageFormUrl"></iframe>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<ng-container *ngIf="mapCoordinates">
  <agm-map

    class="agm-map"
    [latitude]="+mapCoordinates.map.latitude"
    [longitude]="+mapCoordinates.map.longitude"
    [zoom]="+mapCoordinates.map.zoom"
    [scrollwheel]="false"
    [clickableIcons]="false"
  >
    <agm-marker
      [latitude]="+mapCoordinates.marker.latitude"
      [longitude]="+mapCoordinates.marker.longitude"
      [iconUrl]="'./assets/images/map-marker.svg'"
    >
      <agm-snazzy-info-window

        [placement]="'left'"
        [isOpen]="true"
        [closeOnMapClick]="false"
        [showCloseButton]="false"
      >
        <ng-template>
          <p class="si-window-title">
            {{mapContacts?.companyName}}
          </p>
          <div class="row si-window-list">
            <div class="col-6">
              <div class="d-flex">
                <div class="mr-2">
                  <i class="nd-envelope"></i>
                </div>
                <div>
                  <p class="si-window-subtitle">
                    {{ 'contact_page.map.post_address' | translate }}
                  </p>
                  <p class="mb-3" [innerHTML]="mapContacts?.postAddress"></p>
                  <p class="si-window-subtitle">{{ 'contact_page.map.iban' | translate }}</p>
                  <p class="mb-3">{{mapContacts?.iban}}</p>
                  <p class="si-window-subtitle">{{ 'contact_page.map.bic' | translate }}</p>
                  <p class="mb-0">{{mapContacts?.bic}}</p>
                </div>
              </div>
            </div>
            <div class="col-6">
              <div class="d-flex">
                <div class="mr-2">
                  <i class="nd-map-marker-outline"></i>
                </div>
                <div>
                  <p class="si-window-subtitle">
                    {{ 'contact_page.map.visit_address' | translate }}
                  </p>

                  <p class="mb-2" [innerHTML]="mapContacts?.visitorAddress"></p>

                  <p class="si-window-subtitle">
                    {{ 'contact_page.map.kvk_number' | translate }}
                  </p>
                  <p class="mb-0">{{mapContacts?.kvk}}</p>
                </div>
              </div>
            </div>
          </div>
        </ng-template>
      </agm-snazzy-info-window>
    </agm-marker>
  </agm-map>
</ng-container>
