// tslint:disable-next-line:import-name
import gql from 'graphql-tag';

export const supportItemQuery = gql`
  query supportItem($slug: String!) {
    contentPage(slug: $slug) {
      id
      slug
      title
      flexible_content
      image
      useful_links {
        link_url
        type
        title
      }
      tooltiles
    }
    toolTiles {
      id
      title
      image
      tooltile_type
      url
      link_type
      start_date
      active
      category
    }
  }
`;
