import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  DisplayFilterItem,
  IFiltersValues,
} from '@modules/products/components/products-filters/products-filters.interface';
import { ProductsFiltersService } from '@modules/products/components/products-filters/products-filters.service';
import { ProductsService } from '@modules/products/products.service';
import { Store } from '@ngrx/store';
import { without } from 'lodash-es';

import { State } from '../../../../store/app-reducers';
import { IPackagesQueryParams } from '../../interfaces/dto.interface';
import { InsuranceCompany } from '../../interfaces/insurance-company.interface';
import { InsuranceProduct } from '../../interfaces/insurance-product.interface';

const tabs = ['products', 'companies'];
const initialPagination = {
  productsFrom: 0,
  productsSize: 10,
  ProductsDashboardPage: 1,
  companiesFrom: 0,
  companiesSize: 10,
  companiesPage: 1,
};
@Component({
  selector: 'app-products-dashboard',
  templateUrl: './products-dashboard.page.html',
  styleUrls: ['./products-dashboard.page.scss'],
})
export class ProductsDashboardPage implements OnInit {
  state = {
    isMutation: false,
    tabs: [...tabs],
    activeTab: tabs[0],
    totalCount: {
      products: 0,
      companies: 0,
    },
    pagination: { ...initialPagination },
    filters: {} as IFiltersValues,
  };
  isCollapsedNav: boolean;
  products: InsuranceProduct[];
  companies: InsuranceCompany[];
  constructor(
    private productsService: ProductsService,
    public productsFiltersService: ProductsFiltersService,
    private route: ActivatedRoute,
    private router: Router,
    public store: Store<State>,
  ) {
    this.state.isMutation = this.route.snapshot.data.isMutation;
    if (this.state.isMutation) {
      this.state.tabs = without(this.state.tabs, tabs[0]);
    }
    this.productsFiltersService.filters$.subscribe(res => {
      this.state.pagination = { ...initialPagination };
      this.state.totalCount.products = 0;
      this.state.totalCount.companies = 0;
      this.filtersChanged(res);
    });
  }

  ngOnInit() {
    this.store.select('core').subscribe(data => {
      this.isCollapsedNav = data.isCollapsedNav;
    });
  }

  isFilterBuCompanyOnly(filters: IFiltersValues): boolean {
    const appliedFilters = Object.keys(filters).filter(
      k =>
        (!Array.isArray(filters[k]) && !!filters[k]) ||
        !!(Array.isArray(filters[k]) && filters[k].length),
    );

    return (
      (!!filters.searchBy && filters.searchBy === 'company') ||
      (appliedFilters.length === 1 && appliedFilters[0] === 'companiesIds')
    );
  }

  filtersChanged(filters: IFiltersValues) {
    if (this.state.isMutation || this.isFilterBuCompanyOnly(filters)) {
      this.state.activeTab = tabs[1];
    } else {
      this.state.activeTab = tabs[0];
    }

    this.state.filters = filters;
    this.requestNewData({ ...initialPagination, ...filters });
  }

  public requestNewData(query: IPackagesQueryParams) {
    if (this.state.isMutation) {
      this.getCompanies(query);
    } else {
      this.getProducts(query);
    }
  }

  getCompanies(query) {
    this.productsService
      .getCompanies(query, this.state.isMutation)
      .subscribe(res => {
        this.companies = res.data.companies.items;
        this.state.totalCount.companies = res.data.companies.totalCount;
      });
  }

  getProducts(query) {
    this.productsService
      .getProductsDashboardPageData(query, this.state.isMutation)
      .subscribe(res => {
        this.products = res.products.items;
        this.state.totalCount.products = res.products.totalCount;
        if (res.companiesByProduct) {
          this.companies = res.companiesByProduct.items;
          this.state.totalCount.companies = res.companiesByProduct.totalCount;
        }
      });
  }

  removeFilter(filter: DisplayFilterItem) {
    this.router.navigate([], { queryParams: {}, relativeTo: this.route });
    this.productsFiltersService.removeFilter(filter);
  }
  removeAllFilters() {
    this.router.navigate([], { queryParams: {}, relativeTo: this.route });
    this.productsFiltersService.refreshValues();
  }

  updatePagination(event, tab: string) {
    this.state.pagination[`${tab}Page`] = event.page;
    setTimeout(() => {
      this.state.pagination[`${tab}From`] =
        (this.state.pagination[`${tab}Page`] - 1) *
        this.state.pagination[`${tab}Size`];
      this.requestNewData({ ...this.state.pagination, ...this.state.filters });
    });
  }
}
