<div class="article" ngClass="{{articleType}}">
  <div class="article__header" [ngClass]="[category | toolColor]">
    <span class="article__category" *ngIf="category && category !== 'uncategorized'">
      {{'global.tool_type.' + category | translate | lowercase}}
    </span>
    <h1 class="article__title" *ngIf="title" >{{ title }}</h1>
    <h5 class="article__date" *ngIf="date">
      {{ date | date: 'dd.MM.yyyy' }}
    </h5>
  </div>
  <div class="article__content">
    <ng-container *ngFor="let block of _content">
      <ng-container [ngSwitch]="block.layout">
        <div
          *ngSwitchCase="'text_block'"
          class="mb-3"
          [innerHTML]="block['text']"
        ></div>
        <h2
          *ngSwitchCase="'subtitle_block'"
          class="mt-4 mb-2"
          [innerText]="block['subtitle']"
        ></h2>
        <img
          *ngSwitchCase="'image_block'"
          [src]="block['image']"
          class="w-100 mb-4"
        />
        <ng-container *ngFor="let collapse of block['items']">
          <details class="mb-3 details" *ngSwitchCase="'collapse_block'">
            <summary class="summary">{{ collapse.title }}</summary>
            <p class="m-0">{{ collapse.text }}</p>
          </details>
        </ng-container>

        <div
          *ngSwitchCase="'column_block'"
          class="row mb-3"
          [ngClass]="'columns' + block['items'].length"
        >
          <div
            class="{{ getColSize(block['items'])}} content-item"
            *ngFor="let col of block['items']"
          >
            <img
              class="w-100"
              *ngIf="col.type === 'image'"
              [src]="col['url']"
              alt=""
              (click)="openModal(template, 'image', col['url'])"
            />
            <span
              class="d-block h-100"
              (click)="openModal(template, 'video', col['url'])"
            >
              <iframe
                style="pointer-events: none"
                *ngIf="col.type === 'video'"
                width="100%"
                height="100%"
                [src]="col['url']"
                frameborder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </span>
          </div>
        </div>

        <div class="card p-5" *ngSwitchCase="'iframe_block'">
          <div class="row mb-4">
            <div class="col-6">
              <p class="h3" *ngIf="block['column_1']?.title">
                {{ block['column_1']?.title }}
              </p>
              <p *ngIf="block['column_1']?.description">
                {{ block['column_1']?.description }}
              </p>
            </div>
            <div class="col-6">
              <p class="h3" *ngIf="block['column_2']?.title">
                {{ block['column_2']?.title }}
              </p>
              <p *ngIf="block['column_2']?.description">
                {{ block['column_2']?.description }}
              </p>
            </div>
            <div class="col-12">
              <p class="h3">{{ 'article.contactform.title' | translate }}</p>
            </div>
            <iframe
              *ngIf="block['iframe_url']"
              width="100%"
              height="585"
              [src]="sanitizeVideo(block['iframe_url'])"
              frameborder="0"
            ></iframe>
          </div>
        </div>
      </ng-container>
    </ng-container>

    <ng-template #template>
      <button
        type="button"
        class="close pull-right"
        aria-label="Close"
        (click)="hideModal()"
      >
        <span aria-hidden="true">&times;</span>
      </button>

      <img
        *ngIf="modalContent?.type === 'image'"
        [src]="modalContent?.src"
        class="w-100"
        alt=""
      />
      <iframe
        *ngIf="modalContent?.type === 'video'"
        width="560"
        [src]="modalContent?.src"
        height="315"
        frameborder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></iframe>
    </ng-template>
  </div>
</div>



