<div
  class="collapsible"
  [ngClass]="[
    'bg-' + color,
    !!category ? (category | toolColor) : ''
  ]"
>
  <a
    href="javascript:void(0)"
    (click)="isCollapsed = !isCollapsed"
    class="collapsible-head"
    [ngClass]="{ singleline: !multilineHeader }"
  >
    {{ title }}
    <i
      [ngClass]="'nd-chevron-' + (isCollapsed ? 'down-small' : 'up-small')"
    ></i>
  </a>
  <div
    class="collapsible-body"
    #collapse="bs-collapse"
    [collapse]="isCollapsed"
  >
    <ng-content></ng-content>
  </div>
</div>
