import { Component, Input } from '@angular/core';
import { LastViewedProductService } from '@modules/products/components/last-viewed-product/last-viewed-product.service';
import { InsuranceProduct } from '@modules/products/interfaces/insurance-product.interface';

@Component({
  // tslint:disable-next-line:component-selector
  selector: '[app-products-list-item]',
  templateUrl: './products-list-item.component.html',
  styleUrls: ['./products-list-item.component.scss'],
})
export class ProductsListItemComponent {
  expanded = false;
  @Input() storeViewind: boolean;
  @Input() product: InsuranceProduct;
  @Input() fullPageSize: boolean;
  @Input() isMutation = false;
  constructor(private lastViewedProductService: LastViewedProductService) {}

  setViewedItem() {
    if (this.storeViewind) {
      this.lastViewedProductService.addItem(this.product.id, this.isMutation);
    }
  }
}
