// tslint:disable-next-line:import-name
import gql from 'graphql-tag';

import { IPackagesQueryParams } from '../interfaces/dto.interface';

export const productsQuery = (query: IPackagesQueryParams) => {
  const queryParamsDef = [];
  const queryParamsProducts = [];
  const queryParamsCompanies = [];
  const formatQuery = (params: string[]) => `(${params.join(',')})`;
  const addToQueryObject = (param: string, type: string) => {
    queryParamsDef.push(`$${param}: ${type}`);
    queryParamsProducts.push(`${param}: $${param}`);
    queryParamsCompanies.push(`${param}: $${param}`);
  };

  if (query.productsFrom) {
    queryParamsDef.push('$productsFrom: Int');
    queryParamsProducts.push('from: $productsFrom');
  }
  if (query.productsSize) {
    queryParamsDef.push('$productsSize: Int');
    queryParamsProducts.push('size: $productsSize');
  }
  if (query.companiesFrom) {
    queryParamsDef.push('$companiesFrom: Int');
    queryParamsCompanies.push('from: $companiesFrom');
  }
  if (query.companiesSize) {
    queryParamsDef.push('$companiesSize: Int');
    queryParamsCompanies.push('size: $companiesSize');
  }

  // tslint:disable:no-unused-expression
  query.type && query.type.length && addToQueryObject('type', '[ProductType!]');
  query.kind && query.kind.length && addToQueryObject('kind', '[ProductKind!]');
  query.categoryId && addToQueryObject('categoryId', 'ID');
  query.subcategoriesIds &&
    query.subcategoriesIds.length &&
    addToQueryObject('subcategoriesIds', '[ID!]');
  query.companiesIds &&
    query.companiesIds.length &&
    addToQueryObject('companiesIds', '[ID!]');
  query.search && addToQueryObject('search', 'String');
  query.searchBy && addToQueryObject('searchBy', 'ProductSearchByType');

  return gql`
    query products ${queryParamsDef.length ? formatQuery(queryParamsDef) : ''} {
      companiesByProduct ${
        queryParamsCompanies.length ? formatQuery(queryParamsCompanies) : ''
      } {
        totalCount
        items {
          id
          logo
          slug
          title
          page
          website
          extranet
          contact {
            type
            address
            phone
            email
          }
        }
      }
      products ${
        queryParamsProducts.length ? formatQuery(queryParamsProducts) : ''
      } {
        totalCount
        items {
          id
          slug
          title
          proxy_url
          request_url
          contact_url
          description_title
          description
          provision
          order
          category
          type
          kind
          insurance_company {
            id
            slug
            title
            logo
            documents {
              id
              title
              url
              type
              document_type
            }
            contact {
              type
              address
              phone
              email
            }
          }
          documents {
            id
            title
            url
            type
            document_type
          }
          package {
            title
            documents {
              id
              title
              url
              type
              document_type
            }
          }
        }
      }
      contacts {
        type
        address
        phone
        email
        proxy_url
      }
    }
  `;
};
