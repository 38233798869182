import { Component, Input } from '@angular/core';
import { InsuranceProduct } from '@modules/products/interfaces/insurance-product.interface';

@Component({
  selector: 'app-products-list',
  templateUrl: './products-list.component.html',
})
export class ProductsListComponent {
  @Input() isMutation = false;
  @Input() fullPageSize: boolean;
  @Input() products: InsuranceProduct[];
}
