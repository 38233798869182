import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CompanyPage } from '@modules/products/pages/company/company.page';
import { ProductPage } from '@modules/products/pages/product/product.page';
import { ProductsDashboardPage } from '@modules/products/pages/products-dashboard/products-dashboard.page';

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: '',
        pathMatch: 'full',
        component: ProductsDashboardPage,
        data: { fullWidthPage: true },
      },
      {
        path: 'product/:slug',
        component: ProductPage,
        data: { fullWidthPage: true },
      },
      {
        path: 'company/:slug',
        component: CompanyPage,
        data: { fullWidthPage: true },
      },
      {
        path: 'niet-volmacht-mutaties',
        children: [
          {
            path: '',
            pathMatch: 'full',
            component: ProductsDashboardPage,
            data: {
              fullWidthPage: true,
              isMutation: true,
            },
          },
          {
            path: ':slug',
            component: CompanyPage,
            data: {
              fullWidthPage: true,
              isMutation: true,
            },
          },
        ],
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ProductsRoutingModule {}
