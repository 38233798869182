<div class="custom-control custom-checkbox {{customClass}}">
  <input
    type="checkbox"
    class="custom-control-input"
    [id]="id"
    [(ngModel)]="value"
  />
  <label class="custom-control-label" [for]="id"
    ><ng-content></ng-content
  ></label>
</div>
<div  class="custom-control-description" *ngIf="description">
  {{description}}
</div>
