import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DashboardRoutingModule } from '@modules/dashboard/dashboard-routing.module';
import { SharedModule } from '@shared/shared.module';

import { DashboardService } from './dashboard.service';
import { DashboardPage } from './pages/dashboard/dashboard.page';
import { DraggableTilesComponent } from './pages/dashboard/draggable-tiles/draggable-tiles.component';

@NgModule({
  declarations: [DashboardPage, DraggableTilesComponent],
  imports: [CommonModule, SharedModule, DashboardRoutingModule],
  providers: [DashboardService],
})
export class DashboardModule {}
