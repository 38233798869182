// tslint:disable-next-line:import-name
import gql from 'graphql-tag';

export const companyQuery = gql`
  query company($slug: String!) {
    company(slug: $slug) {
      id
      logo
      slug
      title
      page
      website
      extranet
      contact {
        type
        address
        phone
        email
      }
      products {
        items {
          id
          slug
          title
          description_title
          description
          provision
          order
          category
          proxy_url
          type
          kind
          insurance_company {
            id
            title
            logo
            contact {
              type
              address
              phone
              email
            }
            documents {
              id
              title
              url
              type
              document_type
            }
          }
          documents {
            id
            title
            url
            type
            document_type
          }
          package {
            title
            documents {
              id
              title
              url
              type
              document_type
            }
          }
        }
      }
      contentPage {
        title
        flexible_content
        image
      }
    }
    contacts {
      type
      address
      phone
      email
      proxy_url
    }
  }
`;
