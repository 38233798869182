import { Injectable } from '@angular/core';
import { IFiltersQuery } from '@modules/products/interfaces/dto.interface';
import { filtersQuery } from '@modules/products/queries/filters.query';
import { TranslateService } from '@ngx-translate/core';
import { HtmlStringPipe } from '@shared/pipes/html-string.pipe';
import { Apollo } from 'apollo-angular';
import cloneDeep from 'lodash-es/cloneDeep';
import groupBy from 'lodash-es/groupBy';
import mapValues from 'lodash-es/mapValues';
import omit from 'lodash-es/omit';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';

import {
  ArrayFilterItem,
  DisplayFilterItem,
  IFiltersOptions,
  IFiltersValues,
} from './products-filters.interface';

const optionsToValuesLookup = {
  companies: 'companiesIds',
  categories: 'categoryId',
  subcategories: 'subcategoriesIds',
  searchBy: 'searchBy',
  kind: 'kind',
  type: 'type',
  search: 'search',
};

@Injectable()
export class ProductsFiltersService {
  options: IFiltersOptions = {
    companies: [],
    categories: [],
    subcategories: {},
    searchBy: [
      { value: null },
      { value: 'company' },
      { value: 'product' },
    ] as ArrayFilterItem[],
    kind: [
      { value: 'private' },
      { value: 'business' },
      { value: 'income' },
    ] as ArrayFilterItem[],
    type: [{ value: 'proxy' }, { value: 'non_proxy' }] as ArrayFilterItem[],
  };
  initialValues: IFiltersValues = {
    type: [],
    kind: [],
    categoryId: null,
    subcategoriesIds: [],
    companiesIds: [],
    search: null,
    searchBy: null,
  };
  values: IFiltersValues;

  displayValues = [];
  displaySubject = new BehaviorSubject([]);
  get displayFilters$() {
    return this.displaySubject.asObservable();
  }

  filtersSubject: Subject<IFiltersValues> = new Subject();
  get filters$(): Observable<IFiltersValues> {
    return this.filtersSubject.asObservable();
  }

  constructor(
    private apollo: Apollo,
    private translation: TranslateService,
    private htmlString: HtmlStringPipe,
  ) {
    this.refreshValues();
    this.getFilters();
    this.getTranslationsForFilters();
  }

  getFilters() {
    return this.apollo
      .watchQuery<IFiltersQuery>({
        query: filtersQuery,
      })
      .valueChanges.pipe(map(response => response.data))
      .subscribe(res => {
        this.options.companies = res.companies.items.map(c => ({
          label: this.htmlString.transform(c.title),
          value: c.id,
        }));
        this.options.categories = res.categories
          .filter(c => c.parent === '0')
          .map(c => ({
            label: this.htmlString.transform(c.title),
            value: c.id,
          }));
        this.options.subcategories = mapValues(
          groupBy(
            res.categories
              .filter(c => c.parent !== '0')
              .map(c => ({
                label: this.htmlString.transform(c.title),
                value: c.id,
                parent: c.parent,
              })),
            'parent',
          ),
          group => group.map(item => omit(item, 'parent')),
        );
      });
  }
  getTranslationsForFilters() {
    const subscription = this.translation
      .get('products_page.filters')
      .subscribe(res => {
        const translatableFilters = ['searchBy', 'kind', 'type'];
        translatableFilters.forEach(filter => {
          this.options[filter] = this.options[filter].map(
            (i: ArrayFilterItem) => {
              i.label = res[filter][i.value ? i.value : 'all'];
              return i;
            },
          );
        });
        if (subscription) {
          subscription.unsubscribe();
        }
      });
  }

  setSearchFilter(value: string | null) {
    if (!value || (value && value.length > 1)) {
      this.values.search = value ? value : null;
      this.displayValues = this.displayValues.filter(i => i.type !== 'search');
      if (value) {
        this.displayValues.push({
          type: 'search',
          label: this.values.search,
          value: this.values.search,
        });
      }
      this.emitUpdates();
    }
  }
  setSearchByFilter(value: string | null) {
    this.displayValues = this.displayValues.filter(i => i.type !== 'searchBy');
    if (value) {
      this.values.searchBy = value;
      this.displayValues.push({
        type: 'searchBy',
        label: this.options.searchBy.find(
          (i: ArrayFilterItem) => i.value === this.values.searchBy,
        ).label,
        value: this.values.searchBy,
      });
    } else {
      this.values.searchBy = null;
    }
    this.emitUpdates();
  }
  setKindFilter(value: string[]) {
    this.values.kind = value;
    this.createArrayTypedDisplayValue('kind', this.values.kind);
  }
  setTypeFilter(value: string[]) {
    this.values.type = value;
    this.createArrayTypedDisplayValue('type', this.values.type);
  }
  setCompanyFilter(value: string[]) {
    this.values.companiesIds = value;
    this.createArrayTypedDisplayValue('companies', this.values.companiesIds);
  }
  setCategoryFilter(value: string | null) {
    this.values.categoryId = value;
    this.displayValues = this.displayValues.filter(
      i => i.type !== 'categories',
    );
    this.displayValues.push({
      type: 'categories',
      label: this.options.categories.find(
        (i: ArrayFilterItem) => i.value === this.values.categoryId,
      ).label,
      value: this.values.categoryId,
    });
    this.emitUpdates();
  }
  setSubcategoryFilter(value: string[]) {
    this.values.subcategoriesIds = value;
    this.createArrayTypedDisplayValue(
      'subcategories',
      this.values.subcategoriesIds,
      this.values.categoryId,
    );
  }

  createArrayTypedDisplayValue(
    type: string,
    value: string[],
    categoryId?: string,
  ) {
    this.displayValues = this.displayValues.filter(i => i.type !== type);
    const options = categoryId
      ? this.options[type][categoryId]
      : this.options[type];
    this.displayValues.push(
      ...value.map(val => ({
        type,
        label: options.find((i: ArrayFilterItem) => i.value === val).label,
        value: val,
      })),
    );
    this.emitUpdates();
  }

  emitUpdates() {
    this.displaySubject.next(this.displayValues);
    this.filtersSubject.next(this.values);
  }

  removeFilter(filter: DisplayFilterItem) {
    if (['kind', 'type', 'companies', 'subcategories'].includes(filter.type)) {
      this.removeArrayTypedFilter(filter);
    } else {
      this.displayValues = this.displayValues.filter(
        i => i.type !== filter.type,
      );
      this.values[optionsToValuesLookup[filter.type]] = null;
      if (filter.type === 'categories') {
        this.values.subcategoriesIds = [];
        this.displayValues = this.displayValues.filter(
          i => i.type !== 'subcategories',
        );
      }
    }
    this.emitUpdates();
  }

  removeArrayTypedFilter(filter: DisplayFilterItem) {
    const valName = optionsToValuesLookup[filter.type];
    this.values[valName] = this.values[valName].filter(
      (v: string) => v !== filter.value,
    );
    this.displayValues = this.displayValues.filter(
      v =>
        v.type !== filter.type ||
        (v.type === filter.type && v.value !== filter.value),
    );
  }

  refreshValues() {
    this.values = cloneDeep(this.initialValues);
    this.displayValues = [];
    this.emitUpdates();
  }
}
