<div class="row {{customClass}}" >
  <div [class]="columnClass" *ngFor="let option of _options">
    <app-checkbox
      class="d-block mb-2"
      [description]="option.description"
      [(ngModel)]="option._checked"
      (ngModelChange)="checkboxChange()"
      [customClass]="customClass"
      >{{
        translateSelector
          ? (translateSelector + option[propLabel] | translate)
          : (option[propLabel] | htmlString)
      }}</app-checkbox
    >
  </div>
</div>
