import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { AlertsService } from '@shared/components/alerts/alerts.service';
import {
  ActionTypes,
  GetAlertsFail,
  GetAlertsSuccess,
} from '@shared/components/alerts/store/alerts-actions';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

@Injectable()
export class AlertsEffects {
  constructor(
    private actions$: Actions,
    private alertsService: AlertsService,
  ) {}

  @Effect()
  getAlerts$ = this.actions$.pipe(
    ofType(ActionTypes.GET_ALERTS),
    switchMap(() =>
      this.alertsService.getAlertData().pipe(
        map(alerts => {
          return new GetAlertsSuccess(alerts);
        }),
        catchError(error => of(new GetAlertsFail(error))),
      ),
    ),
  );
}
