import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { IToolTileWithOrder } from '@modules/dashboard/dashboard.interface';
import { SwitchEditMode } from '@modules/dashboard/store/dashboard-actions';
import { Store } from '@ngrx/store';
import { AlertsState } from '@shared/components/alerts/store/alerts-reducer';
import { WINDOW } from '@shared/window.token';
import range from 'lodash-es/range';
// tslint:disable-next-line: import-name
import Muuri from 'muuri';
import { BehaviorSubject } from 'rxjs';
import { Subscription } from 'rxjs/index';

import { State } from '../../../../../store/app-reducers';

@Component({
  selector: 'app-draggable-tiles',
  templateUrl: './draggable-tiles.component.html',
  styleUrls: ['./draggable-tiles.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DraggableTilesComponent implements OnInit {
  @ViewChild('tilesGrid', { static: true }) tilesGrid: ElementRef;
  @ViewChild('tilesGridSpots', { static: true }) tilesGridSpots: ElementRef;
  @ViewChild('tilesList', { static: true }) tilesList: ElementRef;

  @Input() set isEditMode(isEditMode: boolean) {
    if (isEditMode && !this.editModeState) {
      this.enableEditMode();
    } else if (!isEditMode && this.editModeState) {
      this.disableEditMode();
    }
    this.editModeState = isEditMode;
  }
  initialTiles: IToolTileWithOrder[];
  @Input() set tiles(tiles: IToolTileWithOrder[]) {
    this.initialTiles = [...tiles];
    this.initialisationTiles(tiles);
  }

  @Output() orderChanged = new EventEmitter();

  isCollapsedNav: boolean;
  isExceptionalAlert: boolean;

  public tilesUpdating = false;
  private initialSorting: string[];

  public editModeState: boolean;
  public _tiles: IToolTileWithOrder[];
  public _unusedTiles: IToolTileWithOrder[];

  private muuriGrid: any;
  private muuriList: any;

  gridSpotsCount = new BehaviorSubject([]);
  listSpotsCount = new BehaviorSubject([]);
  hoveredSpot = new BehaviorSubject(null);

  private muuriContainers: any[];

  constructor(
    @Inject(WINDOW)
    private window: Window,
    private store: Store<State>,
  ) {}

  ngOnInit() {
    this.store.select('core').subscribe(data => {
      this.isCollapsedNav = data.isCollapsedNav;
    });

    this.store.select('dashboard').subscribe(data => {
      this.editModeState = data.isEditMode;
    });

    this.store.select('alerts').subscribe(({ alert }: AlertsState) => {
      this.isExceptionalAlert =
        alert !== null && alert !== undefined ? true : false;
    });
  }

  initialisationTiles(tiles) {
    this.restoreInitialOrder();
    [this.muuriGrid, this.muuriList].forEach(grid => grid && grid.destroy());
    this.muuriGrid = this.muuriList = undefined;
    this._tiles = tiles
      .filter(tile => typeof tile.order === 'number')
      .sort((a, b) => a.order - b.order);
    this._unusedTiles = tiles.filter(tile => typeof tile.order !== 'number');

    setTimeout(() => {
      this.initMuuriGrid();
      if (this.editModeState) {
        this.initMuuriList();
      }
    }, 20);
  }

  save() {
    this.store.dispatch(new SwitchEditMode(false));
    this.disableEditMode();
  }

  cancel() {
    this.store.dispatch(new SwitchEditMode(false));
    const rootElement = <HTMLBodyElement>this.window.document.body;
    this.removeOldOverlays(rootElement);
    this.fixHeader();
    this.unsetEditModeForTilesContainer(rootElement);
    this.initialisationTiles(this.initialTiles);
    const currentSoring = this.getSorting();
    currentSoring.visible = this.initialSorting;
    this.orderChanged.emit({ currentSoring, noAlert: true });
  }

  isMobile(): boolean {
    return innerWidth < 768;
  }

  addNewTiles() {
    this.enableEditMode();
    // TODO hide for temprorary time
    // this.muuriGrid.remove(this.muuriGrid.getItems(-1));
    // this.recalculateGridSpots(-1);
    this.store.dispatch(new SwitchEditMode(true));
  }

  private initMuuriGrid() {
    this.muuriGrid = new Muuri(this.tilesGrid.nativeElement, {
      items: '.item',
      dragEnabled: true,
      dragContainer: this.window.document.body,
      dragSortInterval: 0,
      dragReleaseDuration: 400,
      dragReleaseEasing: 'ease',
      dragStartPredicate: () => this.editModeState,
      dragSort: () => this.muuriContainers,
    });

    this.recalculateGridSpots(0);

    this.muuriGrid.on('dragMove', this.handleItemDrag.bind(this));

    this.muuriGrid.on('receive', () => {
      this.recalculateGridSpots(0);
    });
    this.muuriGrid.on('send', () => {
      this.recalculateGridSpots(0);
    });
    this.muuriGrid.on('dragStart', (item: any) => {
      this.recalculateGridSpots(0);
    });
    this.muuriGrid.on('dragReleaseStart', (item: any) => {
      this.recalculateGridSpots(1);
    });

    this.muuriGrid.on('dragReleaseEnd', (item: any) => {
      this.refreshItems();
      this.muuriGrid.synchronize();
      this.muuriList.synchronize();
      this.hoveredSpot.next(null);
    });

    this.muuriGrid.on('removeItem', (item: any) => {
      this.refreshItems();
      this.muuriGrid.synchronize();
      this.muuriList.synchronize();
    });

    this.muuriContainers = [this.muuriGrid, this.muuriList];
  }

  private recalculateGridSpots(correction: number) {
    const itemsCount =
      this.tilesGrid.nativeElement.children.length + correction;
    this.gridSpotsCount.next(range(itemsCount + 1));
  }
  private recalculateListSpots(correction: number) {
    const itemsCount =
      this.tilesList.nativeElement.children.length + correction;
    this.listSpotsCount.next(range(itemsCount + 1));
  }

  private initMuuriList() {
    this.muuriList = new Muuri(this.tilesList.nativeElement, {
      dragEnabled: true,
      dragContainer: this.window.document.body,
      dragSortInterval: 0,
      dragReleaseDuration: 400,
      dragReleaseEasing: 'ease',
      dragStartPredicate: () => this.editModeState,
      dragSort: () => this.muuriContainers,
    });

    this.recalculateListSpots(0);

    this.muuriList.on('dragMove', this.handleItemDrag.bind(this));

    this.muuriList.on('receive', () => {
      this.recalculateListSpots(0);
    });
    this.muuriList.on('send', () => {
      this.recalculateListSpots(0);
    });
    this.muuriList.on('dragStart', (item: any) => {
      this.recalculateListSpots(0);
    });
    this.muuriList.on('dragReleaseStart', (item: any) => {
      this.recalculateListSpots(1);
    });

    this.muuriList.on('dragReleaseEnd', (item: any) => {
      this.refreshItems();
      this.muuriGrid.synchronize();
      this.muuriList.synchronize();
      this.hoveredSpot.next(null);
    });

    this.muuriContainers = [this.muuriGrid, this.muuriList];
  }
  private refreshItems() {
    this.muuriContainers.forEach(grid => {
      grid.refreshItems();
    });
  }

  private restoreInitialOrder() {
    if (this.muuriGrid && this.muuriList) {
      const moveToList = this.muuriGrid._items.filter(
        (i: any) => i._element.dataset.initialOrder === undefined,
      );
      moveToList.forEach((item: any) => {
        this.muuriGrid.send(item, this.muuriList, 0);
      });

      const moveToGrid = this.muuriList._items.filter(
        (i: any) => i._element.dataset.initialOrder !== undefined,
      );
      moveToGrid.forEach((item: any) => {
        this.muuriList.send(
          item,
          this.muuriGrid,
          +item._element.dataset.initialOrder,
        );
      });
    }
  }

  private getSorting() {
    return {
      visible: Array.from(this.tilesGrid.nativeElement.children).map(
        (tile: any) => tile.dataset.id,
      ),
      hidden: Array.from(this.tilesList.nativeElement.children).map(
        (tile: any) => tile.dataset.id,
      ),
    };
  }

  private handleItemDrag(item: any) {
    let rowFactor = 1;
    if (item._gridId === this.muuriGrid._id) {
      rowFactor = 3;
    }
    const posInCol = Math.floor(item._left / item._width) + 1;
    const posInRow = Math.floor(item._top / item._height);

    this.hoveredSpot.next({
      index: posInRow * rowFactor + posInCol - 1,
      grid: item._gridId,
    });
  }

  private enableEditMode() {
    const rootElement = <HTMLBodyElement>this.window.document.body;

    this.removeOldOverlays(rootElement);
    // this.unfixHeader();

    const overlay = this.buildOverlay(rootElement);
    const tilesContainer = this.setEditModeForTilesContainer(rootElement);
    this.buildOverlayContent(overlay, tilesContainer);
    setTimeout(() => {
      if (!this.muuriList) {
        this.initMuuriList();
        this.initialSorting = this.getSorting().visible;
      }
    }, 20);
  }
  private disableEditMode() {
    const rootElement = <HTMLBodyElement>this.window.document.body;
    this.removeOldOverlays(rootElement);
    this.fixHeader();
    this.unsetEditModeForTilesContainer(rootElement);
    const currentSoring = this.getSorting();
    if (
      this.initialSorting &&
      JSON.stringify(this.initialSorting) !== JSON.stringify(currentSoring)
    ) {
      this.initialSorting = currentSoring.visible;
      this.orderChanged.emit({ currentSoring, noAlert: false });
    }
  }

  private buildOverlay(rootElement: HTMLBodyElement): HTMLDivElement {
    const overlay = document.createElement('div');
    overlay.classList.add('tiles-edit-overlay');
    rootElement.appendChild(overlay);
    return overlay;
  }
  private removeOldOverlays(rootElement: HTMLBodyElement) {
    const oldOverlays = rootElement.querySelectorAll('.tiles-edit-overlay');
    oldOverlays.forEach(oldOverlay => {
      oldOverlay.remove();
    });
  }

  private setEditModeForTilesContainer(rootElement: HTMLBodyElement) {
    const tilesContainerElement = <HTMLDivElement>(
      rootElement.querySelector('.editable-tiles-container')
    );
    tilesContainerElement.style.position = 'relative';
    tilesContainerElement.style.zIndex = '10001';

    return tilesContainerElement;
  }
  private unsetEditModeForTilesContainer(rootElement: HTMLBodyElement) {
    const tilesContainerElement = <HTMLDivElement>(
      rootElement.querySelector('.editable-tiles-container')
    );
    tilesContainerElement.removeAttribute('style');
    return tilesContainerElement;
  }

  private buildOverlayContent(
    overlay: HTMLDivElement,
    tilesContainer: HTMLDivElement,
  ) {
    const containerRect = this.getElementClientRect(tilesContainer);

    const positionMap = new Map([
      ['top', 'height'],
      ['left', 'width'],
      ['right', 'left'],
      ['bottom', 'top'],
    ]);

    Array.from(positionMap.keys()).forEach(placement => {
      const el = document.createElement('div');
      el.classList.add(placement);
      el.style[positionMap.get(placement)] = `${containerRect[placement]}px`;

      overlay.appendChild(el);
    });
  }

  private unfixHeader() {
    this.window.document.querySelector<HTMLDivElement>(
      '.navbar.fixed-top',
    ).style.position = 'absolute';
  }
  private fixHeader() {
    this.window.document
      .querySelector<HTMLDivElement>('.navbar.fixed-top')
      .removeAttribute('style');
  }

  private getElementClientRect(el: HTMLElement) {
    const rect = { ...el.getBoundingClientRect() };
    const pageY = this.window.pageYOffset;
    rect.top += pageY;
    rect.bottom += pageY;
    return rect;
  }
  private getDocumentHeight() {
    const doc = this.window.document;
    return Math.max(
      doc.body.scrollHeight,
      doc.documentElement.scrollHeight,
      doc.body.offsetHeight,
      doc.documentElement.offsetHeight,
      doc.body.clientHeight,
      doc.documentElement.clientHeight,
    );
  }
}
