import { NgModule } from '@angular/core';
import { environment } from '@env/environment';
import { APOLLO_OPTIONS, ApolloModule } from 'apollo-angular';
import { HttpLink, HttpLinkModule } from 'apollo-angular-link-http';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { ApolloLink, Observable } from 'apollo-link';

export function createApollo(httpLink: HttpLink) {
  const authMiddleware = new ApolloLink((operation, forward) => {
    return new Observable(observer => {
      console.log('Operation:', operation);

      const operationName = operation.query.definitions.find(
        def => def.kind === 'OperationDefinition' && def.name,
      )?.name.value;

      console.log('Operation Name:', operationName);

      if (operationName === 'validateUserNotificationPreferencesHashRef') {
        console.log('Skipping token for validateUserNotificationPreferencesHashRef');
        forward(operation).subscribe({
          next: observer.next.bind(observer),
          error: observer.error.bind(observer),
          complete: observer.complete.bind(observer),
        });
      } else {
        const token = localStorage.getItem('token');
        if (token) {
          operation.setContext({
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
        }
        forward(operation).subscribe({
          next: observer.next.bind(observer),
          error: observer.error.bind(observer),
          complete: observer.complete.bind(observer),
        });
      }
    });
  });

  const link = ApolloLink.from([authMiddleware, httpLink.create({ uri: environment.graphql })]);
  const cache = new InMemoryCache();

  return {
    link,
    cache,
  };
}

@NgModule({
  exports: [ApolloModule, HttpLinkModule],
  providers: [
    {
      provide: APOLLO_OPTIONS,
      useFactory: createApollo,
      deps: [HttpLink],
    },
  ],
})
export class GraphQLModule { }
