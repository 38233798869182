import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NavComponent } from '@core/nav/nav.component';
import { NavService } from '@core/nav/nav.service';
import { SharedModule } from '@shared/shared.module';

import { FooterComponent } from './footer/footer.component';
import { FooterService } from './footer/footer.service';
import { HeaderComponent } from './header/header.component';
import { HeaderService } from './header/header.service';
import { NotFoundComponent } from './not-found/not-found.component';
import { CssVariablesService } from './services/css-variables.service';

const components = [FooterComponent, HeaderComponent, NavComponent];

@NgModule({
  declarations: [
    FooterComponent,
    HeaderComponent,
    NotFoundComponent,
    NavComponent,
  ],
  imports: [CommonModule, SharedModule.forRoot()],
  providers: [HeaderService, FooterService, NavService, CssVariablesService],
  exports: [FooterComponent, HeaderComponent, NotFoundComponent, NavComponent],
})
export class CoreModule {}
