import { Injectable } from '@angular/core';
import { categoryName } from '@shared/interfaces/categories.interface';

@Injectable()
export class CategoriesService {
  private categoriesList: categoryName[] = [
    'administratie',
    'inkomen',
    'schade_particulier',
    'schade_zakelijk',
    'schade_melden',
    'financieel',
  ];

  private newsCategoriesList: categoryName[] = [
    'general',
    'inkomen',
    'schade_particulier',
    'schade_zakelijk',
    'other',
  ];

  get categories(): categoryName[] {
    return [...this.categoriesList];
  }

  get newsCategories(): categoryName[] {
    return [...this.newsCategoriesList];
  }
  constructor() {}
}
