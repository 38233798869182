import { Params } from '@angular/router';
import { routerReducer, RouterReducerState } from '@ngrx/router-store';
import { ActionReducerMap } from '@ngrx/store';

import * as Core from '../core/store/core-reducer';
import * as Dashboard from '../modules/dashboard/store/dashboard-reducer';
import * as Alerts from '../shared/components/alerts/store/alerts-reducer';

interface RouterStateUrl {
  url: string;
  params: Params;
  queryParams: Params;
}

export interface State {
  routerReducer: RouterReducerState<RouterStateUrl>;
  core: Core.CoreState;
  dashboard: Dashboard.DashboardState;
  alerts: Alerts.AlertsState;
}

export const appReducers: ActionReducerMap<State> = {
  routerReducer,
  core: Core.reducer,
  dashboard: Dashboard.reducer,
  alerts: Alerts.reducer,
};
