<div class="checkbox-select" [ngClass]="{ opened: isOpened }">
  <div class="checkbox-select-value-container" (click)="isOpened = !isOpened">
    <p *ngIf="!values.length" class="checkbox-select-value-placeholder m-0">
      {{ placeholder }}
    </p>
    <div class="checkbox-select-value" *ngIf="values.length">
      <ng-container *ngFor="let option of options">
        <span
          *ngIf="values.includes(option[propValue])"
          style="max-width: 100%;"
          class="badge rounded badge-info-bg text-truncate"
          (click)="preventBubling($event)"
        >
          <a
            class="mr-1"
            (click)="preventBubling($event); uncheckItem(option[propValue])"
            >×</a
          >
          {{ option[propLabel] | htmlString }}
        </span>
      </ng-container>
    </div>

    <span class="checkbox-select-value-chevron">
      <i
        [ngClass]="{
          'nd-triangle-down': !isOpened,
          'nd-triangle-up': isOpened
        }"
      ></i>
    </span>
  </div>
  <div class="card p-3 checkbox-select-dropdown" *ngIf="isOpened">
    <app-checkboxes-list
      [columnClass]="'col-6'"
      [options]="options"
      [(ngModel)]="values"
      [propLabel]="propLabel"
      [propValue]="propValue"
      (ngModelChange)="checkboxChange()"
    ></app-checkboxes-list>
  </div>
</div>
