<div class="container-fluid products">

  <div class="breadcrumbs">
    <a class="breadcrumbs__link" [routerLink]="['/dashboard']">
      <i class="nd-arrow-left"></i>
      {{'products_page.breadcrumbs.dashboard' | translate }}
    </a>
    /
    <span class="breadcrumbs__text">
      {{
          'products_page.breadcrumbs.' +
            (state.isMutation ? 'mutations_offertes' : 'product_offer')
            | translate
        }}</span>
  </div>

  <div class="row" >
    <div class="col-12 col-xl-3 products__sidebar">
      <h2 class="title"
          [ngClass]="{'nav-collapsed': isCollapsedNav}"
      >
        {{
          'products_page.' +
            (state.isMutation ? 'mutations_page_title' : 'products_page_title')
            | translate
        }}
      </h2>

      <div class="card p-3">
        <app-products-filters [isMutation]="state.isMutation"></app-products-filters>
      </div>
    </div>

    <div class="col-12 col-xl-9 products-content">
      <nav class="tabs">
        <a
          *ngFor="let tab of state.tabs; let idx = index"
          class="tabs__item"
          href="javascript:void(0)"
          (click)="state.activeTab = tab"
          [ngClass]="{ 'active': tab === state.activeTab}"
        >
          {{ 'products_page.tabs.' + tab | translate }} ({{ state.totalCount[tab] }})
        </a>
      </nav>

      <ng-container
        *ngIf="productsFiltersService.displayFilters$ | async as displayFilters"
      >
        <app-applied-filters
          *ngIf="displayFilters.length"
          [filters]="displayFilters"
          (removeFilter)="removeFilter($event)"
          (removeAllFilters)="removeAllFilters()"
          class="d-block mb-4"
        ></app-applied-filters>
      </ng-container>

      <div
        class="mb-4"
        *ngIf="!state.isMutation"
        [hidden]="!(state.activeTab === 'products')">
        <div class="card mb-5 product-list-card">
          <app-products-list
            [isMutation]="state.isMutation"
            [products]="products"
          ></app-products-list>
        </div>

        <nav
          class="text-center"
          *ngIf="state.totalCount['products'] > state.pagination.productsSize"
        >
          <pagination
            [firstText]="'pagination.first' | translate"
            [lastText]="'pagination.last' | translate"
            [previousText]="'pagination.previous' | translate"
            [nextText]="'pagination.next' | translate"
            [maxSize]="7"
            [boundaryLinks]="true"
            [totalItems]="state.totalCount['products']"
            [ngModel]="state.pagination.productsDashboardPage"
            (pageChanged)="updatePagination($event, 'products')"
          ></pagination>
        </nav>
      </div>

      <div class="mb-4">
        <app-companies-list
          [companies]="companies"
          [isMutation]="state.isMutation"
          [hidden]="!(state.activeTab === 'companies')"
          class="mb-5"
        ></app-companies-list>

        <nav
          class="text-center"
          [hidden]="!(state.activeTab === 'companies')"
          *ngIf="state.totalCount['companies'] > state.pagination.companiesSize"
        >
          <pagination
            [firstText]="'pagination.first' | translate"
            [lastText]="'pagination.last' | translate"
            [previousText]="'pagination.previous' | translate"
            [nextText]="'pagination.next' | translate"
            [maxSize]="7"
            [boundaryLinks]="true"
            [totalItems]="state.totalCount['companies']"
            [ngModel]="state.pagination.companiesPage"
            (pageChanged)="updatePagination($event, 'companies')"
          ></pagination>
        </nav>
      </div>

      <app-last-viewed-product *ngIf="!state.isMutation" [isMutation]="!!state.isMutation"></app-last-viewed-product>
    </div>
  </div>
</div>
