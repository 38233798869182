// tslint:disable-next-line:import-name
import gql from 'graphql-tag';

import { IFaqQuery } from './faq.interface';

export const faqQuery = (query: IFaqQuery) => {
  const queryParamsDef = [];
  const queryParams = [];

  const formatQuery = (params: string[]) => `(${params.join(',')})`;

  const addToQueryObject = (param: string, type: string) => {
    queryParamsDef.push(`$${param}: ${type}`);
    queryParams.push(`${param}: $${param}`);
  };

  // tslint:disable:no-unused-expression
  typeof query.startPage === 'boolean' &&
    addToQueryObject('startPage', 'Boolean');
  query.category && addToQueryObject('category', 'String');
  query.search && addToQueryObject('search', 'String');

  return gql`
    query faqs ${queryParamsDef.length ? formatQuery(queryParamsDef) : ''} {
      faqs ${queryParams.length ? formatQuery(queryParams) : ''} {
        id
        title
        content
        show_on_top
        show_on_faq
        category
      }
    }
  `;
};
