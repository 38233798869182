import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { IContactPageResponse } from './contact.interface';
import { contactQuery, mapContactQuery } from './contact.query';

@Injectable()
export class ContactService {
  constructor(private apollo: Apollo) {}

  getContactPageData(): Observable<IContactPageResponse> {
    return this.apollo
      .watchQuery<IContactPageResponse>({
        query: contactQuery,
      })
      .valueChanges.pipe(map(response => response.data));
  }

  getMapContact() {
    return this.apollo
      .watchQuery<any>({
        query: mapContactQuery,
      })
      .valueChanges.pipe(map(response => response.data.customer));
  }
}
