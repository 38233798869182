import {
  ChangeDetectionStrategy,
  Component,
  forwardRef,
  HostListener,
  Input,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import differenceWith from 'lodash-es/differenceWith';
import isEqual from 'lodash-es/isEqual';
import without from 'lodash-es/without';

/* tslint:disable */
export const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => CheckboxSelectComponent),
  multi: true,
};
@Component({
  selector: 'app-checkbox-select',
  templateUrl: './checkbox-select.component.html',
  providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CheckboxSelectComponent implements ControlValueAccessor {
  ckickInside = false;
  @Input() options: any[];
  @Input() propLabel: string;
  @Input() propValue: string;
  @Input() placeholder: string;
  isOpened = false;
  internalValues: any[] = [];
  values: any[] = [];

  constructor() {}

  private onChangeCallback: (_: any) => void = () => {};
  private onTouchedCallback: (_: any) => void = () => {};

  @HostListener('click')
  clickInside() {
    this.ckickInside = true;
  }
  @HostListener('document:click')
  clickout() {
    if (!this.ckickInside) {
      this.isOpened = false;
    }
    this.ckickInside = false;
  }
  preventBubling(e: MouseEvent) {
    e.stopPropagation();
  }
  uncheckItem(removedValue: string) {
    this.values = without(this.values, removedValue);
    this.checkboxChange();
  }

  writeValue(v: string[]) {
    if (
      (v && v.length !== this.internalValues.length) ||
      differenceWith(v, this.internalValues, isEqual).length
    ) {
      this.values = v;
      this.internalValues = v;
      this.onChangeCallback(this.internalValues);
    }
  }
  checkboxChange() {
    this.writeValue(this.values);
  }

  registerOnChange(fn: any) {
    this.onChangeCallback = fn;
  }
  registerOnTouched(fn: any) {
    this.onTouchedCallback = fn;
  }
}
