<div class="breadcrumbs" *ngIf="asHeader">
  <a class="breadcrumbs__link" [routerLink]="['/dashboard']">
    <i class="nd-arrow-left"></i>
    Dashboard
  </a>
  /
  <a class="breadcrumbs__link" [routerLink]="[isMutation ? '/products/niet-volmacht-mutaties' : '/products']">
    {{
    'products_page.' +
    (isMutation ? 'mutations_page_title' : 'products_page_title') | translate
    }}
  </a>
  /
  <span class="breadcrumbs__text">{{ company.title | htmlString }}</span>
</div>

<div class="company-card card p-3 mb-2 position-relative">

  <div class="row">
    <div class="col-12 col-md-8 col-lg-9 mb-4 mb-lg-0">
      <div class="d-flex align-items-center">
        <img
          height="60"
          width="60"
          class="media-image"
          [src]="company.logo"
          [ngClass]="{ 'mr-2': !asHeader, 'mr-3': asHeader }"
        />
        <div>
          <div class="mb-1" [ngClass]="{ h2: asHeader, 'company-card__title': !asHeader }">
            {{ company.title | htmlString }}
            <span *ngIf="tag" class="badge rounded badge-info-bg">
              {{ tag }}
            </span>
          </div>
          <nav aria-label="breadcrumb" *ngIf="asHeader">
            <ol class="breadcrumb p-0 bg-white m-0">
              <li class="breadcrumb-item">
                <a
                  [routerLink]="[
                    asHeader && isMutation
                      ? '/products/niet-volmacht-mutaties'
                      : '/products'
                  ]"
                  class="text-light"
                >
                  {{
                    'products_page.' +
                      (isMutation
                        ? 'mutations_page_title'
                        : 'products_page_title') | translate
                  }}
                </a>
              </li>
              <li
                class="breadcrumb-item active text-primary"
                aria-current="page"
              >
                {{
                  asHeader && isMutation
                    ? ('products_list.buttons.proxy_redirect_mutation'
                      | translate)
                    : pageType
                }}:
                {{ company.title | htmlString }}
              </li>
            </ol>
          </nav>
        </div>
      </div>
    </div>

    <div
    class="col-12 col-md-4 col-lg-3"
    *ngIf="asHeader"
  >
  </div>

    <div
      class="col-12 col-md-4 col-lg-3 d-flex align-items-center flex-wrap"
      *ngIf="!asHeader"
    >
      <a
        class="btn btn-sm btn-block btn-outline-primary"
        [routerLink]="isMutation ? [company.slug] : ['company/', company.slug]"
      >
        {{ (isMutation
          ? 'products_list.buttons.proxy_redirect_mutation'
          : 'products_list.buttons.open_details_company') | translate}}

      </a>
    </div>
  </div>
</div>
