import {
  HttpErrorResponse,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { AlertsService } from './alerts.service';

@Injectable()
export class AlertsInterceptor implements HttpInterceptor {
  constructor(private alerts: AlertsService) {}
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<any> {
    return next.handle(req).pipe(
      map(res => {
        if (res['body'] && res['body'].errors) {
          res['body'].errors.forEach((error: any) => {
            const httpError = {
              name: error.extensions ? error.extensions.code : '',
              statusText: error.path ? `/${error.path.join('/')}` : '',
              message: error.message,
            } as HttpErrorResponse;
            this.alerts.addHttpError(httpError);
          });
        }
        return res;
      }),
      catchError((error: HttpErrorResponse) => {
        if (error.status !== 401) {
          this.alerts.addHttpError(error);
        }
        return throwError(error);
      }),
    );
  }
}
