<div class="container-fluid company">
  <div class="row">
    <div class="col-12" *ngIf="company">
      <app-company-card
        class="d-block w-100 mb-4"
        [pageType]="'products_list.table_headers.company_name' | translate"
        [company]="company"
        [isMutation]="isMutation"
        [asHeader]="true"
      ></app-company-card>
      <!-- <p class="text-right h5 mb-4" *ngIf="!isMutation">
        {{ 'company_page.feedback_prologue' | translate }}
        <app-incorrect-item [company]="company"></app-incorrect-item>
      </p> -->
    </div>
  </div>

  <div class="row mb-4 " *ngIf="!isMutation">
    <div class="col-12 col-md-6 mb-3 mb-md-0"
         *ngIf="company?.general_contacts?.length && hasProduct('proxy')">
      <h3 class="company__title">{{ 'company_page.contacts.title' | translate }}</h3>

      <div class="card" style="overflow: hidden;">
        <div class="table-responsive">
          <table
            class="table table-body-striped table-borderless table-hover mb-0"
          >
            <thead>
              <tr>
                <th scope="col" class="pl-3">
                  {{
                    'company_page.contacts.table_headers.department' | translate
                  }}
                </th>
                <th scope="col">
                  {{ 'company_page.contacts.table_headers.phone' | translate }}
                </th>
                <th scope="col">
                  {{ 'company_page.contacts.table_headers.email' | translate }}
                </th>
              </tr>
            </thead>
            <ng-container *ngFor="let contact of company.general_contacts">
              <tbody>
                <tr>
                  <td class="pl-3">
                    {{ contact.address | titlecase }}
                  </td>
                  <td class="phone-column">
                    <a [href]="'tel:' + contact.phone" class="text-dark">{{
                      contact.phone
                    }}</a>
                  </td>
                  <td>
                    <a [href]="'mailto:' + contact.email" class="text-dark">{{
                      contact.email
                    }}</a>
                  </td>
                </tr>
              </tbody>
            </ng-container>
          </table>
        </div>
      </div>
    </div>

    <div class="col-12 col-md-6"
         *ngIf="company?.contact?.length && hasProduct('non_proxy')">
      <h3 class="company__title">{{ 'company_page.contacts.title_non_proxy' | translate }}</h3>

      <div class="card" style="overflow: hidden;">
        <div class="table-responsive">
          <table
            class="table table-body-striped table-borderless table-hover mb-0"
          >
            <thead>
              <tr>
                <th scope="col" class="pl-3">
                  {{
                    'company_page.contacts.table_headers.department' | translate
                  }}
                </th>
                <th scope="col">
                  {{ 'company_page.contacts.table_headers.phone' | translate }}
                </th>
                <th scope="col">
                  {{ 'company_page.contacts.table_headers.email' | translate }}
                </th>
              </tr>
            </thead>

            <ng-container *ngFor="let contact of company.contact">
              <tbody>
                <tr>
                  <td class="pl-3">
                    {{ contact.address | titlecase }}
                  </td>
                  <td>
                    <a [href]="'tel:' + contact.phone" class="text-dark">{{
                      contact.phone
                    }}</a>
                  </td>
                  <td>
                    <a [href]="'mailto:' + contact.email" class="text-dark">{{
                      contact.email
                    }}</a>
                  </td>
                </tr>
              </tbody>
            </ng-container>
          </table>
        </div>
      </div>
    </div>
  </div>

  <div class="row" *ngIf="company && company.products.items.length && !isMutation">
    <div class="col-12">
      <h3 class="company__title">
        {{ 'company_page.products.title' | translate }} ({{
          company.products.items.length
        }})
      </h3>
      <div class="card" style="overflow: hidden">
        <app-products-list
          [products]="company.products.items"
          class="d-block w-100"
          [fullPageSize]="true"
        ></app-products-list>
      </div>
    </div>
  </div>

  <div class="row mt-4 mb-4" *ngIf="company && company.contentPage">
    <div class="col-12">
      <app-article
        [isMutation]="isMutation"
        [standalone]="true"
        [content]="company.contentPage.flexible_content"
      >
      </app-article>
    </div>
  </div>
</div>
