import { AgmCoreModule } from '@agm/core';
import { AgmSnazzyInfoWindowModule } from '@agm/snazzy-info-window';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ContactRoutingModule } from '@modules/contact/contact-routing.module';
import { SharedModule } from '@shared/shared.module';

import { ContactService } from './contact.service';
import { ContactPage } from './pages/contact/contact.page';

@NgModule({
  declarations: [ContactPage],
  providers: [ContactService],
  imports: [
    CommonModule,
    AgmCoreModule,
    SharedModule,
    AgmSnazzyInfoWindowModule,
    ContactRoutingModule,
  ],
})
export class ContactModule {}
