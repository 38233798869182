import { Component, OnInit } from '@angular/core';

import { IShortcutsResponse } from './news-widget.interface';
import { NewsWidgetService } from './news-widget.service';

@Component({
  selector: 'app-news-widget',
  templateUrl: './news-widget.component.html',
  styleUrls: ['./news-widget.component.scss'],
})
export class NewsWidgetComponent implements OnInit {
  data: IShortcutsResponse;

  constructor(private newsWidgetService: NewsWidgetService) {}

  ngOnInit() {
    this.newsWidgetService.getNews().subscribe((data: IShortcutsResponse) => {
      this.data = data;
    });
  }

  shortenText(str: string): string {
    return str.length > 75 ? `${str.slice(0, 75)}...` : str;
  }
}
