import { Injectable } from '@angular/core';
import { InsuranceContact } from '@modules/products/interfaces/insurance-contact.interface';
import { InsuranceProduct } from '@modules/products/interfaces/insurance-product.interface';
import { lastViewedProducts } from '@modules/products/queries/last-viewed-products.query';
import { Apollo } from 'apollo-angular';
import { cloneDeep } from 'apollo-utilities';
import { compact } from 'lodash-es';
import get from 'lodash-es/get';
import pull from 'lodash-es/pull';
import take from 'lodash-es/take';
import { BehaviorSubject, Observable } from 'rxjs';
import { first, map, pluck } from 'rxjs/operators';

interface IProductType {
  ids: string[];
  subject: BehaviorSubject<InsuranceProduct[]>;
  list$: Observable<InsuranceProduct[]>;
}
export enum productTypeEnum {
  PRODUCTS = 'products',
  MUTATIONS = 'mutations',
}

const LOCAL_STORAGE_KEYS = {
  [productTypeEnum.PRODUCTS]: 'lastViewedProducts',
  [productTypeEnum.MUTATIONS]: 'lastViewedMutations',
};

@Injectable()
export class LastViewedProductService {
  [productTypeEnum.PRODUCTS]: IProductType;
  [productTypeEnum.MUTATIONS]: IProductType;
  constructor(private apollo: Apollo) {
    [productTypeEnum.PRODUCTS, productTypeEnum.MUTATIONS].forEach(
      this.initialize.bind(this),
    );
  }

  initialize(type: productTypeEnum) {
    const lastViewedIds = JSON.parse(
      window.localStorage.getItem(LOCAL_STORAGE_KEYS[type]),
    );

    this[type] = {
      ids:
        lastViewedIds && Array.isArray(lastViewedIds)
          ? (lastViewedIds as string[])
          : [],
      subject: new BehaviorSubject([]),
    } as any;
    this[type].list$ = this[type].subject.asObservable();

    if (this[type].ids.length) {
      this.getItems(type);
    }
  }

  addItem(id: string, isMutation: boolean) {
    const type = isMutation
      ? productTypeEnum.MUTATIONS
      : productTypeEnum.PRODUCTS;
    const oldIds = [...this[type].ids];
    if (oldIds.includes(id)) {
      this[type].ids = take([id, ...pull(oldIds, id)], 4);
    } else {
      this[type].ids = [id, ...take(oldIds, 3)];
    }
    window.localStorage.setItem(
      LOCAL_STORAGE_KEYS[type],
      JSON.stringify(this[type].ids),
    );
    this.getItems(type);
  }

  async getItems(type: productTypeEnum) {
    const products = await this.apollo
      .watchQuery<{ productsByIds: { items: InsuranceProduct[] } }>({
        query: lastViewedProducts,
        variables: {
          ids: this[type].ids.map(i => Number(i)),
        },
        fetchPolicy: 'no-cache',
      })
      .valueChanges.pipe(
        pluck('data'),
        map(
          (res: {
            productsByIds: { items: InsuranceProduct[] };
            contacts: InsuranceContact[];
          }) => {
            const response = cloneDeep(res);
            if (response.contacts && response.contacts.length) {
              response.productsByIds.items.forEach(product => {
                if (product.type === 'proxy') {
                  product.insurance_company = {
                    ...product.insurance_company,
                    contact: cloneDeep(response.contacts),
                  };
                  const proxyContact = response.contacts.find(
                    (c: any) => c.type === product.kind,
                  );
                  if (proxyContact) {
                    product.general_proxy_url = proxyContact.proxy_url;
                  }
                }
              });
            }
            return get(response, 'productsByIds.items') as InsuranceProduct[];
          },
        ),
        first(),
      )
      .toPromise();
    this[type].subject.next(
      compact(this[type].ids.map(i => products.find(p => p.id === i))),
    );
  }
}
