// tslint:disable-next-line:import-name
import gql from 'graphql-tag';

export const dashboardQuery = gql`
  query dashboard {
    alerts {
      id
      title
      content
      type
      due_date
      closable
    }
    toolTiles {
      id
      title
      image
      tooltile_type
      url
      link_type
      start_date
      active
      category
      toolTileActions {
        id
        title
        values
      }
    }
    usefulLinks {
      id
      type
      link_text
      link_url
    }
    dashboardCategories {
      name
      id
    }
    userTooltiles {
      tooltileId
      order
      hidden
    }
    userProfile {
      is_wizard_completed
    }
  }
`;

export const bulkUpdateUserToolTile = gql`
  mutation bulkUpdateUserToolTile(
    $orderedVisibleTooltileIds: [ID!]!
    $orderedHiddenTooltileIds: [ID!]!
    $categoryId: ID!
  ) {
    bulkUpdateUserToolTile(
      orderedVisibleTooltileIds: $orderedVisibleTooltileIds
      orderedHiddenTooltileIds: $orderedHiddenTooltileIds
      categoryId: $categoryId
    ) {
      tooltileId
      categoryId
      order
    }
  }
`;

export const setWizardCompletion = gql`
  mutation setWizardCompletion {
    setWizardCompletion {
      is_wizard_completed
    }
  }
`;

export const markAlertAsSeen = gql`
  mutation closeAlert($alert: ID!) {
    closeAlert(alert: $alert) {
      id
    }
  }
`;

export const toolTilePreprocessRedirect = gql`
  query toolTilePreprocessRedirect($toolTileId: Int!, $toolTileActionId: Int!) {
    toolTilePreprocessRedirect(
      toolTileId: $toolTileId
      toolTileActionId: $toolTileActionId
    ) {
      url
    }
  }
`;
