import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NavService } from '@core/nav/nav.service';
import { ILinkList } from '@shared/components/links-list/links-list.interface';
import { IToolTile } from '@shared/interfaces/tool-tiles.interface';
import { Subscription } from 'rxjs';

import { INewsItem } from '../../news.interface';
import { NewsService } from '../../news.service';

@Component({
  selector: 'app-news-item',
  templateUrl: './news-item.page.html',
  styleUrls: ['./news-item.page.scss'],
})
export class NewsItemPage implements OnInit, OnDestroy {
  public article: INewsItem;
  public links: ILinkList[];
  public tiles: IToolTile[];
  isCollapsedNav: false;

  private subscriptions = new Subscription();

  constructor(
    private route: ActivatedRoute,
    private newsService: NewsService,
    private navService: NavService,
    private router: Router,
  ) {
    const subscription = this.route.params.subscribe(routeParams => {
      this.newsService.getNewsItem(routeParams.slug).subscribe(item => {
        if (!item.newsItem) {
          this.router.navigate(['news']).then();
          return;
        }
        this.article = item.newsItem;
        this.tiles =
          this.article &&
          this.article.tooltiles
            .map(used_tile =>
              item.toolTiles.find(tile => tile.id === used_tile.toString()),
            )
            .filter(tile => !!tile);
        this.links = this.article.useful_links.map(link => ({
          name: link.title,
          external: link.type === '_blank',
          url: `${link.type === '_blank' ? '' : '/support/'}${link.link_url}`,
        }));
      });
    });
  }

  ngOnInit() {
    this.navService.getToggleState().subscribe(state => {
      this.isCollapsedNav = state;
    });
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
