import { Component, Input, OnInit } from '@angular/core';
import { InsuranceCompany } from '@modules/products/interfaces/insurance-company.interface';

@Component({
  selector: 'app-company-card',
  templateUrl: './company-card.component.html',
  styleUrls: ['./company-card.component.scss'],
})
export class CompanyCardComponent implements OnInit {
  @Input() tag: string;
  @Input() pageType: string;
  @Input() asHeader = false;
  @Input() isMutation: boolean;
  @Input() company: InsuranceCompany;

  ngOnInit() {}
}
