import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import cloneDeep from 'lodash-es/cloneDeep';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import {
  IFeedbackProductCompanyIncorrect,
  IPackagesQuery,
  IPackagesQueryParams,
} from './interfaces/dto.interface';
import { companiesQuery } from './queries/companies.query';
import { companyQuery } from './queries/company.query';
import { feedbackMutation } from './queries/feedback.mutation';
import { productQuery } from './queries/product.query';
import { productsQuery } from './queries/products.query';

@Injectable()
export class ProductsService {
  constructor(private apollo: Apollo) {}

  getCompanies(query: any, isMutation = false): Observable<any> {
    query.ids = query.companiesIds.map(item => parseInt(item, 10));
    delete query.companiesIds;
    return this.apollo
      .watchQuery<any>({
        query: companiesQuery(query),
        variables: {
          ...query,
          niet_volmacht: true,
        },
      })
      .valueChanges.pipe(
        map(response => {
          return response;
        }),
      );
  }

  getProductsDashboardPageData(
    query: IPackagesQueryParams,
    isMutation = false,
  ): Observable<IPackagesQuery> {
    if (isMutation) {
      query.type = ['non_proxy'];
    }
    return this.apollo
      .watchQuery<IPackagesQuery>({
        query: productsQuery(query),
        variables: {
          ...query,
        },
      })
      .valueChanges.pipe(
        map(response => response.data),
        map((res: IPackagesQuery) => {
          const response = cloneDeep(res);

          if (response.contacts && response.contacts.length) {
            response.products.items.forEach(product => {
              if (product.type === 'proxy') {
                product.insurance_company = {
                  ...product.insurance_company,
                  contact: cloneDeep(response.contacts),
                };

                const proxyContact = response.contacts.find(
                  (c: any) => c.type === product.kind,
                );
                if (proxyContact) {
                  product.general_proxy_url = proxyContact.proxy_url;
                }
              }
            });
          }
          return response;
        }),
      );
  }
  getProduct(slug: string, isMutation = false): Observable<any> {
    return this.apollo
      .watchQuery<any>({
        query: productQuery,
        variables: {
          slug,
          mutation: isMutation,
        },
      })
      .valueChanges.pipe(map(response => response.data));
  }
  getCompany(slug: string): Observable<any> {
    return this.apollo
      .watchQuery<any>({
        fetchPolicy: 'no-cache',
        query: companyQuery,
        variables: {
          slug,
          categoryId: slug,
        },
      })
      .valueChanges.pipe(
        map(response => response.data),
        map(res => {
          const response = cloneDeep(res);

          if (
            response.company.products.items.some(e => e.type === 'proxy') &&
            response.contacts &&
            response.contacts.length
          ) {
            response.company.general_contacts = cloneDeep(response.contacts);
            response.company.products.items.forEach(product => {
              if (product.type === 'proxy') {
                product.insurance_company = {
                  ...product.insurance_company,
                  contact: cloneDeep(response.contacts),
                };
              }

              const proxyContact = response.contacts.find(
                (c: any) => c.type === product.kind,
              );
              if (proxyContact) {
                product.general_proxy_url = proxyContact.proxy_url;
              }
            });
          }

          return response;
        }),
      );
  }

  sendFeedback(data: IFeedbackProductCompanyIncorrect) {
    return this.apollo
      .mutate({
        mutation: feedbackMutation,
        variables: { ...data },
      })
      .pipe(map(response => response.data.wrongInfo.success));
  }
}
