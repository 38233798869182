import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NavService } from '@core/nav/nav.service';
import { ILinkList } from '@shared/components/links-list/links-list.interface';
import { IToolTile } from '@shared/interfaces/tool-tiles.interface';
import { Subscription } from 'rxjs';

import { ISupportItem } from '../../support.interface';
import { SupportService } from '../../support.service';

@Component({
  selector: 'app-support',
  templateUrl: './support.page.html',
  styleUrls: ['./support.page.scss'],
})
export class SupportPage implements OnInit, OnDestroy {
  isCollapsedNav: boolean;
  public article: ISupportItem;
  public links: ILinkList[] = [];
  public tiles: IToolTile[];

  private subscriptions = new Subscription();

  constructor(
    private route: ActivatedRoute,
    private supportService: SupportService,
    private navService: NavService,
  ) {
    const subscription = this.route.params.subscribe(routeParams => {
      this.supportService.getSupportPage(routeParams.slug).subscribe(item => {
        this.article = item.contentPage;

        this.tiles =
          this.article &&
          this.article.tooltiles
            .map(used_tile =>
              item.toolTiles.find(tile => tile.id === used_tile.toString()),
            )
            .filter(tile => !!tile);

        this.links =
          this.article &&
          this.article.useful_links.map(link => ({
            name: link.title,
            external: link.type === '_blank',
            url: `${link.type === '_blank' ? '' : '/support/'}${link.link_url}`,
          }));
      });
    });

    this.subscriptions.add(subscription);
  }

  ngOnInit() {
    this.navService.getToggleState().subscribe(state => {
      this.isCollapsedNav = state;
    });
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
