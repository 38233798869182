import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'toolColor',
})
export class ToolColorPipe implements PipeTransform {
  private lookup = {
    administratie: 'administratie',
    general: 'general',
    inkomen: 'inkomen',
    schade_particulier: 'schade-particulier',
    schade_zakelijk: 'schade-zakelijk',
    schade_melden: 'schade-melden',
    financieel: 'financieel',
    other: 'overig_nieuws',
  };

  transform(value: string): any {
    if (!value) {
      return '';
    }
    return `${this.lookup[value]}`;
  }
}
