<ng-container *ngIf="(tile.link_type === '_blank' || silentlyExternal) && !isPreprocessAwiLogin">
  <a
    class="d-block tool-card"
    [target]="!silentlyExternal ? '_blank' : '_self'"
    [href]="tile.url"
    [ngClass]="tile.tooltile_type | toolColor"
  >
    <i *ngIf="!silentlyExternal" class="nd-open-in-new"></i>
    <ng-container *ngTemplateOutlet="tailcontent"></ng-container>
  </a>
</ng-container>
<ng-container *ngIf="(tile.link_type === '_self' && !silentlyExternal) && !isPreprocessAwiLogin">
  <a
    class="d-block tool-card"
    [routerLink]="'/support/' + tile.url"
    [ngClass]="tile.tooltile_type | toolColor"
  >
    <ng-container *ngTemplateOutlet="tailcontent"></ng-container>
  </a>
</ng-container>
<ng-container *ngIf="isPreprocessAwiLogin">
  <span
    class="d-block tool-card"
    (click)="getLink(tile)"
    [ngClass]="tile.tooltile_type | toolColor"
  > 
    <i class="nd-open-in-new"></i>
    <ng-container *ngTemplateOutlet="tailcontent"></ng-container>
</span>
</ng-container>

<ng-template #tailcontent>
  <span
    class="d-block tool-card-title display3 mb-2"
    [ngClass]="'text-' + (tile.tooltile_type | toolColor)"
  >
    <strong>
      {{ 'global.tool_type.' + tile.tooltile_type | translate }}
    </strong>
    <span *ngIf="isNew" class="badge badge-pill badge-success">
      {{ 'global.new' | translate }}
    </span>
  </span>
  <span class="d-flex">
    <span class="tool-card-subtext">
      {{ tile.title }}
    </span>
    <span class="tool-card-icon align-self-end" *ngIf="tile.image">
      <img
        *ngIf="tile.image && tile.image !== '0'"
        class="w-100"
        [src]="tile.image"
        alt=""
      />
    </span>
  </span>
</ng-template>
