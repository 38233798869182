import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DisplayFilterItem } from '@modules/products/components/products-filters/products-filters.interface';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { ILinkList } from '@shared/components/links-list/links-list.interface';
import { categoryName } from '@shared/interfaces/categories.interface';
import { CategoriesService } from '@shared/services/categories/categories.service';
import omit from 'lodash-es/omit';
import { Subscription } from 'rxjs';
import { State } from 'src/app/store/app-reducers';

import { INews } from '../../news.interface';
import { NewsService } from '../../news.service';

@Component({
  selector: 'app-news-list',
  templateUrl: './news-list.page.html',
  styleUrls: ['./news-list.page.scss'],
})
export class NewsListPage implements OnInit, OnDestroy {
  categories: ILinkList[];
  news: INews[];
  topNewsItem: Partial<INews>;
  translateSubscription: Subscription;
  appModules: string[];

  state = {
    category: null,
    search: '',
    from: 0,
    size: 10,
    page: 1,
    total: 0,
  };

  displayFilters: DisplayFilterItem[] = [];
  categoryNames: any;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private newsService: NewsService,
    private categoriesService: CategoriesService,
    private translateService: TranslateService,
    public store: Store<State>,
  ) {
    this.generateLinksList();
  }

  ngOnInit() {
    this.route.queryParams.subscribe(res => {
      this.setSearch(res.s);
    });
    this.translateSubscription = this.translateService
      .get('global.tool_type_news')
      .subscribe(res => {
        this.categoryNames = res;
      });
    this.store.select('core').subscribe(data => {
      this.appModules = data.config.modules;
    });
  }

  modulePermission(module) {
    return this.appModules.includes(module);
  }

  private generateLinksList(): void {
    const allCategoriesValue = {
      category: null,
      name: 'Alle rubrieken',
      translate: false,
    };
    const newCategories = this.categoriesService.newsCategories.map(
      category => ({
        category,
        name: `global.tool_type_news.${category}`,
        translate: true,
      }),
    );
    this.categories = [allCategoriesValue, ...newCategories];
  }

  public setCategory(category: any | ''): void {
    this.state.page = 1;
    this.state.from = 0;
    this.state.category = category;
    this.addFilterToDisplayFilters('category', category);
    this.requestNewData();
  }

  public setSearch(term: string): void {
    if (!term || (term && term.length > 2 && term !== this.state.search)) {
      this.state.page = 1;
      this.state.from = 0;
      this.state.search = term;
      if (!term) {
        this.removeFilter({ type: 'search' } as DisplayFilterItem);
      } else {
        this.addFilterToDisplayFilters('search', term);
      }
      this.requestNewData();
    }
  }

  public setSearchQuery(term?: string): void {
    const queryParams = {};
    if (term) {
      queryParams['s'] = term;
    }
    this.router.navigate([], { queryParams, relativeTo: this.route });
  }

  public updatePagination(): void {
    this.state.from = (this.state.page - 1) * this.state.size;
    this.requestNewData();
  }

  public requestNewData(): void {
    const subscription = this.newsService
      .getNews(omit(this.state, ['page', 'total']))
      .subscribe(res => {
        this.topNewsItem = res.topNewsItem;
        this.news = res.news.items;
        this.state.total = res.news.totalCount;
        if (subscription) {
          subscription.unsubscribe();
        }
      });
  }

  addFilterToDisplayFilters(type: 'search' | 'category', value: string): void {
    const newDisplayFilters = this.displayFilters.filter(f => f.type !== type);
    newDisplayFilters.push({
      type,
      value,
      label: type === 'category' ? this.categoryNames[value] : value,
    });
    this.displayFilters = newDisplayFilters;
  }

  removeFilter($event: DisplayFilterItem): void {
    this.state.page = 1;
    this.state.from = 0;
    this.state[$event.type] = '';
    this.displayFilters = this.displayFilters.filter(
      f => f.type !== $event.type,
    );
    if ($event.type === 'search') {
      this.router.navigate([], { queryParams: {}, relativeTo: this.route });
    } else {
      this.requestNewData();
    }
  }

  removeSearch(): void {
    this.state.page = 1;
    this.state.from = 0;
    this.state.search = null;
    this.displayFilters = this.displayFilters.filter(
      item => item.type !== 'search',
    );
    this.requestNewData();
  }

  removeAllFilters(): void {
    this.state.page = 1;
    this.state.from = 0;
    this.state.search = '';
    this.state.category = null;
    this.displayFilters = [];
    this.requestNewData();
  }

  ngOnDestroy(): void {
    if (this.translateSubscription) {
      this.translateSubscription.unsubscribe();
    }
  }
}
