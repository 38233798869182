import { IAppAlert } from '@shared/components/alerts/alerts.interface';

import * as AlertsActions from './alerts-actions';

export interface AlertsState {
  alert: IAppAlert;
}
export const initialState: AlertsState = {
  alert: null,
};

export function reducer(
  state = initialState,
  action: AlertsActions.Type,
): AlertsState {
  switch (action.type) {
    case AlertsActions.ActionTypes.GET_ALERTS: {
      return {
        ...state,
      };
    }
    case AlertsActions.ActionTypes.GET_ALERTS_SUCCESS: {
      return {
        ...state,
        alert: action.payload,
      };
    }
    case AlertsActions.ActionTypes.GET_ALERTS_FAIL: {
      return {
        ...state,
      };
    }
    default:
      return state;
  }
}
