<div class="container">
  <app-alerts></app-alerts>
</div>

<div class="controls">
  <div class="controls__search">
    <input
      #inputSearch
      [ngModel]="state.search"
      type="text"
      class="controls__search-input"
      placeholder="{{ 'news_page.search.label' | translate }}"
      (keyup)="
        $event.keyCode === 13 && setSearchQuery(inputSearch.value)
      "
      (blur)="setSearchQuery(inputSearch.value)"
    />
    <button
      [disabled]="inputSearch.value.length < 2"
      class="controls__search-btn"
      (click)="setSearchQuery(inputSearch.value)"
    >
      <i class="nd-magnify"></i>
    </button>
    <button
      *ngIf="inputSearch.value.length > 0"
      class="controls__search-reset"
      (click)="removeSearch()"
    >
    <i class="nd-close"></i>
  </button>
  </div>
  <div class="controls__label">
    <i class="nd-filter"></i>
    Filters
  </div>
  <ng-select
    class="filter"
    [items]="categories"
    [dropdownPosition]="'down'"
    [searchable]="false"
    placeholder="Alle rubrieken"
    bindLabel="name"
    bindValue="category"
    [(ngModel)]="state.category"
    (ngModelChange)="setCategory($event)"
  >
  <ng-template ng-label-tmp let-item="item">
    <span class="media-name" [ngClass]="item.category | toolColor">{{item.name | translate}}</span>
  </ng-template>
  <ng-template ng-option-tmp let-item="item">
      <span class="media-name" [ngClass]="item.category | toolColor">{{item.name | translate}}</span>
  </ng-template>
</ng-select>
  <div class="controls__content">
    <button
      type="button"
      [ngClass]="displayFilters.length === 0 ? 'disabled': ''"
      class="controls__remove-filters"
      (click)="removeAllFilters()">
      Reset filters
    </button>
  </div>
</div>

<div class="news">
  <div class="breadcrumbs">
    <a class="breadcrumbs__link" [routerLink]="['/dashboard']">
      <i class="nd-arrow-left"></i>
      Dashboard
    </a>
    /
    <span class="breadcrumbs__text">Nieuws</span>
  </div>
  <div class="news__title-wrapper">
    <h2 class="news__title">Nieuwsoverzicht</h2>
    <a class="btn btn-primary notifications-btn" [routerLink]="['/notifications']" *ngIf="modulePermission('notifications')">
      Notificatievoorkeuren
    </a>
  </div>

  <span class="news__counter" *ngIf="news">{{state.total}}  Artikelen</span>
  <ng-container *ngFor="let item of news; let i = index">
    <a class="news-item"
    [routerLink]="[item.slug]"
    [ngClass]="[item.category | toolColor, i === 0 ? 'highlighted': '']">
      <div class="news-item__img-wrapper" *ngIf="item.image">
        <img class="news-item__img" [src]="item.image">
      </div>
      <div class="news-item__text-wrapper">
        <h3 class="news-item__title">{{ item.title }} <i class="nd-chevron-right"></i></h3>
        <p class="news-item__text">{{ item.short_content }}</p>
        <span class="news-item__date">{{item.created | date: 'dd.MM.yyyy'}}</span>
        <button class="news-item__read-more" type="button">Lees meer</button>
      </div>
    </a>
  </ng-container>

  <ng-container *ngIf="state.total > state.size">
    <pagination
      class="pagination"
      [firstText]="'<<'"
      [lastText]="'>>'"
      [previousText]="'<'"
      [nextText]="'>'"
      [maxSize]="4"
      [maxSize]="5"
      [boundaryLinks]="true"
      [totalItems]="state.total"
      [(ngModel)]="state.page"
      (ngModelChange)="updatePagination()"
    ></pagination>
  </ng-container>
</div>
