import { Inject, Injectable } from '@angular/core';
import { WINDOW } from '@shared/window.token';
import { fromEvent, Observable } from 'rxjs';
import { distinctUntilChanged, map, pluck } from 'rxjs/operators';

@Injectable()
export class ScrollService {
  public scroll$: Observable<any>;
  private elements = new WeakMap<
    HTMLElement | HTMLElement[],
    number | WeakMap<HTMLElement, number>
  >();

  constructor(@Inject(WINDOW) private window: Window) {
    this.scroll$ = fromEvent(this.window, 'scroll').pipe(
      map(() => this.window),
      pluck<Window, number>('pageYOffset'),
      distinctUntilChanged(),
    );
  }

  scrollTo(offset: number, behavior?: 'auto' | 'smooth') {
    this.window.scrollTo({ top: offset, behavior: behavior || 'smooth' });
  }

  reachElement(input: HTMLElement, offset: number): Observable<any> {
    this.setElementToMap(input);
    return this.scroll$.pipe(
      map(scroll => {
        const elementOffset = this.elements.get(input) as number;
        return {
          scroll,
          elementOffset,
          element: input,
          reach: elementOffset - offset < scroll,
        };
      }),
    );
  }

  reachElementinCollection(
    input: HTMLElement[],
    offset: number,
  ): Observable<any> {
    this.setElementToMap(input);

    return this.scroll$.pipe(
      map(scroll => {
        const wm = this.elements.get(input) as WeakMap<HTMLElement, number>;
        const elementOffset = input.map(el => wm.get(el)) as number[];
        const reachedOffset = elementOffset.reduce(
          (acc, item) => (acc < item && item - offset < scroll ? item : acc),
          0,
        );

        const reachedElement = input.find(i => wm.get(i) === reachedOffset);

        return {
          scroll,
          elementOffset,
          element: reachedElement,
          reach: !!reachedElement,
        };
      }),
    );
  }

  private setElementToMap(el: HTMLElement | HTMLElement[]) {
    const pageY = this.window.pageYOffset;
    const getCRTop = (e: HTMLElement) => e.getBoundingClientRect().top;

    this.elements.set(
      el,
      Array.isArray(el)
        ? new WeakMap<HTMLElement, number>(
            el.map<[HTMLElement, number]>(i => [i, getCRTop(i) + pageY]),
          )
        : getCRTop(el) + pageY,
    );

    if (Array.isArray(el)) {
      el.forEach(i => {
        i.dataset.offsetTop = (getCRTop(i) + pageY).toString();
      });
    }
  }
}
