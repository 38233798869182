import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs/index';
import { map, take } from 'rxjs/internal/operators';
import { filter, pluck } from 'rxjs/operators';

import { IConfig } from '../../config/app.config.interface';
import { State } from '../../store/app-reducers';

@Injectable()
export class ModulesGuard implements CanActivate {
  constructor(private store: Store<State>, private router: Router) {}

  getModulesFromStore(): Observable<IConfig> {
    return this.store.select('core').pipe(
      pluck('config'),
      filter(data => data !== null),
      take(1),
    );
  }

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    const currentModule = route.data.module;

    return this.getModulesFromStore().pipe(
      map(({ modules }) => {
        if (!modules.includes(currentModule)) {
          this.router.navigate(['/403']);
          return false;
        }
        return true;
      }),
    );
  }
}
