import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-links-widget',
  templateUrl: './links-widget.component.html',
  styleUrls: ['./links-widget.component.scss'],
})
export class LinsWidgetComponent implements OnInit {
  @Input() data;

  constructor() {}
  ngOnInit() {}
}
