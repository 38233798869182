import { Injectable } from '@angular/core';
import {
  ILinkNavItem,
  ILinkNavList,
  INavResponse,
} from '@core/nav/nav.interface';
import { navQuery } from '@core/nav/nav.query';
import { CollapsedNav } from '@core/store/core-actions';
import { Store } from '@ngrx/store';
import { Apollo } from 'apollo-angular';
import { Observable, Subject } from 'rxjs';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { map } from 'rxjs/operators';

import { State } from '../../store/app-reducers';

@Injectable()
export class NavService {
  toggleState = new BehaviorSubject(false);
  toggleVal = false;

  private newData = {} as ILinkNavList[];
  private _data = new Subject<ILinkNavList[]>();
  get data$(): Observable<ILinkNavList[]> {
    return this._data.asObservable();
  }
  constructor(private apollo: Apollo, private store: Store<State>) {}

  getUsefulLinksData() {
    this.apollo
      .watchQuery<INavResponse>({
        query: navQuery,
        fetchPolicy: 'network-only',
      })
      .valueChanges.pipe(
        map(response => response.data),
        map(data => {
          const info = data.usefulLinks.map(link => ({
            name: link.link_text,
            external: link.type === '_blank',
            url: `${link.type === '_blank' ? '' : '/support/'}${link.link_url}`,
            iconType: `${link.icon.type}`,
            iconClass: `${link.icon.type === 'class' ? link.icon.value : ''}`,
            iconUrl: `${link.icon.type === 'image' ? link.icon.value : ''}`,
            iconValue: `${link.icon.value}`,
            group: link.group,
          }));

          this.newData = this.transformArray(info, 'group');
          return this.newData;
        }),
      )
      .subscribe(res => {
        this._data.next(res);
      });
  }
  /*
   * transform array according to field ('group')
   * */

  transformArray(array: ILinkNavItem[], field: string): ILinkNavList[] {
    if (array) {
      const groupedObj = array.reduce((prev, cur) => {
        if (!prev[cur[field]]) {
          prev[cur[field]] = [cur];
        } else {
          prev[cur[field]].push(cur);
        }
        return prev;
      }, {});
      return Object.keys(groupedObj).map(key => ({
        key,
        value: groupedObj[key],
      }));
    }
    return [];
  }

  setToggleState(state): void {
    this.toggleState.next(state);
    localStorage.setItem('toggleState', state);
    this.store.dispatch(new CollapsedNav(state));
  }

  getToggleState(): Observable<any> {
    return this.toggleState.asObservable();
  }
}
