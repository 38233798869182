import {
  ChangeDetectionStrategy,
  Component,
  forwardRef,
  Input,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import differenceWith from 'lodash-es/differenceWith';
import isEqual from 'lodash-es/isEqual';
/* tslint:disable */
export const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => CheckboxesListComponent),
  multi: true,
};

@Component({
  selector: 'app-checkboxes-list',
  templateUrl: './checkboxes-list.component.html',
  styleUrls: ['./checkboxes-list.component.scss'],
  providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR],
})
export class CheckboxesListComponent implements ControlValueAccessor {
  _columnClass = 'col-12';
  @Input() set columnClass(columnClass: string) {
    this._columnClass = columnClass;
  }
  get columnClass() {
    return this._columnClass;
  }
  @Input() customClass: string;
  @Input() translateSelector: string;
  @Input() propLabel: string;
  @Input() propValue: string;

  @Input() set options(options: { [key: string]: any }[]) {
    if (options) {
      this._options = options.map(o => {
        const existingOption = this._options.find(
          _o => _o[this.propValue] === o[this.propValue],
        );
        return {
          _checked: !!existingOption && !!existingOption._checked,
          ...o,
        };
      });
    } else {
      this._options = [];
    }
  }
  private onChangeCallback: (_: any) => void = () => {};
  private onTouchedCallback: (_: any) => void = () => {};

  values: string[] = [];
  _options: { [key: string]: any; _checked: boolean }[] = [];

  constructor() {}

  writeValue(v: string[]) {
    if (
      (v && v.length !== this.values.length) ||
      differenceWith(v, this.values, isEqual).length
    ) {
      if (this._options) {
        this._options.forEach(o => {
          o._checked = v.includes(o[this.propValue]);
        });
      }
      this.values = v;
      this.onChangeCallback(v);
    }
  }

  checkboxChange() {
    this.writeValue(
      this._options.filter(o => o._checked).map(v => v[this.propValue]),
    );
  }

  registerOnChange(fn: any) {
    this.onChangeCallback = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouchedCallback = fn;
  }
}
