import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NewsItemPage } from '@modules/news/pages/news-item/news-item.page';
import { NewsListPage } from '@modules/news/pages/news-list/news-list.page';
import { KeycloakWrapperAuthGuard } from '@shared/keycloak/keycloak-auth.guard';

const routes: Routes = [
  {
    path: '',
    canActivate: [KeycloakWrapperAuthGuard],
    children: [
      {
        path: '',
        pathMatch: 'full',
        component: NewsListPage,
      },
      {
        path: ':slug',
        component: NewsItemPage,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class NewsRoutingModule {}
