import { Component, EventEmitter, Input, Output } from '@angular/core';
import flatten from 'lodash-es/flatten';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { IAppAlert } from './alerts.interface';
import { AlertsService } from './alerts.service';

@Component({
  selector: 'app-alerts',
  templateUrl: './alerts.component.html',
})
export class AlertsComponent {
  @Input() set alerts(data: IAppAlert[]) {
    if (data && Array.isArray(data)) {
      this.alertsSubject.next(data);
    }
  }

  @Output() onClose = new EventEmitter();

  initTime = Date.now();

  private alertsSubject: BehaviorSubject<IAppAlert[]> = new BehaviorSubject([]);
  public alerts$: Observable<IAppAlert[]>;

  constructor(private service: AlertsService) {
    this.alerts$ = combineLatest(
      this.alertsSubject.asObservable(),
      this.service.alerts$,
    ).pipe(map(flatten));
  }

  onClosed(alert: IAppAlert, event) {
    this.service.closeError(alert.id);
    this.onClose.emit({
      alert,
      closedByUser: Date.now() - this.initTime < event.dismissOnTimeout,
      dismissOnTimeout: event.dismissOnTimeout,
    });
  }
}
