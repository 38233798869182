import * as DashboardActions from './dashboard-actions';

export interface DashboardState {
  isEditMode: boolean;
}
export const initialState: DashboardState = {
  isEditMode: false,
};
export function reducer(
  state = initialState,
  action: DashboardActions.Type,
): DashboardState {
  switch (action.type) {
    case DashboardActions.ActionTypes.SWITCH_EDIT_MODE: {
      return {
        ...state,
        isEditMode: action.payload,
      };
    }
    default:
      return state;
  }
}
