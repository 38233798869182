// tslint:disable-next-line:import-name
import gql from 'graphql-tag';

export const productQuery = gql`
  query product($slug: String!, $mutation: Boolean) {
    product(slug: $slug, mutation: $mutation) {
      id
      slug
      title
      description_title
      description
      provision
      order
      category
      type
      kind
      contentPage {
        title
        flexible_content
        image
      }
      insurance_company {
        id
        title
        logo
        website
        documents {
          id
          title
          url
          type
          document_type
        }
        contact {
          type
          address
          phone
          email
        }
      }
      documents {
        id
        title
        url
        type
        document_type
      }
      package {
        title
        documents {
          id
          title
          url
          type
          document_type
        }
      }
    }
  }
`;
