import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { Observable } from 'rxjs';

import { IShortcutsResponse } from './shortcuts.interface';
import { ShortcutsService } from './shortcuts.service';

@Component({
  selector: 'app-shortcuts',
  templateUrl: './shortcuts.component.html',
  styleUrls: ['./shortcuts.component.scss'],
})
export class ShortcutsComponent implements OnInit, OnChanges {
  @Input() background: 'white' | 'light';
  data$: Observable<IShortcutsResponse>;

  constructor(private shortcutsService: ShortcutsService) {}

  ngOnInit() {
    this.data$ = this.shortcutsService.getData();
  }

  ngOnChanges() {
    if (!this.background) {
      this.background = 'light';
    }
  }

  shortenText(str: string) {
    return str.length > 75 ? `${str.slice(0, 75)}...` : str;
  }
}
