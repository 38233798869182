import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { AlertsState } from '@shared/components/alerts/store/alerts-reducer';

import { State } from '../../../store/app-reducers';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent {
  isCollapsed = true;
  isExceptionalAlert: boolean;

  constructor(private store: Store<State>) {
    this.store.select('alerts').subscribe(({ alert }: AlertsState) => {
      this.isExceptionalAlert =
        alert !== null && alert !== undefined ? true : false;
    });
  }
}
