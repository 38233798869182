import { Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-input-radio-list',
  templateUrl: './input-radio-list.component.html',
  styleUrls: ['./input-radio-list.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputRadioListComponent),
      multi: true,
    },
  ],
})
export class InputRadioListComponent implements ControlValueAccessor {
  modelValue: any;

  @Input() options: any[];
  @Input() name: string;
  @Input() inline = true;
  @Input() disabled = false;
  @Input() class = '';

  private propagateChange = (_: any) => {
    // Intentionally empty
  }

  public writeValue(obj: any) {
    this.modelValue = obj;
  }

  public registerOnChange(fn: any) {
    this.propagateChange = fn;
  }

  public registerOnTouched() {
    // Intentionally empty
  }

  onChange(_event: any) {
    this.propagateChange(this.modelValue);
  }
}
