import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { categoryName } from '@shared/interfaces/categories.interface';

import { ILinkList } from './links-list.interface';

@Component({
  selector: 'app-links-list',
  templateUrl: './links-list.component.html',
})
export class LinksListComponent implements OnInit {
  _links: ILinkList[];
  @Input() set links(links: ILinkList[]) {
    this._links = links.map(link => {
      const newLink = { ...link };
      if (link.external && link.url && link.url.includes(`${location.host}/`)) {
        newLink.external = false;
        newLink.url = link.url.split(location.host)[1];
      }
      return newLink;
    });
  }
  @Input() selected: categoryName;
  @Output() linkClicked: EventEmitter<ILinkList> = new EventEmitter();

  constructor() {}

  ngOnInit() {}

  click(link: ILinkList) {
    this.linkClicked.emit(link);
  }
}
