<div [ngClass]="{ 'radio-group-inline': inline, disabled: disabled }" class="{{class}}">
  <div 
    class="custom-radio" 
    *ngFor="let option of options">
    <input type="radio" 
      class="custom-radio__input" 
      [disabled]="disabled" 
      name='form-radio-{{name}}' 
      id="form-radio-{{name}}-{{option.id}}" 
      [value]="option.id" 
      [(ngModel)]="modelValue" 
      hidden
      (change)="onChange()">
    <label 
      class="custom-radio__label" 
      for="form-radio-{{name}}-{{option.id}}">
      {{option.name}}
      <small *ngIf="option.description" class="form-text text-muted">{{option.description}}</small>
    </label>
  </div>
</div>