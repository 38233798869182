<div class="filters">
  <div class="filters__title-wrapper">
    <h3 class="filters__title"> {{ 'applied_filters.name' | translate }}</h3>
    <span class="filters__counter">{{ filters.length }}</span>
  </div>
  <div class="filters__list">
    <span 
      class="filters__item" 
      *ngFor="let filter of filters"
      (click)="filterClicked(filter)"
      >
      {{ filter.label }}
      <i class="nd-close"></i>
    </span>
  </div>
  <button
    class="filters__reset-btn"
    (click)="clearButtonClicked()"
  >
  {{ 'applied_filters.clear' | translate }}
  </button>
</div>
