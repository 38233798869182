import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { map } from 'rxjs/operators';

import { contactCustomerQuery } from './contact-widget.query';

@Injectable()
export class ContactWidgetService {
  constructor(private apollo: Apollo) {}

  getContactData() {
    return this.apollo
      .watchQuery<any>({
        query: contactCustomerQuery,
      })
      .valueChanges.pipe(
        map(response => {
          return response.data.customer;
        }),
      );
  }
}
