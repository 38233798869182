import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NotFoundComponent } from '@core/not-found/not-found.component';
import { SupportPage } from '@modules/support/pages/support/support.page';
import { KeycloakWrapperAuthGuard } from '@shared/keycloak/keycloak-auth.guard';

const routes: Routes = [
  {
    path: '',
    canActivate: [KeycloakWrapperAuthGuard],
    children: [
      {
        path: '',
        pathMatch: 'full',
        component: NotFoundComponent,
      },
      {
        path: ':slug',
        component: SupportPage,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class SupportRoutingModule {}
