import { IConfig } from 'src/app/config/app.config.interface';

import * as CoreActions from './core-actions';

export interface CoreState {
  config: IConfig;
  isCollapsedNav: boolean;
}

export const initialState: CoreState = {
  config: null,
  isCollapsedNav: false,
};

export function reducer(
  state = initialState,
  action: CoreActions.Type,
): CoreState {
  switch (action.type) {
    case CoreActions.ActionTypes.LOAD_CONFIG: {
      return {
        ...state,
      };
    }
    case CoreActions.ActionTypes.LOAD_CONFIG_SUCCESS: {
      return {
        ...state,
        config: action.payload,
      };
    }
    case CoreActions.ActionTypes.LOAD_CONFIG_FAIL: {
      return {
        ...state,
      };
    }
    case CoreActions.ActionTypes.COLLAPSED_NAV: {
      return {
        ...state,
        isCollapsedNav: action.payload,
      };
    }

    default:
      return state;
  }
}
