

<div class="news-item sidebar-margin" [ngClass]="{'is-nav-collapsed': isCollapsedNav}">
  <div class="container">
    <app-alerts></app-alerts>
  </div>
  <div class="breadcrumbs">
    <a class="breadcrumbs__link" [routerLink]="['/dashboard']">
      <i class="nd-arrow-left"></i>
      Dashboard
    </a>
    /
    <a class="breadcrumbs__link"[routerLink]="['/news']">Nieuws</a>
    /
    <span class="breadcrumbs__text">{{article?.title}}</span>
  </div>
  <app-article
    *ngIf="article"
    [title]="article.title"
    [category]="article.category"
    [date]="article.created"
    [content]="article.flexible_content"
    [articleType]="'news'"
  ></app-article>
</div>

<app-sidebar [ngClass]="{'is-nav-collapsed': isCollapsedNav}">
  <app-news-widget></app-news-widget>
</app-sidebar>