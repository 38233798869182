<ul class="list-group list-group-flush tails-list">
  <li class="list-group-item" *ngFor="let item of _links">
    <ng-container *ngIf="item.external && item.url">
      <a
        [href]="item.url"
        target="_blank"
        [ngClass]="[
          item.category | toolColor,
          item.category && selected === item.category ? 'active' : ''
        ]"
      >
        {{ item.translate ? (item.name | translate) : item.name }}
        <i class="nd-open-in-new"></i>
      </a>
    </ng-container>
    <ng-container *ngIf="!item.external && item.url">
      <a
        [routerLink]="item.url"
        [ngClass]="[
          item.category | toolColor,
          item.category && selected === item.category ? 'active' : ''
        ]"
      >
        {{ item.translate ? (item.name | translate) : item.name }}
        <i class="nd-chevron-right-small"></i>
      </a>
    </ng-container>
    <ng-container *ngIf="!item.url">
      <a
        href="javascript:void(0)"
        (click)="click(item)"
        [ngClass]="[
          item.category | toolColor,
          item.category && selected === item.category ? 'active' : ''
        ]"
      >
        {{ item.translate ? (item.name | translate) : item.name }}
        <i class="nd-chevron-right-small"></i>
      </a>
    </ng-container>
  </li>
</ul>
