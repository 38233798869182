import { Component, Input, OnInit } from '@angular/core';
import { toolTilePreprocessRedirect } from '@modules/dashboard/dashboard.query';
import {
  IToolTile,
  IToolTileRedirectUrl,
} from '@shared/interfaces/tool-tiles.interface';
import { Apollo } from 'apollo-angular';
import { map, take } from 'rxjs/operators';

@Component({
  selector: 'app-tool-card',
  templateUrl: './tool-card.component.html',
})
export class ToolCardComponent implements OnInit {
  isNew = false;
  silentlyExternal = false;
  isPreprocessAwiLogin = false;
  awiLoginActionId;
  @Input() tile: IToolTile;

  constructor(private apollo: Apollo) {}

  ngOnInit() {
    if (this.tile) {
      const tileDate = new Date(this.tile.start_date);
      this.isNew = Date.now() < tileDate.setMonth(tileDate.getMonth() + 1);

      if (
        this.tile.link_type === '_self' &&
        (this.tile.url.includes('http://') ||
          this.tile.url.includes('https://'))
      ) {
        this.silentlyExternal = true;
      }
      this.checkPreprocessAwiLogin(this.tile);
    }
  }

  checkPreprocessAwiLogin(item) {
    item.toolTileActions.map(data => {
      const awiLoginValue = JSON.parse(data.values.toString());
      this.isPreprocessAwiLogin = awiLoginValue.type === 'preprocess_awi_login';
      if (this.isPreprocessAwiLogin) {
        this.awiLoginActionId = data.id;
      }
    });
  }

  getLink(tile) {
    const toolTileId = +tile.id;
    const toolTileActionId = +this.awiLoginActionId;
    this.apollo
      .watchQuery<IToolTileRedirectUrl>({
        query: toolTilePreprocessRedirect,
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
        variables: {
          toolTileId,
          toolTileActionId,
        },
      })
      .valueChanges.pipe(
        map((response: any) => response.data),
        take(1),
      )
      .subscribe(res => {
        if (res.toolTilePreprocessRedirect.url) {
          tile.url = res.toolTilePreprocessRedirect.url;
        }
        window.open(tile.url, tile.link_type);
      });
  }
}
