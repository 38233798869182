// tslint:disable-next-line:import-name
import gql from 'graphql-tag';

export const alertsdQuery = gql`
  query {
    alerts {
      id
      title
      type
      content
      due_date
      closable
    }
  }
`;
