<div class="contact-widget" *ngIf="contactData" id="step6">
    <h3 class="contact-widget__title">{{ 'shortcuts.contact' | translate }}</h3>
    <div class="contact-widget__info">
        <i class="nd-phone-outline"></i>
        {{contactData.phone}}
    </div>
    <div class="contact-widget__info">
        <i class="nd-envelope"></i>
        {{contactData.email}}
    </div>
    <a class="contact-widget__link" [routerLink]="['/contact']">
        {{ 'shortcuts.all_contacts_link' | translate }}
        <i class="nd-chevron-right-small"></i>
    </a>
    <a class="contact-widget__link" [routerLink]="['/faq']">
        {{ 'shortcuts.faq_link' | translate }}
        <i class="nd-chevron-right-small"></i>
    </a>
</div>
