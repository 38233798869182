// tslint:disable-next-line:import-name
import gql from 'graphql-tag';

export const navQuery = gql`
  query nav {
    usefulLinks {
      id
      type
      link_text
      link_url
      icon {
        type
        value
      }
      group
    }
  }
`;
