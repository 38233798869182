// tslint:disable-next-line:import-name
import gql from 'graphql-tag';

export const contactCustomerQuery = gql`
  {
    customer {
      phone
      email
    }
  }
`;
