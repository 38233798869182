import { Injectable } from '@angular/core';
import { categoryName } from '@shared/interfaces/categories.interface';
import { CategoriesService } from '@shared/services/categories/categories.service';
import { Apollo } from 'apollo-angular';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { IFaq, IFaqQuery, IFaqResponse } from './faq.interface';
import { faqQuery } from './faq.query';

@Injectable()
export class FaqService {
  constructor(
    private apollo: Apollo,
    private categoriesService: CategoriesService,
  ) {}

  private getFaqsResponseTemplate(): IFaqResponse {
    return this.categoriesService.categories.reduce((acc, category) => {
      acc[category] = {
        top: [],
        all: [],
      };
      return acc;
    }, {}) as IFaqResponse;
  }

  getFaqs(
    query: IFaqQuery,
  ): Observable<{ total: number; items: IFaqResponse } | IFaq[]> {
    return this.apollo
      .watchQuery<{ faqs: IFaq[] }>({
        query: faqQuery(query),
        variables: {
          ...query,
        },
      })
      .valueChanges.pipe(
        map(response => response.data),
        map<{ faqs: IFaq[] }, { total: number; items: IFaqResponse } | IFaq[]>(
          data => {
            let response;
            if (query.startPage) {
              response = data.faqs;
            } else {
              response = {};
              response.total = data.faqs.length;
              response.items = data.faqs.reduce((acc, item) => {
                acc[item.category][item.show_on_top ? 'top' : 'all'].push(item);
                return acc;
              }, this.getFaqsResponseTemplate());
            }
            return response;
          },
        ),
      );
  }
}
