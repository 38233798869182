import { Inject, Injectable } from '@angular/core';
import {
  breakpointName,
  IAllBreakpoints,
  IBreakpointSizes,
} from '@shared/interfaces/media-breakpoint.interface';
import { WINDOW } from '@shared/window.token';
import { BehaviorSubject, fromEvent, Observable } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  map,
  mergeMap,
  pluck,
} from 'rxjs/operators';

@Injectable()
export class MediaBreakpointService {
  private bsBreakpoints: IAllBreakpoints = {
    xs: {
      to: 575,
    },
    sm: {
      from: 576,
      to: 767,
    },
    md: {
      from: 768,
      to: 991,
    },
    lg: {
      from: 992,
      to: 1199,
    },
    xl: {
      from: 1200,
    },
  };

  public resizeEvent$: Observable<number>;
  public resizeBreakpointSubject = new BehaviorSubject(
    this.getCurrentState(this.window.innerWidth),
  );
  public resizeBreakpoint$: Observable<
    breakpointName
  > = this.resizeBreakpointSubject.asObservable();

  constructor(@Inject(WINDOW) private window: Window) {
    this.resizeEvent$ = fromEvent(this.window, 'resize').pipe(
      debounceTime(100),
      distinctUntilChanged(),
      pluck<Event, Window>('target'),
      pluck<Window, number>('innerWidth'),
    );
    this.resizeEvent$.subscribe(size => {
      this.resizeBreakpointSubject.next(this.getCurrentState(size));
    });
  }
  getCurrentState(currentWidth: number): breakpointName {
    for (const key in this.bsBreakpoints) {
      if (this.bsBreakpoints.hasOwnProperty(key)) {
        const breakpoint = this.bsBreakpoints[key] as IBreakpointSizes;

        const conditionXS = !breakpoint.from && breakpoint.to >= currentWidth;
        const conditionXL = !breakpoint.to && breakpoint.from <= currentWidth;
        const conditionRest =
          breakpoint.from <= currentWidth && breakpoint.to >= currentWidth;

        if (conditionXS || conditionXL || conditionRest) {
          return key as breakpointName;
        }
      }
    }
  }
}
