import { Component, OnDestroy, OnInit } from '@angular/core';
import { ILinkNavList } from '@core/nav/nav.interface';
import { NavService } from '@core/nav/nav.service';
import { CollapsedNav } from '@core/store/core-actions';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs/index';

import { State } from '../../store/app-reducers';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss'],
})
export class NavComponent implements OnInit, OnDestroy {
  isCollapsedNav: boolean;
  toggleStateSub: Subscription;
  links: ILinkNavList[];
  subscriptions = new Subscription();
  dataSubscription = new Subscription();

  constructor(private navService: NavService, private store: Store<State>) {}

  ngOnInit() {
    this.dataSubscription = this.navService.data$.subscribe(links => {
      this.links = links;
    });

    this.navService.getUsefulLinksData();
    this.subscriptions.add(this.dataSubscription);

    this.store.select('core').subscribe(data => {
      this.isCollapsedNav = data.isCollapsedNav;
    });
  }

  // Temporary check for links. (We have to remove it in the future)
  // Now we need that some external links should look like internal
  isLinkShouldLooksLikeExternal(linkURL) {
    return linkURL.includes('http') || linkURL.includes('https');
  }

  toggleSidebar(state): void {
    if (state !== null && state !== undefined) {
      this.isCollapsedNav
        ? this.store.dispatch(new CollapsedNav(false))
        : this.store.dispatch(new CollapsedNav(true));
    }
    this.navService.setToggleState(this.isCollapsedNav);
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
