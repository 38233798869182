import { Component, Input, OnChanges } from '@angular/core';
import { InsuranceContact } from '@modules/products/interfaces/insurance-contact.interface';
import { InsuranceDocument } from '@modules/products/interfaces/insurance-document.interface';
import { InsuranceProduct } from '@modules/products/interfaces/insurance-product.interface';
import flatten from 'lodash-es/flatten';

@Component({
  // tslint:disable-next-line:component-selector
  selector: '[app-product-item-expanded]',
  templateUrl: './product-item-expanded.component.html',
  styleUrls: ['./product-item-expanded.component.scss'],
})
export class ProductItemExpandedComponent implements OnChanges {
  @Input() product: InsuranceProduct;
  @Input() fullPageSize: boolean;
  documents: InsuranceDocument[];
  documentsExpanded = false;
  contact: InsuranceContact;

  ngOnChanges() {
    if (this.product) {
      this.documents = [
        ...this.product.documents,
        ...this.product.insurance_company.documents,
        ...(this.product.package.length
          ? flatten(this.product.package.map(p => p.documents)) || []
          : []),
      ];

      if (this.product.type === 'proxy') {
        this.contact = this.product.insurance_company.contact.find(
          (c: any) => c.type === this.product.kind,
        );
      } else {
        this.contact = this.product.insurance_company.contact[0];
      }
      this.contact.contact_url = this.product.contact_url;
    }
  }
}
