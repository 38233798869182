import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { map } from 'rxjs/operators';

import { IShortcutsResponse } from './news-widget.interface';
import { shortcutsQuery } from './news-widget.query';

@Injectable()
export class NewsWidgetService {
  constructor(private apollo: Apollo) {}

  getNews() {
    return this.apollo
      .watchQuery<IShortcutsResponse>({
        query: shortcutsQuery,
        variables: {
          includeTopItem: false,
          size: 3,
        },
      })
      .valueChanges.pipe(map(response => response.data));
  }
}
