import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { NavService } from '@core/nav/nav.service';
import { SwitchEditMode } from '@modules/dashboard/store/dashboard-actions';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { AlertsService } from '@shared/components/alerts/alerts.service';
import { categoriesDict } from '@shared/interfaces/dashboard-categories.interface';
import { KeycloakWrapperService } from '@shared/keycloak/keycloak-wrapper.service';
import { MediaBreakpointService } from '@shared/services/media-breakpoint/media-breakpoint.service';
import { OnboardingService } from '@shared/services/onboarding/onboarding.service';
import { KeycloakProfile } from 'keycloak-js';
import invert from 'lodash-es/invert';
import { Observable, Subscription } from 'rxjs';
import { combineLatest, filter, map } from 'rxjs/operators';

import { State } from '../../../../store/app-reducers';
import {
  DayTime,
  IDashboardCategoryWithTiles,
  IDashboardData,
} from '../../dashboard.interface';
import { DashboardService } from '../../dashboard.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.page.html',
  styleUrls: ['./dashboard.page.scss'],
})
export class DashboardPage implements OnInit, OnDestroy {
  isCollapsedNav: boolean;
  toggleStateSub: Subscription;

  public categoriesDict = categoriesDict;
  public data: IDashboardData;
  public auth$: Observable<KeycloakProfile>;
  public dayTime: DayTime;
  public state = {
    activeCategory: undefined,
    isEditMode: false,
  };

  public dashboardCategoriesWithTiles: IDashboardCategoryWithTiles[];
  private subscriptions = new Subscription();

  constructor(
    private dashboardService: DashboardService,
    private keycloakWrapperService: KeycloakWrapperService,
    private mediaBreakpointService: MediaBreakpointService,
    private alertsService: AlertsService,
    private onboardingService: OnboardingService,
    private translateService: TranslateService,
    private route: ActivatedRoute,
    private navService: NavService,
    private store: Store<State>,
  ) {
    this.auth$ = this.keycloakWrapperService.getUserProfile();
    const dataSubscription = this.dashboardService.data$
      .pipe(combineLatest(this.route.queryParams))
      .subscribe(res => {
        this.data = res[0];
        if (res[1].category) {
          this.handleQueryParamsChanged(res[1]);
        } else {
          this.dashboardCategoriesWithTiles = res[0].dashboardCategories.reduce(
            (arr, item) => (item.tiles.length && arr.push(item), arr),
            [],
          );
          this.setSelectedCategory(this.dashboardCategoriesWithTiles[0]);
        }
        if (this.data.alerts) {
          this.data.alerts = this.data.alerts.filter(
            item => item.type !== 'exceptional',
          );
        }
        if (!res[0].userProfile.is_wizard_completed) {
          this.initTour(res[0].dashboardCategories);
        }
      });
    this.dashboardService.getDashboardData();

    this.subscriptions.add(dataSubscription);

    this.dayTime = this.getDayTime(new Date().getHours());

    const subscription = this.mediaBreakpointService.resizeBreakpoint$
      .pipe(
        filter(size => ['xs', 'sm', 'md'].includes(size)),
        map(isSmallScreen => isSmallScreen && this.state.isEditMode),
      )
      .subscribe(() => {
        this.store.dispatch(new SwitchEditMode(false));
      });
    this.subscriptions.add(subscription);
  }

  ngOnInit() {
    this.store.select('core').subscribe(data => {
      this.isCollapsedNav = data.isCollapsedNav;
    });

    this.store.select('dashboard').subscribe(data => {
      this.state.isEditMode = data.isEditMode;
    });
  }
  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  initTour(categories: IDashboardCategoryWithTiles[]) {
    const onExitCb = () => {
      this.dashboardService.completeWizard();
    };
    this.onboardingService.initTour(categories, onExitCb);
  }

  handleQueryParamsChanged(params: Params) {
    const invertedCategories = invert(this.categoriesDict);
    const categoryId = invertedCategories[params.category];
    const category = this.data.dashboardCategories.find(
      cat => cat.id === categoryId,
    );
    this.setSelectedCategory(category);
  }

  setSelectedCategory(category: IDashboardCategoryWithTiles) {
    this.state.activeCategory = category;
  }

  getDayTime(time: number): DayTime {
    const timeMap = {
      night: { from: 0, to: 6 },
      morning: { from: 6, to: 12 },
      day: { from: 12, to: 18 },
      evening: { from: 18, to: 24 },
    };
    const pickTime = (t: number) => {
      let r: DayTime;
      for (r in timeMap) {
        if (timeMap.hasOwnProperty(r)) {
          const range = timeMap[r];
          if (t >= range.from && t < range.to) {
            return r;
          }
        }
      }
    };

    return pickTime(time);
  }

  handleReordering(order: {
    currentSoring: { visible: string[]; hidden: string[] };
    noAlert: true;
  }) {
    const translationSubscription = this.translateService
      .get('dashboard_page.success_reorder')
      .subscribe(message => {
        const serviceSubscription = this.dashboardService
          .reorderElements(
            this.state.activeCategory,
            order.currentSoring.visible,
            order.currentSoring.hidden,
          )
          .subscribe(res => {
            if (res && res.data && !order.noAlert) {
              this.alertsService.addSuccess(message);
            }
            if (serviceSubscription) {
              serviceSubscription.unsubscribe();
            }
            if (translationSubscription) {
              translationSubscription.unsubscribe();
            }
          });
      });
  }

  onAlertClose(event) {
    if (!event.dismissOnTimeout || event.closedByUser) {
      this.dashboardService.markAlertAsSeen(event.alert.id);
    }
  }
}
