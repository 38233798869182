import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NewsRoutingModule } from '@modules/news/news-routing.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { SharedModule } from '@shared/shared.module';

import { NewsService } from './news.service';
import { NewsItemPage } from './pages/news-item/news-item.page';
import { NewsListPage } from './pages/news-list/news-list.page';

@NgModule({
  declarations: [NewsListPage, NewsItemPage],
  imports: [CommonModule, SharedModule, NewsRoutingModule, NgSelectModule],
  providers: [NewsService],
})
export class NewsModule {}
