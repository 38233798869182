import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DisplayFilterItem } from '@modules/products/components/products-filters/products-filters.interface';

@Component({
  selector: 'app-applied-filters',
  templateUrl: './applied-filters.component.html',
  styleUrls: ['./applied-filters.component.scss'],
})
export class AppliedFiltersComponent implements OnInit {
  @Input() filters: DisplayFilterItem[];
  @Output() removeFilter = new EventEmitter();
  @Output() removeAllFilters = new EventEmitter();
  constructor() {}
  ngOnInit() {}

  filterClicked(filter: DisplayFilterItem) {
    this.removeFilter.emit(filter);
  }
  clearButtonClicked() {
    this.removeAllFilters.emit(true);
  }
}
