<div class="footer">
  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-12 col-md-12 col-lg-auto col-lg-auto">
        <div class="footer-copyright" *ngIf="footerTitle">{{footerTitle}}</div>
      </div>
      <div class="col-sm-12 col-md-12 col-lg order-lg-2">
        <ul class="footer-links">
          <li *ngFor="let link of footerLinks$ | async">
            <ng-container *ngIf="link.type === '_blank'">
              <a class="link" [href]="link.link" target="_blank">{{ link.title }}</a>
            </ng-container>
            <ng-container *ngIf="link.type === '_self'">
              <a class="link" [routerLink]="link.link">{{ link.title }}</a>
            </ng-container>
          </li>
        </ul>
      </div>
      <div class="col-sm-12 col-md-12 col-lg-auto order-lg-3 ">
        <div class="footer-developed">
          <a class="link" href="https://dotlab.net/solutions/" target="_blank">
            {{ 'footer.developed' | translate }}
          </a>
        </div>
      </div>
    </div>
  </div>
</div>
