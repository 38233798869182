import { Component, Input, OnChanges } from '@angular/core';
import { InsuranceProduct } from '@modules/products/interfaces/insurance-product.interface';
import { Observable } from 'rxjs';

import {
  LastViewedProductService,
  productTypeEnum,
} from './last-viewed-product.service';

@Component({
  selector: 'app-last-viewed-product',
  templateUrl: './last-viewed-product.component.html',
  styleUrls: ['./last-viewed-product.component.scss'],
})
export class LastViewedProductComponent implements OnChanges {
  @Input() isMutation: boolean;
  items$: Observable<InsuranceProduct[]>;
  openedProduct: InsuranceProduct | null;
  constructor(private service: LastViewedProductService) {}

  ngOnChanges() {
    if (typeof this.isMutation === 'boolean') {
      const serviceKey = this.isMutation
        ? productTypeEnum.MUTATIONS
        : productTypeEnum.PRODUCTS;
      this.items$ = this.service[serviceKey].list$;
    }
  }

  toggleOpenedProduct(product: InsuranceProduct) {
    if (!this.openedProduct || this.openedProduct.id !== product.id) {
      this.openedProduct = product;
    } else {
      this.openedProduct = null;
    }
  }
}
