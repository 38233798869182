import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { map } from 'rxjs/operators';

import { IShortcutsResponse } from './shortcuts.interface';
import { shortcutsQuery } from './shortcuts.query';

@Injectable()
export class ShortcutsService {
  constructor(private apollo: Apollo) {}

  getData() {
    return this.apollo
      .watchQuery<IShortcutsResponse>({
        query: shortcutsQuery,
        variables: {
          includeTopItem: false,
          size: 3,
        },
      })
      .valueChanges.pipe(map(response => response.data));
  }
}
