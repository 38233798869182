import { HttpBackend, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';

import { IConfig } from './app.config.interface';

@Injectable({
  providedIn: 'root',
})
export class AppConfigService {
  public hostName: string;
  private http: HttpClient;
  apiKey: string;
  gtmId: string;

  constructor(
    private httpBackEnd: HttpBackend,
    private httpClient: HttpClient,
  ) {
    this.http = new HttpClient(httpBackEnd);
    this.getHostname();
  }

  public fetchConfigData() {
    return this.http.get<{ data: IConfig }>(
      `${environment.configServiceAPI}/customers/${this.hostName}`,
    );
  }

  public fetchGoogleConfigData() {
    return this.http
      .get<any>(
        `${environment.configServiceAPI}/customers/${this.hostName}/bootstrap`,
      )
      .toPromise()
      .then(data => {
        this.apiKey = data.data.googleApiKey;
        if (data.data.analytics) {
          this.gtmId = data.data.analytics[0].parameters.id;
        }
        if (this.hostName === 'localhost') {
          this.gtmId = 'GTM-WSS8B8H';
        }
      });
  }

  /**
   * get host name from url
   * return String of hostname
   * */
  getHostname() {
    this.hostName = window.location.hostname;
  }
}
