import { Action } from '@ngrx/store';

export class ActionTypes {
  static readonly SWITCH_EDIT_MODE = 'SWITCH_EDIT_MODE';
}

export type Type = SwitchEditMode;

export class SwitchEditMode implements Action {
  readonly type = ActionTypes.SWITCH_EDIT_MODE;
  constructor(public payload: any) {}
}
