import { Injectable } from '@angular/core';
import { forEach } from 'lodash-es';

@Injectable()
export class CssVariablesService {
  setGlobalCssVariables(cssVariables: any) {
    cssVariables.forEach(item => {
      document.body.style.setProperty(`--${item.name}`, item.value);
    });
  }
}
