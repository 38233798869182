import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CompaniesListComponent } from '@modules/products/components/companies-list/companies-list.component';
import { CompanyCardComponent } from '@modules/products/components/company-card/company-card.component';
import { ProductsFiltersComponent } from '@modules/products/components/products-filters/products-filters.component';
import { ProductsFiltersService } from '@modules/products/components/products-filters/products-filters.service';
import { ProductsListItemComponent } from '@modules/products/components/products-list-item/products-list-item.component';
import { ProductsListComponent } from '@modules/products/components/products-list/products-list.component';
import { CompanyPage } from '@modules/products/pages/company/company.page';
import { ProductPage } from '@modules/products/pages/product/product.page';
import { ProductsRoutingModule } from '@modules/products/products-routing.module';
import { ProductsService } from '@modules/products/products.service';
import { NgSelectModule } from '@ng-select/ng-select';
import { SharedModule } from '@shared/shared.module';

import { IncorrectItemComponent } from './components/incorrect-item/incorrect-item.component';
import { LastViewedProductComponent } from './components/last-viewed-product/last-viewed-product.component';
import { LastViewedProductService } from './components/last-viewed-product/last-viewed-product.service';
import { ProductItemExpandedComponent } from './components/product-item-expanded/product-item-expanded.component';
import { ProductsDashboardPage } from './pages/products-dashboard/products-dashboard.page';

@NgModule({
  declarations: [
    ProductsDashboardPage,
    ProductsListComponent,
    ProductsListItemComponent,
    CompaniesListComponent,
    CompanyCardComponent,
    ProductPage,
    CompanyPage,
    ProductsFiltersComponent,
    IncorrectItemComponent,
    LastViewedProductComponent,
    ProductItemExpandedComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    NgSelectModule,
    FormsModule,
    ProductsRoutingModule,
  ],
  providers: [
    ProductsService,
    ProductsFiltersService,
    LastViewedProductService,
  ],
})
export class ProductsModule {}
