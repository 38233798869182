<div class="news-widget" id="step5">
    <div *ngIf="data && data?.featuredItem" class="news-widget__item highlighted">
        <a
            class="news-widget__title"
            target="_blank"
            [href]="data?.featuredItem?.link_url"
        >{{data?.featuredItem?.title}}
        <i class="nd-chevron-right-small"></i></a>
        <div
            class="news-widget__text-block"
            [innerHTML]="data?.featuredItem?.text">
        </div>
        <span class="news-widget__date"></span>
    </div>
    <ng-container *ngFor="let item of data?.news?.items">
        <div class="news-widget__item">
            <a
                class="news-widget__title"
                [routerLink]="['/news', item.slug]"
                [title]="item.title"
            >
            {{ item.title }}
            <i class="nd-chevron-right-small"></i>
            </a>
        </div>
    </ng-container>
    <a [routerLink]="['/news']" class="news-widget__read-more">
        {{ 'shortcuts.all_news' | translate }}
        <i class="nd-chevron-right-small"></i>
    </a>
</div>
