// tslint:disable-next-line:import-name
import gql from 'graphql-tag';

import { IPackagesQueryParams } from '../interfaces/dto.interface';

export const companiesQuery = (query: any) => {
  const queryParamsDef = [];
  const queryParamsCompanies = [];
  const formatQuery = (params: string[]) => `(${params.join(',')})`;

  const addToQueryObject = (param: string, type: string) => {
    queryParamsDef.push(`$${param}: ${type}`);
    queryParamsCompanies.push(`${param}: $${param}`);
  };

  if (query.companiesFrom) {
    queryParamsDef.push('$companiesFrom: Int');
    queryParamsCompanies.push('from: $companiesFrom');
  }

  if (query.companiesSize) {
    queryParamsDef.push('$companiesSize: Int');
    queryParamsCompanies.push('size: $companiesSize');
  }

  queryParamsCompanies.push('niet_volmacht: true');

  // tslint:disable:no-unused-expression
  query.type && query.type.length && addToQueryObject('type', '[ProductType!]');
  query.kind && query.kind.length && addToQueryObject('kind', '[ProductKind!]');
  query.categoryId && addToQueryObject('categoryId', 'ID');
  query.subcategoriesIds &&
    query.subcategoriesIds.length &&
    addToQueryObject('subcategoriesIds', '[ID!]');
  query.ids && query.ids.length && addToQueryObject('ids', '[Int!]');
  query.search && addToQueryObject('search', 'String');
  query.searchBy && addToQueryObject('searchBy', 'ProductSearchByType');

  return gql`
    query companies ${
      queryParamsDef.length ? formatQuery(queryParamsDef) : ''
    } {
      companies ${
        queryParamsCompanies.length ? formatQuery(queryParamsCompanies) : ''
      } {
        totalCount
        items {
          id
          logo
          slug
          title
          page
          website
          }
        }
      }
  `;
};
