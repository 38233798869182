import { Component, OnInit } from '@angular/core';
import { IContactData } from '@shared/components/contact-widget/contact-widget.interface';

import { ContactWidgetService } from './contact-widget.service';

@Component({
  selector: 'app-contact-widget',
  templateUrl: './contact-widget.component.html',
  styleUrls: ['./contact-widget.component.scss'],
})
export class ContactWidgetComponent implements OnInit {
  constructor(private contactWidgetService: ContactWidgetService) {}
  public contactData: IContactData;

  ngOnInit() {
    this.contactWidgetService.getContactData().subscribe(data => {
      this.contactData = data;
    });
  }
}
