<ngb-toast
  *ngFor="let toast of toastService.toasts"
  class ="custom-toast"
  [header]="toast.headertext"
  [class]="toast.classname"
  [autohide]="toast.autohide"
  [delay]="toast.delay || 5000"
  (hide)="toastService.remove(toast)"
>
  <span [ngSwitch]="toast.classname">
    <fa-icon *ngSwitchCase="'info-toast'" class="info-icon" icon="info"></fa-icon>
    <fa-icon *ngSwitchCase="'success-toast'" class="check-icon" icon="check"></fa-icon>
    <fa-icon *ngSwitchCase="'warning-toast'" class="exclamation-triangle-icon" icon="exclamation-triangle"></fa-icon>
    <fa-icon *ngSwitchDefault class="times-icon" icon="times"></fa-icon>
  </span>

  <ng-template [ngIf]="isTemplate(toast)" [ngIfElse]="text">
    <ng-template [ngTemplateOutlet]="toast.textOrTpl"></ng-template>
  </ng-template>

  <ng-template #text>{{ toast.textOrTpl }}</ng-template>
</ngb-toast>
