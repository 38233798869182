// tslint:disable-next-line:import-name
import gql from 'graphql-tag';

import { INewsQueryParams } from './news.interface';

export const newsQuery = (query: INewsQueryParams) => {
  const queryParamsDef = [];
  const queryParams = [];

  const formatQuery = (params: string[]) => `(${params.join(',')})`;

  const addToQueryObject = (param: string, type: string) => {
    queryParamsDef.push(`$${param}: ${type}`);
    queryParams.push(`${param}: $${param}`);
  };

  // tslint:disable:no-unused-expression
  query.category && addToQueryObject('category', 'String');
  query.search && addToQueryObject('search', 'String');
  query.from && addToQueryObject('from', 'Int');
  query.size && addToQueryObject('size', 'Int');

  return gql`
    query news ${queryParamsDef.length ? formatQuery(queryParamsDef) : ''} {
      news ${queryParams.length ? formatQuery(queryParams) : ''} {
        items {
          id
          slug
          title
          short_content
          image
          created
          category
        }
        totalCount
      }
      topNewsItem {
        id
        slug
        title
        short_content
        image
        created
        category
      }
    }
  `;
};

export const newsItemQuery = gql`
  query newsItem($slug: String!) {
    newsItem(slug: $slug) {
      id
      slug
      title
      flexible_content
      image
      created
      category
      useful_links {
        link_url
        type
        title
      }
      tooltiles
    }
    toolTiles {
      id
      title
      image
      tooltile_type
      url
      link_type
      start_date
      active
      category
    }
  }
`;
