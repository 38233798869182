// tslint:disable-next-line:import-name
import gql from 'graphql-tag';

export const shortcutsQuery = gql`
  query shortcuts($includeTopItem: Boolean, $size: Int) {
    featuredItem {
      title
      text
      link_text
      link_url
    }
    news(includeTopItem: $includeTopItem, size: $size) {
      items {
        id
        slug
        title
        created
      }
    }
  }
`;
