import { environment } from '@env/environment';
import { KeycloakOptions } from 'keycloak-angular';
import urlJoin from 'url-join';

const ONLOAD_POLICY = 'check-sso';
const AUTHORIZATION_HEADER = 'authorization';
const BEARER_PREFIX = 'Bearer';
const BEARER_EXCLUDE_URLS = ['/assets', '/notification/unsubscribe/'];

export const keycloakConfig = {
  config: {
    url: urlJoin([environment.keycloak.url, '/auth']),
    realm: environment.keycloak.realm,
    clientId: environment.keycloak.clientId,
  },
  initOptions: {
    onLoad: ONLOAD_POLICY,
    checkLoginIframe: false,
  },
  authorizationHeaderName: AUTHORIZATION_HEADER,
  bearerPrefix: BEARER_PREFIX,
  enableBearerInterceptor: true,
  bearerExcludedUrls: BEARER_EXCLUDE_URLS,
} as KeycloakOptions | any;
