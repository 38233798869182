<div class="table-responsive">
  <table class="table table-body-striped table-borderless mb-0">
    <thead>
      <tr>
        <th scope="col" class="pl-3 align-middle">
          {{ 'products_list.table_headers.company_name' | translate }}
        </th>

        <th scope="col" class="align-middle" *ngIf="!isMutation">
          {{ 'products_list.table_headers.product' | translate }}
        </th>

        <th scope="col" class="align-middle" *ngIf="!isMutation">
          {{ 'products_list.table_headers.type' | translate }}
        </th>

        <th scope="col" class="align-middle" style="width: 160px"></th>

        <th
          scope="col"
          class="pr-3 align-middle text-right"
          style="width: 140px"
          *ngIf="!isMutation"
        ></th>
      </tr>
    </thead>

    <tbody
      *ngFor="let product of products"
      app-products-list-item
      [storeViewind]="true"
      [isMutation]="isMutation"
      [product]="product"
      [fullPageSize]="fullPageSize"
    ></tbody>
  </table>
</div>
