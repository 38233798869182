<div class="row pt-3" *ngIf="(alerts$ | async).length">
  <div class="col pb-2 pt-2">
    <ng-container *ngFor="let alert of alerts$ | async">
      <alert
        [type]="alert.type"
        [dismissible]="true"
        (onClosed)="onClosed(alert, $event)"
      >
        <p class="alert-heading">{{ alert.title }}</p>
        <div [innerHTML]="alert.content"></div>
      </alert>
    </ng-container>
  </div>
</div>
