// tslint:disable-next-line:import-name
import gql from 'graphql-tag';

export const feedbackMutation = gql`
  mutation wrongInfo(
    $userName: String!
    $companyTitle: String!
    $productTitle: String
    $type: String
    $message: String!
    $url: String!
  ) {
    wrongInfo(
      userName: $userName
      companyTitle: $companyTitle
      productTitle: $productTitle
      type: $type
      message: $message
      url: $url
    ) {
      success
    }
  }
`;
