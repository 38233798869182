<div class="container-fluid p-5">
  <div class="row" *ngIf="header">
    <div class="col-12">
      <app-company-card
        class="d-block w-100 mb-4"
        [tag]="'products_page.filters.type.' + product.type | translate"
        [pageType]="'products_list.buttons.proxy_redirect' | translate"
        [company]="header"
        [asHeader]="true"
        [isMutation]="isMutation"
      ></app-company-card>
    </div>
  </div>
  <div class="row mb-4">
    <div class="col-12">
      <app-article
        *ngIf="product && product.contentPage"
        [standalone]="true"
        [content]="product.contentPage.flexible_content"
      ></app-article>
    </div>
  </div>
</div>
