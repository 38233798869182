import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { categoryName } from '@shared/interfaces/categories.interface';

@Component({
  selector: 'app-collapsible',
  templateUrl: './collapsible.component.html',
  styleUrls: ['./collapsible.component.scss'],
})
export class CollapsibleComponent implements OnChanges {
  @Input() title: string;
  @Input() category: categoryName | null;
  @Input() color: 'white' | 'light';
  @Input() multilineHeader: boolean;
  isCollapsed = true;

  ngOnChanges() {
    if (typeof this.multilineHeader !== 'boolean') {
      this.multilineHeader = true;
    }
  }
}
