import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'username',
})
export class UsernamePipe implements PipeTransform {
  transform(username: string | undefined, placeholder?: string): any {
    // tslint:disable-next-line:no-parameter-reassignment
    username = username.trim();
    if (username && username.length > 1 && !username.includes('.')) {
      return username;
      // tslint:disable-next-line:no-else-after-return
    } else {
      return placeholder || '';
    }
  }
}
