import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { AlertsEffects } from '@shared/components/alerts/store/alerts-effects';

import { Effects as CoreEffects } from '../core/store/core-effects';

@NgModule({
  imports: [EffectsModule.forRoot([CoreEffects, AlertsEffects])],
})
export class AppEffectsModule {}
