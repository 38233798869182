import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { IToolTileRedirectUrl } from '@shared/interfaces/tool-tiles.interface';
import { Apollo } from 'apollo-angular';
import { Observable, Subject } from 'rxjs';
import { filter, map, pluck, tap } from 'rxjs/operators';

import { IConfig } from '../../config/app.config.interface';
import { State } from '../../store/app-reducers';

import {
  IDashboardCategoryWithTiles,
  IDashboardData,
  IDashboardResponse,
} from './dashboard.interface';
import {
  bulkUpdateUserToolTile,
  dashboardQuery,
  markAlertAsSeen,
  setWizardCompletion,
  toolTilePreprocessRedirect,
} from './dashboard.query';

@Injectable()
export class DashboardService {
  public clientCode: string;

  private _data = new Subject<IDashboardData>();
  get data$(): Observable<IDashboardData> {
    return this._data.asObservable();
  }

  constructor(private apollo: Apollo, private store: Store<State>) {}

  /***
   *  Get clientCode from config data "code" from store (value is uppercase)
   *  return: true/false
   */

  // get isBSB(): boolean {
  //   this.store
  //     .select('core')
  //     .pipe(
  //       pluck('config'),
  //       filter(data => data !== null),
  //     )
  //     .subscribe((data: IConfig) => {
  //       this.clientCode = data.code;
  //     });
  //   return this.clientCode === 'BSB';
  // }

  getDashboardData() {
    this.apollo
      .watchQuery<IDashboardResponse>({
        query: dashboardQuery,
        fetchPolicy: 'network-only',
      })
      .valueChanges.pipe(
        map(response => response.data),
        map(data => {
          const newData = {} as IDashboardData;
          const tiles = data.toolTiles.map(tile => {
            const toolTileId = +tile.id;
            // Step 1: if toolTileActions value for type === 'preprocess_awi_login'

            const bsbTiles =
              tile.toolTileActions &&
              tile.toolTileActions.map(item => {
                const tileValues = JSON.parse(item.values.toString());
                const isPreprocessAwiLogin =
                  tileValues.type === 'preprocess_awi_login';

                // Step 2: if isPreprocessAwiLogin = true --> send toolTileId, toolTileActionId for updating URL
                if (isPreprocessAwiLogin) {
                  const toolTileActionId = +item.id;
                  return this.apollo
                    .watchQuery<IToolTileRedirectUrl>({
                      query: toolTilePreprocessRedirect,
                      errorPolicy: 'all',
                      variables: {
                        toolTileId,
                        toolTileActionId,
                      },
                    })
                    .valueChanges.pipe(map(response => response.data))
                    .subscribe(res => {
                      if (res.toolTilePreprocessRedirect.url) {
                        tile.url = res.toolTilePreprocessRedirect.url;
                      }
                    });
                }
              });

            return Object.assign({}, tile, {
              order: (
                data.userTooltiles.find(
                  i => `${i.tooltileId}` === tile.id && !i.hidden,
                ) || {
                  order: undefined,
                }
              ).order,
            });
          });
          newData.dashboardCategories = data.dashboardCategories.map(
            category => {
              const categoryWithTiles = Object.assign({}, category) as any;
              categoryWithTiles.tiles = tiles.filter(tile =>
                tile.category.includes(+category.id),
              );
              return categoryWithTiles;
            },
          );
          newData.usefulLinks = data.usefulLinks.map(link => ({
            name: link.link_text,
            external: link.type === '_blank',
            url: `${link.type === '_blank' ? '' : '/support/'}${link.link_url}`,
          }));
          newData.userProfile = data.userProfile;
          newData.alerts = data.alerts;
          return newData;
        }),
      )
      .subscribe(res => {
        this._data.next(res);
      });
  }

  reorderElements(
    category: IDashboardCategoryWithTiles,
    orderedVisibleTooltileIds: string[],
    orderedHiddenTooltileIds: string[],
  ) {
    return this.apollo
      .mutate({
        mutation: bulkUpdateUserToolTile,
        variables: {
          orderedVisibleTooltileIds,
          orderedHiddenTooltileIds,
          categoryId: category.id,
        },
      })
      .pipe(
        tap(() => {
          this.getDashboardData();
        }),
      );
  }
  completeWizard() {
    this.apollo
      .mutate({
        mutation: setWizardCompletion,
      })
      .subscribe(res => {
        this.getDashboardData();
      });
  }

  markAlertAsSeen(alert) {
    this.apollo
      .mutate({
        mutation: markAlertAsSeen,
        variables: {
          alert,
        },
      })
      .subscribe(res => {});
  }
}
