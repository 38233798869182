<nav class="navbar navbar-expand-lg navbar-light fixed-top"
     [ngClass]="{
     'animate': (navbar.smallScreen && !navbar.collapsed) || !navbar.smallScreen,
     'nav-collapsed': isCollapsedNav,
     'top-alert-position': isExceptionalAlert}"
>
  <div class="container-fluid">
    <ng-container *ngIf="auth$ | async as auth">
      <div class="navbar-title">
        <a class="navbar-brand" [routerLink]="['/dashboard']">
          <img class="navbar-logo" [src]="imagePath + 'content/images/logo.png'" alt="Logo" />
        </a>
      </div>
      <button class="navbar-toggler"
      data-toggle="collapse"
      data-target="#navbarResponsive"
      aria-controls="navbarResponsive"
      [attr.aria-expanded]="!navbar.collapsed"
      aria-expanded="false"
      type="button"
      (click)="navbar.collapsed = !navbar.collapsed"
      id ="navButton"
    >
    <div class="animated-icon"
        [ngClass]="{'open': (navbar.smallScreen && !navbar.collapsed) || !navbar.smallScreen}">
      <span class="animated-icon-item"></span>
      <span class="animated-icon-item"></span>
      <span class="animated-icon-item"></span>
      <span class="animated-icon-item"></span>
    </div>
    </button>
      <div
        *ngIf="(navbar.smallScreen && !navbar.collapsed) || !navbar.smallScreen"
        class="collapse navbar-collapse mobile"
        id="navbarResponsive"
        [ngClass]="{
          show:
            (navbar.smallScreen && !navbar.collapsed) || !navbar.smallScreen,
          in: (navbar.smallScreen && !navbar.collapsed) || !navbar.smallScreen
        }"
      >
        <ul class="navbar-nav ml-auto">
          <li class="nav-item dropdown dropdown-user" dropdown id="step8">
            <a
              href="javascript:void(0)"
              class="nav-link dropdown-toggle"
              data-testid="username"
              dropdownToggle
            >
              <i class="nd-account"></i>
              {{ auth.firstName | username: 'header.profile' | translate }}
            </a>
            <div
              class="dropdown-menu dropdown-menu-right dropdown-menu-user"
              *dropdownMenu
            >
              <ul class="list-group list-group-flush mb-4">
                <li class="p-0 list-group-item"
                *ngIf="modulePermission('notifications')"
                (click)="navbar.collapsed = !navbar.collapsed;">
                  <a [routerLink]="['/notifications']">
                    <i class="notification-icon"></i>
                    Notificatievoorkeuren
                  </a>
                </li>
                <li class="p-0 list-group-item"
                    *ngIf="userProfile?.changePasswordUrl"
                    (click)="navbar.collapsed = !navbar.collapsed;">
                  <a (click)="redirectToUrl(userProfile.changePasswordUrl);">
                    <i class="mr-1 nd-key-outline"></i>
                    {{ 'header.change_password' | translate }}
                  </a>
                </li>
                <li class="p-0 list-group-item">
                  <a href="javascript:void(0)" (click)="logout()">
                    <i class="mr-1 nd-logout"></i>
                    {{ 'header.logout' | translate }}
                  </a>
                </li>
              </ul>
              <ng-container *ngIf="manageProfileUrl"
                            (click)="navbar.collapsed = !navbar.collapsed;">
                <a
                  class="btn btn-outline-primary btn-block"
                  [href]="manageProfileUrl"
                  target="_blank"
                >
                  {{ 'header.update_profile' | translate }}
                </a>
              </ng-container>
            </div>
          </li>
          <li class="nav-item dropdown dropdown-links" *ngIf="!navbar.smallScreen" dropdown id="step7">
            <a
              href="javascript:void(0)"
              class="nav-link dropdown-toggle"
              dropdownToggle
            >
              <i class="nd-menu"></i>
              <span class="d-inline-block d-md-none">{{
                'header.menu' | translate
              }}</span>
            </a>
            <div
              class="dropdown-menu dropdown-menu-links dropdown-menu-right"
              *dropdownMenu
            >
              <p class="navbar-caption d-none d-md-block">Menu</p>
              <ul class="list-group list-group-flush mb-3">
                <li
                  class="p-0 list-group-item"
                  *ngFor="let category of data.dashboardCategories"
                >
                  <a
                    *ngIf="isTilesByCategory(category.id)"
                    [routerLink]="['/dashboard']"
                    [queryParams]="{
                      category: categoriesDict[category.id]
                    }"
                    (click)="navbar.collapsed = !navbar.collapsed"
                  >
                    {{ category.name }}
                    <i class="nd-chevron-right-small"></i>
                  </a>

                  <div class="dropdown-menu-submenu card">
                    <ul class="list-group list-group-flush">
                      <li
                        class="p-0 list-group-item"
                        *ngFor="let tile of getTilesByCategory(category.id)"
                      >
                        <ng-container
                          *ngIf="{
                            silentExternal:
                              tile.link_type === '_self' &&
                              checkHttpInUrl(tile.url)
                          } as scopedData"
                        >
                          <ng-container
                            *ngIf="
                              tile.link_type === '_self' &&
                              !scopedData.silentExternal
                            "
                          >
                            <a [routerLink]="['/support', tile.url]">
                              {{ tile.title }}
                              <i class="nd-chevron-right-small"></i>
                            </a>
                          </ng-container>
                          <ng-container
                            *ngIf="
                              tile.link_type === '_blank' ||
                              scopedData.silentExternal
                            "
                          >
                            <a
                              [href]="tile.url"
                              [target]="
                                scopedData.silentExternal ? '_self' : '_blank'
                              "
                            >
                              {{ tile.title }}
                              <i
                                [ngClass]="{
                                  'nd-chevron-right-small':
                                    scopedData.silentExternal,
                                  'nd-open-in-new': !scopedData.silentExternal
                                }"
                              ></i>
                            </a>
                          </ng-container>
                        </ng-container>
                      </li>
                    </ul>
                  </div>
                </li>
                <li class="p-0 list-group-item" *ngIf="data.usefulLinks">
                  <a href="javascript:void(0)"
                     (click)="navbar.collapsed = !navbar.collapsed">
                    Ondersteuning
                    <i class="nd-chevron-right-small"></i>
                  </a>
                  <div class="dropdown-menu-submenu card">
                    <ul class="list-group list-group-flush">
                      <li
                        class="p-0 list-group-item"
                        *ngFor="let link of data.usefulLinks"
                      >
                        <ng-container *ngIf="link.type === '_self'">
                          <a [routerLink]="[link.link_url]"
                             (click)="navbar.collapsed = !navbar.collapsed">
                            {{ link.link_text }}
                            <i class="nd-chevron-right-small"></i>
                          </a>
                        </ng-container>
                        <ng-container *ngIf="link.type === '_blank'">
                          <a [href]="link.link_url" target="_blank">
                            {{ link.link_text }}
                            <i class="nd-open-in-new"></i>
                          </a>
                        </ng-container>
                      </li>
                    </ul>
                  </div>
                </li>
              </ul>
              <p class="navbar-subtitle">
                {{ 'header.have_questions' | translate }}
              </p>
              <a
                class="btn btn-outline-primary btn-block"
                [routerLink]="['/contact']"
                (click)="navbar.collapsed = !navbar.collapsed"
              >
                {{ 'header.contact' | translate }}
              </a>
              <a
                *ngIf="isWizardEnabled"
                class="btn btn-outline-primary btn-block wizard-btn"
                (click)="initTour()"
              >
                {{ 'header.onboarding' | translate }}
              </a>
            </div>
          </li>

          <li class="nav-item-sidebar" *ngIf="navbar.smallScreen" (click)="navbar.collapsed = !navbar.collapsed">
            <app-nav></app-nav>
          </li>
        </ul>
      </div>
    </ng-container>
  </div>
</nav>
