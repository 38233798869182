import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { InsuranceCompany } from '@modules/products/interfaces/insurance-company.interface';
import { ProductsService } from '@modules/products/products.service';
import { pluck, switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-company',
  templateUrl: './company.page.html',
  styleUrls: ['./company.page.scss'],
})
export class CompanyPage {
  isMutation = false;
  company: InsuranceCompany;
  constructor(private service: ProductsService, private route: ActivatedRoute) {
    this.isMutation = this.route.snapshot.data.isMutation;
    this.route.params
      .pipe(
        switchMap(params => this.service.getCompany(params.slug)),
        pluck('company'),
      )
      .subscribe((company: InsuranceCompany) => {
        company.contact = company.contact.filter(
          c => c.address || c.phone || c.email,
        );

        if (company.general_contacts) {
          company.general_contacts = company.general_contacts.filter(
            c => c.address || c.phone || c.email,
          );
        }

        this.company = company;
      });
  }

  hasProduct(type) {
    return this.company.products.items.some(e => e.type === type);
  }
}
