import { Action } from '@ngrx/store';
import { IAppAlert } from '@shared/components/alerts/alerts.interface';

export class ActionTypes {
  static readonly GET_ALERTS = 'GET_ALERTS';
  static readonly GET_ALERTS_SUCCESS = 'GET_ALERTS_SUCCESS';
  static readonly GET_ALERTS_FAIL = 'GET_ALERTS_FAIL';
}

export type Type = GetAlerts | GetAlertsSuccess | GetAlertsFail;

export class GetAlerts implements Action {
  readonly type = ActionTypes.GET_ALERTS;
  constructor() {}
}

export class GetAlertsSuccess implements Action {
  readonly type = ActionTypes.GET_ALERTS_SUCCESS;
  constructor(public payload: IAppAlert) {}
}

export class GetAlertsFail implements Action {
  readonly type = ActionTypes.GET_ALERTS_FAIL;
  constructor(public payload: any) {}
}
