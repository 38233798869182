import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sortAlphabet',
})
export class SortAlphabetPipe implements PipeTransform {
  transform(items: any, prop: any): any {
    return items.sort((a, b) => {
      const s1 = a[prop].toLowerCase();
      const s2 = b[prop].toLowerCase();

      if (s1 < s2) {
        return -1;
      }
      if (s1 > s2) {
        return 1;
      }
      return 0;
    });
  }
}
