import { Action } from '@ngrx/store';
import { IConfig } from 'src/app/config/app.config.interface';

export class ActionTypes {
  static readonly LOAD_CONFIG = 'LOAD_CONFIG';
  static readonly LOAD_CONFIG_SUCCESS = 'LOAD_CONFIG_SUCCESS';
  static readonly LOAD_CONFIG_FAIL = 'LOAD_CONFIG_FAIL';
  static readonly COLLAPSED_NAV = 'COLLAPSED_NAV';
}

export type Type =
  | LoadConfig
  | LoadConfigSuccess
  | LoadConfigFail
  | CollapsedNav;

export class LoadConfig implements Action {
  readonly type = ActionTypes.LOAD_CONFIG;
  constructor() {}
}

export class LoadConfigSuccess implements Action {
  readonly type = ActionTypes.LOAD_CONFIG_SUCCESS;
  constructor(public payload: IConfig) {}
}

export class LoadConfigFail implements Action {
  readonly type = ActionTypes.LOAD_CONFIG_FAIL;
  constructor(public payload: any) {}
}

export class CollapsedNav implements Action {
  readonly type = ActionTypes.COLLAPSED_NAV;
  constructor(public payload: any) {}
}
