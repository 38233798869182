import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { ExceptionalAlertComponent } from '@shared/components/alerts/exceptional-alert/exceptional-alert.component';
import { ToastComponent } from '@shared/components/toast/toast.component';
import { SortAlphabetPipe } from '@shared/pipes/sort-alphabet.pipe';
import { AlertModule } from 'ngx-bootstrap/alert';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ModalModule } from 'ngx-bootstrap/modal';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { TooltipModule } from 'ngx-bootstrap/tooltip';

import { AlertsComponent } from './components/alerts/alerts.component';
import { AlertsService } from './components/alerts/alerts.service';
import { AppliedFiltersComponent } from './components/applied-filters/applied-filters.component';
import { ArticleComponent } from './components/article/article.component';
import { CheckboxSelectComponent } from './components/checkbox-select/checkbox-select.component';
import { CheckboxComponent } from './components/checkbox/checkbox.component';
import { CheckboxesListComponent } from './components/checkboxes-list/checkboxes-list.component';
import { CollapsibleComponent } from './components/collapsible/collapsible.component';
import { ContactWidgetComponent } from './components/contact-widget/contact-widget.component';
import { ContactWidgetService } from './components/contact-widget/contact-widget.service';
import { InputRadioListComponent } from './components/input-radio-list/input-radio-list.component';
import { LinksListComponent } from './components/links-list/links-list.component';
import { LinsWidgetComponent } from './components/links-widget/links-widget.component';
import { NewsWidgetComponent } from './components/news-widget/news-widget.component';
import { NewsWidgetService } from './components/news-widget/news-widget.service';
import { ShortcutsComponent } from './components/shortcuts/shortcuts.component';
import { ShortcutsService } from './components/shortcuts/shortcuts.service';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { ToolCardComponent } from './components/tool-card/tool-card.component';
import { TableSortingHeaderDirective } from './directives/table-sorting-header.directive';
import { KeycloakWrapperService } from './keycloak/keycloak-wrapper.service';
import { HtmlStringPipe } from './pipes/html-string.pipe';
import { ToolColorPipe } from './pipes/tool-color.pipe';
import { UsernamePipe } from './pipes/username.pipe';
import { CategoriesService } from './services/categories/categories.service';
import { MediaBreakpointService } from './services/media-breakpoint/media-breakpoint.service';
import { OnboardingService } from './services/onboarding/onboarding.service';
import { ScrollService } from './services/scroll/scroll.service';

const transitModules = [
  BsDropdownModule,
  AlertModule,
  CollapseModule,
  ModalModule,
  PaginationModule,
  TooltipModule,
  FontAwesomeModule,
];

const components = [
  ArticleComponent,
  CollapsibleComponent,
  LinksListComponent,
  ToolCardComponent,
  CheckboxSelectComponent,
  AppliedFiltersComponent,
  CheckboxComponent,
  CheckboxesListComponent,
  AlertsComponent,
  ShortcutsComponent,
  SidebarComponent,
  NewsWidgetComponent,
  ContactWidgetComponent,
  LinsWidgetComponent,
  ExceptionalAlertComponent,
  InputRadioListComponent,
  ToastComponent,
  TableSortingHeaderDirective,
];

@NgModule({
  declarations: [
    ...components,
    ToolColorPipe,
    UsernamePipe,
    HtmlStringPipe,
    SortAlphabetPipe,
    ToastComponent,
  ],
  imports: [
    CommonModule,
    TranslateModule,
    RouterModule,
    FormsModule,
    NgbModule,
    BsDatepickerModule.forRoot(),
    ...transitModules,
  ],
  providers: [
    CategoriesService,
    MediaBreakpointService,
    ScrollService,
    KeycloakWrapperService,
    OnboardingService,
    HtmlStringPipe,
    AlertsService,
    ShortcutsService,
    NewsWidgetService,
    ContactWidgetService,
  ],
  exports: [
    RouterModule,
    BsDatepickerModule,
    FormsModule,
    TranslateModule,
    ...transitModules,
    ...components,
    ToolColorPipe,
    UsernamePipe,
    HtmlStringPipe,
    SortAlphabetPipe,
  ],
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [
        CategoriesService,
        MediaBreakpointService,
        ScrollService,
        AlertsService,
        ShortcutsService,
        KeycloakWrapperService,
        OnboardingService,
        NewsWidgetService,
        ContactWidgetService,
      ],
    };
  }
}
