import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { map } from 'rxjs/operators';

import { IFooterLinks } from './footer.interface';
import { customerQuery, footerQuery } from './footer.query';

@Injectable()
export class FooterService {
  constructor(private apollo: Apollo) {}

  getLinks() {
    return this.apollo
      .watchQuery<{ footerLinks: IFooterLinks }>({
        query: footerQuery,
      })
      .valueChanges.pipe(
        map(response => response.data && response.data.footerLinks),
      );
  }

  getTitle() {
    return this.apollo
      .watchQuery<any>({
        query: customerQuery,
      })
      .valueChanges.pipe(
        map(response => {
          return response.data.customer.title;
        }),
      );
  }
}
