import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DashboardPage } from '@modules/dashboard/pages/dashboard/dashboard.page';
import { KeycloakWrapperAuthGuard } from '@shared/keycloak/keycloak-auth.guard';

const routes: Routes = [
  {
    path: '',
    component: DashboardPage,
    canActivate: [KeycloakWrapperAuthGuard],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class DashboardRoutingModule {}
