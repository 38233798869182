<div class="links-widget">
  <h2 class="links-widget__title">{{ 'news_item_page.sidebar.interesting_links' | translate }}</h2>
  <ng-container *ngFor="let item of data">
    <ng-container *ngIf="item.external && item.url">
      <a
        class="links-widget__item"
        [title]="item.name"
        [href]="item.url"
        target="_blank"
      >
        {{ item.translate ? (item.name | translate) : item.name }}
        <i class="nd-chevron-right-small"></i>
      </a>
    </ng-container>
    <ng-container *ngIf="!item.external && item.url">
      <a
        class="links-widget__item"
        [title]="item.name"
        [routerLink]="item.url"
      >
        {{ item.translate ? (item.name | translate) : item.name }}
        <i class="nd-chevron-right-small"></i>
      </a>
    </ng-container>
  </ng-container>
</div>
