<div class="container">
  <app-alerts></app-alerts>
</div>

<div class="controls">
  <div class="controls__search">
    <input
      #inputSearch
      [ngModel]="state.search"
      type="text"
      class="controls__search-input"
      placeholder="{{ 'faq_page.search.label' | translate }}"
      (keyup)="$event.keyCode === 13 && setSearchQuery(inputSearch.value)"
      (blur)="setSearchQuery(inputSearch.value)"
    />
    <button
      [disabled]="inputSearch.value.length < 3"
      class="controls__search-btn"
      (click)="setSearchQuery(inputSearch.value)"
    >
      <i class="nd-magnify"></i>
    </button>
    <button
      *ngIf="inputSearch.value.length > 0"
      class="controls__search-reset"
      (click)="removeSearch()"
    >
    <i class="nd-close"></i>
  </button>
  </div>
  <div class="controls__label">
    <i class="nd-filter"></i>
    Filters
  </div>
  <ng-select
    class="filter"
    [items]="categories"
    [dropdownPosition]="'down'"
    [searchable]="false"
    placeholder="Alle vragen"
    bindLabel="name"
    bindValue="category"
    [(ngModel)]="state.category"
    (ngModelChange)="setCategory($event)"
  >
  <ng-template ng-label-tmp let-item="item">
    <span class="media-name" [ngClass]="item.category | toolColor">{{item.name | translate}}</span>
  </ng-template>
  <ng-template ng-option-tmp let-item="item">
      <span class="media-name" [ngClass]="item.category | toolColor">{{item.name | translate}}</span>
  </ng-template>
</ng-select>
  <div class="controls__content">
    <button 
      type="button" 
      [ngClass]="displayFilters.length === 0 ? 'disabled': ''"
      class="controls__remove-filters" 
      (click)="removeAllFilters()">
      Reset filters
    </button>
  </div>
</div>

<div class="faq">
  <div class="breadcrumbs">
    <a class="breadcrumbs__link" [routerLink]="['/dashboard']">
      <i class="nd-arrow-left"></i>
      Dashboard
    </a>
    /
    <span class="breadcrumbs__text">FAQ</span>
  </div>
  
  <ng-container *ngIf="data && isArray(data) && state.startPage">
    <h2 class="faq__title">
      {{ 'faq_page.title' | translate }}
    </h2>
    <span class="faq__counter">{{data.length}}  Resultaten</span>
    <ng-container *ngFor="let item of data">
      <app-collapsible
        [category]="item.category"
        class="mb-3"
        [color]="'white'"
        [title]="item.title"
      >
        <div [innerHTML]="item.content" class="collapsible-text"></div>
      </app-collapsible>
    </ng-container>
  </ng-container>

  <ng-container *ngIf="data && !isArray(data) && !state.startPage">
    <span class="faq__counter">{{state.total}}  Resultaten</span>
    <ng-container *ngFor="let categoryItem of categories">
      <ng-container
        *ngIf="
          data[categoryItem.category] &&
          ((data[categoryItem.category].all &&
            data[categoryItem.category].all.length) ||
            (data[categoryItem.category].top &&
              data[categoryItem.category].top.length))
        "
      >
        <h2 class="faq__title">
          {{ 'global.tool_type.' + categoryItem.category | translate }}
        </h2>

        <ng-container
          *ngIf="
            data[categoryItem.category].top &&
            data[categoryItem.category].top.length
          "
        >
          <p class="faq__info">
            {{
              'faq_page.content.top_title'
                | translate
                  : {
                      tool_type:
                        'global.tool_type.' + categoryItem.category
                        | translate
                        | lowercase
                    }
            }}
          </p>
          <p class="faq__info">
            {{
              'faq_page.content.top_description'
                | translate
                  : {
                      tool_type:
                        'global.tool_type.' + categoryItem.category
                        | translate
                        | lowercase
                    }
            }}
          </p>
        </ng-container>

        <ng-container *ngFor="let item of data[categoryItem.category].top">
          <app-collapsible
            [category]="item.category"
            class="mb-3 collapsible-text"
            [color]="'white'"
            [title]="item.title"
          >
            <div [innerHTML]="item.content"></div>
          </app-collapsible>
        </ng-container>

        <ng-container
          *ngIf="
            data[categoryItem.category].all &&
            data[categoryItem.category].all.length"
        >
          <p class="faq__info">
            {{
              'faq_page.content.all_title'
                | translate
                  : {
                      tool_type:
                        'global.tool_type.' + categoryItem.category
                        | translate
                        | lowercase
                    }
            }}
          </p>
          <ng-container
            *ngFor="let item of data[categoryItem.category].all"
          >
            <app-collapsible
              [category]="item.category"
              class="mb-3 collapsible-text"
              [color]="'white'"
              [title]="item.title"
            >
              <div [innerHTML]="item.content"></div>
            </app-collapsible>
          </ng-container>
        </ng-container>
      </ng-container>
    </ng-container>
  </ng-container>
</div>