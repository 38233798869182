import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { FaqPage } from '@modules/faq/pages/faq/faq.page';
import { KeycloakWrapperAuthGuard } from '@shared/keycloak/keycloak-auth.guard';

const routes: Routes = [
  {
    path: '',
    component: FaqPage,
    canActivate: [KeycloakWrapperAuthGuard],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class FaqRoutingModule {}
