<div class="unused-tiles"
     [ngClass]="{ 'd-none': !editModeState,
                  'nav-collapsed': isCollapsedNav,
                   'top-alert-position': isExceptionalAlert}">
  <div class="unused-tiles-header">
    <h3 class="title">{{ 'draggable_tiles.head' | translate }}</h3>
    <button type="button" (click)="cancel()" class="close close-btn" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="unused-tiles-border"></div>

  <div class="unused-tiles-descriptopns">
    <p class="text-primary"><strong>{{ 'draggable_tiles.title' | translate }}</strong></p>
    <p>
    {{ 'draggable_tiles.description' | translate }}
    </p>

    <div class="unused-tiles-btns">
      <button
        class="btn btn-primary"
        *ngIf="editModeState"
        (click) = "save()"
      >
        {{'dashboard_page.tiles_grid.save' | translate }}
      </button>
      <button
        class="btn btn-outline-primary"
        *ngIf="editModeState"
        (click)="cancel()"
      >
        {{'dashboard_page.tiles_grid.cancel' | translate }} </button>
    </div>
  </div>

  <div class="tiles-list-container">
    <div #tilesListSpots class="tiles-list-spots" *ngIf="editModeState">
      <div
        [ngClass]="{
          hovered:
            (hoveredSpot.asObservable() | async)?.grid === muuriList?._id &&
            (hoveredSpot.asObservable() | async)?.index === idx
        }"
        *ngFor="
          let item of listSpotsCount.asObservable() | async;
          let idx = index
        "
      ></div>
    </div>
    <div #tilesList class="tiles-list">
      <ng-container *ngFor="let item of _unusedTiles">
        <div
          class="item"
          [attr.data-id]="item.id"
          [attr.data-initial-order]="item.order"
        >
          <app-tool-card [tile]="item"></app-tool-card>
        </div>
      </ng-container>
    </div>
  </div>
</div>

<button
  class="d-none d-xl-block btn btn-primary adapt-btn"
  *ngIf="!editModeState && !isMobile()"
  (click)="addNewTiles()"
>
  {{'dashboard_page.tiles_grid.edit' | translate }}
</button>
<div class="tiles-grid-container">
  <div #tilesGridSpots class="tiles-grid-spots" *ngIf="editModeState">
    <div
      [ngClass]="{
        hovered:
          (hoveredSpot.asObservable() | async)?.grid === muuriGrid._id &&
          (hoveredSpot.asObservable() | async)?.index === idx
      }"
      *ngFor="
        let item of gridSpotsCount.asObservable() | async;
        let idx = index
      "
    ></div>
  </div>
  <div #tilesGrid class="tiles-grid" [ngClass]="{ 'edit-mode': editModeState }">
    <ng-container *ngFor="let item of _tiles">
      <div
        class="item"
        [attr.data-id]="item.id"
        [attr.data-initial-order]="item.order"
      >
        <app-tool-card [tile]="item"></app-tool-card>
      </div>
    </ng-container>


    <!--TODO hide for temprorary time-->
    <!--<ng-container *ngIf="!editModeState && !isMobile()">-->
      <!--<div class="item d-none d-xl-block">-->
        <!--<div class="item-content">-->
          <!--<div class="item-content-inner">-->
            <!--<button class="add-extra-line"-->
                    <!--(click)="addNewTiles()">-->
              <!--{{'draggable_tiles.add_extra_line' | translate}}-->
            <!--</button>-->
          <!--</div>-->
        <!--</div>-->
      <!--</div>-->
    <!--</ng-container>-->

  </div>
</div>
