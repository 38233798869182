// tslint:disable-next-line:import-name
import gql from 'graphql-tag';

export const lastViewedProducts = gql`
  query products($ids: [Int!]) {
    productsByIds(ids: $ids) {
      totalCount
      items {
        id
        slug
        title
        proxy_url
        request_url
        contact_url
        description_title
        description
        provision
        order
        category
        type
        kind
        insurance_company {
          id
          title
          logo
          documents {
            id
            title
            url
            type
            document_type
          }
          contact {
            type
            address
            phone
            email
          }
        }
        documents {
          id
          title
          url
          type
          document_type
        }
        package {
          title
          documents {
            id
            title
            url
            type
            document_type
          }
        }
      }
    }
    contacts {
      type
      address
      phone
      email
      proxy_url
    }
  }
`;
