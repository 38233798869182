import { Component, OnInit } from '@angular/core';
import { NavService } from '@core/nav/nav.service';
import { CollapsedNav } from '@core/store/core-actions';
import { Store } from '@ngrx/store';
import { IAppAlert } from '@shared/components/alerts/alerts.interface';
import { GetAlerts } from '@shared/components/alerts/store/alerts-actions';
import { AlertsState } from '@shared/components/alerts/store/alerts-reducer';

import { State } from '../../../../store/app-reducers';

@Component({
  selector: 'app-exceptional-alert',
  templateUrl: './exceptional-alert.component.html',
  styleUrls: ['./exceptional-alert.component.scss'],
})
export class ExceptionalAlertComponent implements OnInit {
  exceptionalAlert: IAppAlert;
  isCollapsedNav: boolean;
  constructor(private store: Store<State>, public navService: NavService) {}

  ngOnInit() {
    this.store.dispatch(new GetAlerts());
    this.getAllerst();
    this.getCollapsedNavState();

    this.store.select('core').subscribe(data => {
      this.isCollapsedNav = data.isCollapsedNav;
    });
  }

  getAllerst(): void {
    this.store.select('alerts').subscribe(({ alert }: AlertsState) => {
      this.exceptionalAlert = alert;
    });
  }

  getCollapsedNavState(): void {
    this.navService.getToggleState().subscribe(state => {
      this.isCollapsedNav = state;
    });
  }
}
