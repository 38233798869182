<button (click)="openModal(template)" class="incorrect-item-link">
  {{ 'product_feedback.modal_open_btn' | translate }}
</button>

<ng-template #template>
  <ng-container *ngIf="!state.isSent">
    <div class="modal-header">
      <h3 class="modal-title pull-left">
        {{
          'product_feedback.modal_title'
            | translate
              : {
                  type: 'product_feedback.' + state.type | translate | lowercase
                }
        }}
      </h3>
    </div>
    <div class="modal-body">
      <div class="row mb-2">
        <div class="col-4">{{ 'product_feedback.company' | translate }}:</div>
        <div class="col-8">{{ data.companyTitle }}</div>
      </div>
      <div class="row mb-2" *ngIf="data.productTitle">
        <div class="col-4">{{ 'product_feedback.product' | translate }}:</div>
        <div class="col-8">{{ data.productTitle }}</div>
      </div>

      <div class="mb-0 form-group has-float-label mt-4">
        <textarea class="form-control" [(ngModel)]="data.message"></textarea>
        <label>{{ 'product_feedback.message_label' | translate }}</label>
      </div>
    </div>
    <div class="modal-footer">
      <div class="d-flex justify-content-between w-100 flex-row-reverse">
        <button
          class="btn btn-primary"
          [ngClass]="{ disabled: !data.message }"
          (click)="submitForm()"
        >
          {{ 'product_feedback.send_btn' | translate }}
        </button>
        <button class="btn btn-link pl-0" (click)="modalRef.hide()">
          {{ 'product_feedback.dismiss_btn' | translate }}
        </button>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="state.isSent">
    <div class="modal-header">
      <h3 class="modal-title pull-left">
        {{ 'product_feedback.success_title' | translate }}
      </h3>
    </div>
    <div class="modal-body pt-0">
      <p class="h5">
        {{ 'product_feedback.success_content' | translate }}
      </p>
    </div>
    <div class="modal-footer">
      <div class="d-flex justify-content-between w-100 flex-row-reverse">
        <button class="btn btn-link pl-0" (click)="modalRef.hide()">
          {{ 'product_feedback.close_btn' | translate }}
        </button>
      </div>
    </div>
  </ng-container>
</ng-template>
