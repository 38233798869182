import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { InsuranceProduct } from '@modules/products/interfaces/insurance-product.interface';
import { ProductsService } from '@modules/products/products.service';
import { pluck, switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-product',
  templateUrl: './product.page.html',
})
export class ProductPage implements OnInit {
  isMutation = false;
  header: any;
  product: InsuranceProduct;
  constructor(private service: ProductsService, private route: ActivatedRoute) {
    this.isMutation = this.route.snapshot.data.isMutation;
    this.route.params
      .pipe(
        switchMap(params =>
          this.service.getProduct(params.slug, this.isMutation),
        ),
        pluck('product'),
      )
      .subscribe(product => {
        this.product = product;
        this.header = {
          logo: product.insurance_company.logo,
          title: product.title,
          website: product.insurance_company.website,
          contact: [{ email: product.insurance_company.contact[0].email }],
        };
      });
  }

  ngOnInit() {}
}
