<td class="product" colspan="5">
  <div class="product__wrapper">
    <div class="product__description">
      <h3 class="product__title">
        {{ product.description_title | htmlString }}
      </h3>
      <div [innerHTML]="product.description"></div>
    </div>


    <div class="product__options">
      <h3 class="product__title">{{ 'products_list.details.package_options' | translate }}</h3>
      <ul class="unordered-list">
        <li class="mb-0" *ngFor="let package of product.package">
          {{ package.title | htmlString }}
        </li>
      </ul>
    </div>


    <div class="product__commission">
      <div  class="product__commission-wrapper">
        <span class="product__commission-text">
          {{ 'products_list.details.commission' | translate }}
        </span>
        <span class="product__commission-provision">{{ product.provision }}%</span>
      </div>
    </div>


    <div class="product__contacts">
      <h3 class="product__title">{{ 'products_list.details.contacts' | translate }}</h3>
      <!-- <p [ngClass]="{ 'm-0': fullPageSize }">
        {{ contact?.address | htmlString }}
      </p> -->
      <div class="product__contacts-holder">
        <ng-container *ngIf="contact?.contact_url; then contactUrl; else contactUrlEmpty">
        </ng-container>
        <ng-template #contactUrlEmpty>
          <a [href]="'tel:' + contact?.phone" class="product__contacts-item">
            {{ contact?.phone }}
          </a>
          <a [href]="'mailto:' + contact?.email" class="product__contacts-item">
            {{ contact?.email }}
          </a>
        </ng-template>
        <ng-template #contactUrl>
          <a [href]="contact.contact_url" class="product__contacts-item">
            {{ 'products_list.contact_url' | translate }}
          </a>
        </ng-template>
      </div>
    </div>
    <div class="product__documents">
      <h3 class="product__title">{{ 'products_list.details.documents' | translate }} ({{documents.length}})</h3>
      <div class="product__documents-holder">
        <ng-container  *ngFor="let document of documents; let idx = index">
          <div
          class="product__documents-item"
          *ngIf="documentsExpanded || (!documentsExpanded && idx < 6)"
        >
          <a
            class="product__documents-card card p-2 d-flex flex-row align-items-center"
            [href]="document.url"
            target="_blank"
          >
            <i
              class="mr-2 h2 mb-0 text-primary"
              [ngClass]="{
                'nd-file-pdf': document.document_type === 'pdf',
                'nd-file-word': document.document_type === 'doc',
                'nd-file-excel': document.document_type === 'excel',
                'nd-file-image': document.document_type === 'image',
                'nd-blank':
                  document.type !== 'pdf' &&
                  document.type !== 'word' &&
                  document.type !== 'excel' &&
                  document.type !== 'image'
              }"
            ></i>
            <div style="width: calc(100% - 60px);">
              <span class="product__documents-type">
                {{ document.type | htmlString }}
              </span>
              <span
                class="text-truncate product__documents-title"
                [title]="document.title | htmlString"
                >{{ document.title | htmlString }}
              </span>
            </div>
          </a>
        </div>
        </ng-container>
      </div>
      <button
        *ngIf="documents.length > 6"
        class="btn btn-link btn-block"
        (click)="documentsExpanded = !documentsExpanded"
      >
        <!-- prettier-ignore -->
        {{
              'products_list.details.' +
                (!documentsExpanded
                  ? 'show_all_documents'
                  : 'show_less_documents')
              | translate
            }}
        <i
          [ngClass]="
            'nd-chevron-' + (!documentsExpanded ? 'down-small' : 'up-small')
          "
        ></i>
      </button>
    </div>
    <div class="product__feedback">
      <p class="product__feedback-text">
        {{ 'products_list.feedback_prologue' | translate }}
      </p>
      <app-incorrect-item [product]="product"></app-incorrect-item>
    </div>
  </div>
</td>
