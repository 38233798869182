<div class="dashboard sidebar-margin" *ngIf="data" [ngClass]="{'is-nav-collapsed': isCollapsedNav}">
   <app-alerts
      [alerts]="data ? data.alerts : []"
      (onClose)="onAlertClose($event)"
      class="d-block"
    ></app-alerts>

    <div class="editable-tiles-container">
      <div class="row">
        <div class="col">
          <h1 id="step1" *ngIf="auth$ | async as auth" class="step-title">
            {{'dashboard_page.greeting.' + dayTime | translate}}
            {{
            (auth.firstName | username)
            ? ' ' + (auth.firstName | username) + ','
            : ','
            }}
          </h1>
        </div>
      </div>

        <div class="step23" id="step23">
          <nav class="tabs tabs__scrollable" *ngIf="state.activeCategory">
            <span *ngFor="let category of data.dashboardCategories; let idx = index">
              <a class="tabs__item"
                 *ngIf="category.tiles.length"
                 [routerLink]="['/dashboard']"
                 [queryParams]="{category: categoriesDict[category.id]}"
                 [disabled]="state.isEditMode && !category.id !== state.activeCategory.id"
                 [ngClass]="{ 'active': category.id === state.activeCategory.id}"
              >
                {{ category.name }}
              </a>
            </span>
          </nav>

        <div class="draggable-block" *ngIf="state.activeCategory">
          <app-draggable-tiles
            [tiles]="state.activeCategory.tiles"
            [isEditMode]="state.isEditMode"
            (orderChanged)="handleReordering($event)"
          ></app-draggable-tiles>
        </div>
      </div>
  </div>
</div>
<div>
  <app-sidebar class="app-sidebar" [ngClass]="{'is-nav-collapsed': isCollapsedNav}">
    <app-news-widget></app-news-widget>
    <app-contact-widget></app-contact-widget>
  </app-sidebar>
</div>
