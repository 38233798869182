import { Component, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { environment } from '@env/environment';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { filter, map, pluck } from 'rxjs/operators';
import { IConfig } from 'src/app/config/app.config.interface';
import { State } from 'src/app/store/app-reducers';

import { IAccountManager, IDepartment } from '../../contact.interface';
import { ContactService } from '../../contact.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.page.html',
  styleUrls: ['./contact.page.scss'],
})
export class ContactPage implements OnInit, OnDestroy {
  contactPageFormUrl: SafeResourceUrl = null;
  mapContacts: any;
  accountManagers: IAccountManager[];
  departments: IDepartment[][];
  subscriptions = new Subscription();
  mapCoordinates = null;
  isBSB: boolean;

  constructor(
    private contactService: ContactService,
    private sanitizer: DomSanitizer,
    public store: Store<State>,
  ) {
    const subscription = this.contactService
      .getContactPageData()
      .subscribe(res => {
        this.accountManagers = res.accountManagers;
        this.departments = this.getDividedBuColumns(res.departments);
      });
    this.contactService.getMapContact().subscribe(res => {
      const stringPartForReplace = '\r\n';
      const updatedStringPart = ', ';
      this.mapContacts = res;
      this.mapContacts.visitorAddress = res.visitorAddress.replace(
        stringPartForReplace,
        updatedStringPart,
      );
      this.mapContacts.postAddress = res.postAddress.replace(
        stringPartForReplace,
        updatedStringPart,
      );
    });
  }

  getDividedBuColumns(array: any[]) {
    const columns = [[], [], []];
    return array.reduce((acc, el, idx) => {
      acc[idx % 3].push(el);
      return acc;
    }, columns);
  }

  ngOnInit() {
    this.store
      .select('core')
      .pipe(
        pluck('config'),
        filter(item => item !== undefined),
      )
      .subscribe((data: any) => {
        this.mapCoordinates = {
          map: {
            latitude: Number(data.coordinates.map.latitude),
            longitude: Number(data.coordinates.map.longitude),
            zoom: Number(data.coordinates.map.zoom),
          },
          marker: {
            latitude: Number(data.coordinates.marker.latitude),
            longitude: Number(data.coordinates.marker.longitude),
          },
        };
        this.contactPageFormUrl = this.getSafeUrl(
          data.additionalInfo.contactPageFormUrl,
        );

        this.isBSB = data.code === 'BSB' ? true : false;
      });
  }

  getSafeUrl(url) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
