// tslint:disable-next-line:import-name
import gql from 'graphql-tag';

export const filtersQuery = gql`
  query filters {
    categories {
      id
      title
      slug
      parent
    }
    companies {
      items {
        id
        title
      }
    }
  }
`;
