import { buildEnv } from './environment.base';

export const environment = buildEnv({
  isBaseEnviroment: false,
  graphql: 'https://whal.acc.nsn.nl/graphql',
  keycloak: {
    url: 'https://naa.acc.nedasco.nl/',
    realm: 'NedascoServiceNet',
    clientId: 'portal',
  },
  profile: {
    manageProfileUrl:
      'https://naa.acc.nedasco.nl/auth/realms/NedascoServiceNet/account/',
  },
  configServiceAPI: 'https://api-product-config-acc.acc.onsweb.com/api/v1',
});
