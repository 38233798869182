import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ContactPage } from '@modules/contact/pages/contact/contact.page';
import { KeycloakWrapperAuthGuard } from '@shared/keycloak/keycloak-auth.guard';

const routes: Routes = [
  {
    path: '',
    component: ContactPage,
    canActivate: [KeycloakWrapperAuthGuard],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ContactRoutingModule {}
