import { AgmCoreModule, LAZY_MAPS_API_CONFIG } from '@agm/core';
import { AgmSnazzyInfoWindowModule } from '@agm/snazzy-info-window';
import { DatePipe, TitleCasePipe } from '@angular/common';
import {
  HTTP_INTERCEPTORS,
  HttpClient,
  HttpClientModule,
} from '@angular/common/http';
import { APP_INITIALIZER, DoBootstrap, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CoreModule } from '@core/ core.module';
import { environment } from '@env/environment';
import {
  FaIconLibrary,
  FontAwesomeModule,
} from '@fortawesome/angular-fontawesome';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { ContactModule } from '@modules/contact/contact.module';
import { DashboardModule } from '@modules/dashboard/dashboard.module';
import { FaqModule } from '@modules/faq/faq.module';
import { NewsModule } from '@modules/news/news.module';
import { ProductsModule } from '@modules/products/products.module';
import { SupportModule } from '@modules/support/support.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AlertsInterceptor } from '@shared/components/alerts/alerts.interceptor';
import { ModulesGuard } from '@shared/guards/modules.guard';
import { keycloakConfig } from '@shared/keycloak/keycloak.config';
import { SharedModule } from '@shared/shared.module';
import { WINDOW_PROVIDERS } from '@shared/window.token';
import { KeycloakAngularModule, KeycloakService } from 'keycloak-angular';
import { AlertModule } from 'ngx-bootstrap/alert';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ModalModule } from 'ngx-bootstrap/modal';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { RoutingModule } from 'src/app/routing.module';
import urlJoin from 'url-join';

import { AppComponent } from './app.component';
import { AppConfigService } from './config/app-config.service';
import { GraphQLModule } from './graphql.module';
import { AppEffectsModule } from './store/app-effects.module';
import { appReducers } from './store/app-reducers';

const keycloakService = new KeycloakService();

const googleConfigFactory = (appConfig: AppConfigService) => {
  return () => {
    return appConfig.fetchGoogleConfigData();
  };
};
const appInitializerFn = (appConfig: AppConfigService) => {
  return () => {
    return appConfig.fetchConfigData();
  };
};

@NgModule({
  declarations: [AppComponent],
  imports: [
    AppEffectsModule,
    BrowserModule,
    KeycloakAngularModule,
    FontAwesomeModule,
    HttpClientModule,
    StoreModule.forRoot(appReducers),
    StoreDevtoolsModule.instrument({
      maxAge: 50,
      logOnly: environment.production,
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (http: HttpClient) => {
          return new TranslateHttpLoader(http, './assets/i18n/', '.json');
        },
        deps: [HttpClient],
      },
    }),
    RoutingModule,
    CoreModule,
    SharedModule,
    GraphQLModule,
    ContactModule,
    DashboardModule,
    FaqModule,
    ProductsModule,
    NewsModule,
    SupportModule,

    AgmCoreModule.forRoot(),
    AgmSnazzyInfoWindowModule,
    PaginationModule.forRoot(),
    AlertModule.forRoot(),
    BsDropdownModule.forRoot(),
    CollapseModule.forRoot(),
    ModalModule.forRoot(),
    TooltipModule.forRoot(),
    KeycloakAngularModule,
    BrowserAnimationsModule,
    NgbModule,
  ],
  providers: [
    WINDOW_PROVIDERS,
    AppConfigService,
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFn,
      multi: true,
      deps: [AppConfigService],
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AlertsInterceptor,
      multi: true,
    },
    {
      provide: KeycloakService,
      useValue: keycloakService,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: googleConfigFactory,
      multi: true,
      deps: [AppConfigService],
    },
    {
      provide: LAZY_MAPS_API_CONFIG,
      useFactory: (init: AppConfigService) => ({ apiKey: init.apiKey }),
      deps: [AppConfigService],
    },
    {
      provide: 'googleTagManagerId',
      useFactory: getGoogleTagManagerId,
      deps: [AppConfigService],
    },
    ModulesGuard,
    DatePipe,
    TitleCasePipe,
  ],
  entryComponents: [AppComponent],
})
export class AppModule implements DoBootstrap {
  constructor(library: FaIconLibrary) {
    library.addIconPacks(fas, far);
  }

  async ngDoBootstrap(app: any) {
    try {
      await keycloakService.init(keycloakConfig);
      app.bootstrap(AppComponent);
    } catch (error) {
      console.error('Keycloak init failed', error);
    }
  }
}

export function getGoogleTagManagerId(appConfig: AppConfigService) {
  return appConfig.gtmId;
}
