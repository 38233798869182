<div class="row last-products" *ngIf="items$ | async as items">
  <div class="col-12" *ngIf="items.length">
    <h3 class="last-products__title">{{ 'products_page.last_viewed_products' | translate }}</h3>
  </div>
  <div class="col-lg-6 col-xl-3" *ngFor="let product of items">
    <div class="card h5 last-products__card">
      <div class="last-products__header d-flex align-items-center">
        <img
          height="20"
          width="20"
          class="media-image mr-2"
          [src]="product.insurance_company.logo"
        />
        <span></span>
        {{ product.insurance_company.title | htmlString }}
      </div>

      <div class="last-products__sub-title">
        {{ product.title | htmlString }}
      </div>

      <div class="last-products__label">
        {{ 'products_page.filters.type.' + product.type | translate }}
      </div>

      <div>
        <a
          class="mb-2 btn btn-block btn-sm btn-outline-primary text-nowrap"
          [href]="product.proxy_url || product.request_url || product.general_proxy_url"
          target="_blank"
          *ngIf="product.type === 'proxy'"
        >
          <!-- prettier-ignore -->
          {{
            ('products_list.buttons.' + ((product.proxy_url || (!product.proxy_url && !product.request_url)) ? 'direct_redirect' : 'redirect' ) | translate)
          }}
        </a>
        <a
          class="mb-2 btn btn-block last-products__link-btn btn-outline-primary text-nowrap"
          [routerLink]="[
            isMutation
              ? '/products/niet-volmacht-mutaties'
              : '/products/product',
            product.slug
          ]"
          *ngIf="product.type === 'non_proxy'"
        >
          {{
            'products_list.buttons.' +
              (isMutation ? 'proxy_redirect_mutation' : 'proxy_redirect')
              | translate
          }}
        </a>
        <a
          class="btn btn-sm btn-link text-nowrap last-products__read-more"
          *ngIf="true"
          href="javascript:void(0)"
          (click)="toggleOpenedProduct(product)"
        >
          <!-- prettier-ignore -->
          {{
            'products_list.buttons.' +
              (!openedProduct || product.id !== openedProduct.id ? 'open_details' : 'close_details') | translate
          }}
          <i
            [ngClass]="
              'nd-chevron-' +
              (!openedProduct || product.id !== openedProduct.id
                ? 'down-small'
                : 'up-small')
            "
          ></i>
        </a>
      </div>
    </div>
  </div>
</div>
<div class="row" *ngIf="openedProduct">
  <div class="col-12">
    <div class="table-responsive">
      <table class="table">
        <tr
          app-product-item-expanded
          [product]="openedProduct"
          [fullPageSize]="false"
        ></tr>
      </table>
    </div>
  </div>
</div>
