import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { IFiltersOptions, IFiltersValues } from './products-filters.interface';
import { ProductsFiltersService } from './products-filters.service';

@Component({
  selector: 'app-products-filters',
  templateUrl: './products-filters.component.html',
  styleUrls: ['./products-filters.component.scss'],
})
export class ProductsFiltersComponent implements OnInit {
  @Input() isMutation = false;

  options: IFiltersOptions;
  values: IFiltersValues;

  constructor(
    private service: ProductsFiltersService,
    private ref: ChangeDetectorRef,
    private route: ActivatedRoute,
    private router: Router,
  ) {
    this.route.queryParams.subscribe(res => {
      this.filtersSearchChanged(res.s);
    });
    this.options = this.service.options;
    this.values = this.service.values;
    this.service.filters$.subscribe(res => {
      this.values = this.service.values;
      this.ref.markForCheck();
    });
  }

  ngOnInit() {}

  filtersSearchChanged(term: string) {
    this.service.setSearchFilter(term);
  }
  filtersSearchByChanged(value: string) {
    this.service.setSearchByFilter(value);
  }
  filtersKindChanged(value: string[]) {
    this.service.setKindFilter(value);
  }
  filtersTypeChanged(value: string[]) {
    this.service.setTypeFilter(value);
  }
  filtersCompanyChanged(value: string[]) {
    this.service.setCompanyFilter(value);
  }
  filtersCategoryChanged(value: string) {
    this.service.setCategoryFilter(value);
  }
  filtersSubcategoryChanged(value: string[]) {
    this.service.setSubcategoryFilter(value);
  }
  public setSearchQuery(term?: string) {
    const queryParams = {};
    if (term) {
      queryParams['s'] = term;
    }
    this.router.navigate([], { queryParams, relativeTo: this.route });
  }
}
