import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';

import { IFooterLinks } from './footer.interface';
import { FooterService } from './footer.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent {
  footerLinks$: Observable<IFooterLinks>;
  footerTitle: string;

  constructor(private footerService: FooterService) {
    this.footerLinks$ = this.footerService.getLinks();
    this.footerService.getTitle().subscribe(title => {
      this.footerTitle = title;
    });
  }
}
