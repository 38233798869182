import {
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { NavigationEnd, Router, RoutesRecognized } from '@angular/router';
import { NavService } from '@core/nav/nav.service';
import { Store } from '@ngrx/store';
import { AlertsState } from '@shared/components/alerts/store/alerts-reducer';
import { categoriesDict } from '@shared/interfaces/dashboard-categories.interface';
import { IKeycloakProfileLinks } from '@shared/keycloak/keycloak-profile.interface';
import { KeycloakWrapperService } from '@shared/keycloak/keycloak-wrapper.service';
import { MediaBreakpointService } from '@shared/services/media-breakpoint/media-breakpoint.service';
import { OnboardingService } from '@shared/services/onboarding/onboarding.service';
import { KeycloakProfile } from 'keycloak-js';
import { BsDropdownToggleDirective } from 'ngx-bootstrap/dropdown';
import { Observable, Subscription } from 'rxjs';
import { filter, map, pluck } from 'rxjs/operators';
import { IConfig } from 'src/app/config/app.config.interface';

import { State } from '../../store/app-reducers';

import { IHeaderResponse } from './header.interface';
import { HeaderService } from './header.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChildren(BsDropdownToggleDirective) dropdowns: QueryList<
    BsDropdownToggleDirective
  >;

  isCollapsedNav: boolean;
  isWizardEnabled: boolean;
  isExceptionalAlert: boolean;
  imagePath: string;
  appModules;

  public isPageFullWidth$: Observable<boolean>;
  public categoriesDict = categoriesDict;
  public navbar = {
    collapsed: true,
    smallScreen: false,
  };
  private subscriptions = new Subscription();
  public auth$: Observable<KeycloakProfile>;
  public data: IHeaderResponse;
  public userProfile: IKeycloakProfileLinks;
  public showWizard = true;
  public manageProfileUrl: string;

  constructor(
    private keycloakWrapperService: KeycloakWrapperService,
    private headerService: HeaderService,
    private mediaBreakpointService: MediaBreakpointService,
    private onboardingService: OnboardingService,
    public router: Router,
    public navService: NavService,
    public store: Store<State>,
  ) {
    this.store.select('alerts').subscribe(({ alert }: AlertsState) => {
      this.isExceptionalAlert =
        alert !== null && alert !== undefined ? true : false;
    });

    this.isPageFullWidth$ = this.router.events.pipe(
      filter(data => data instanceof RoutesRecognized),
      map((data: any) => {
        let r = (data.state as any)._root;
        while (r.children && r.children.length) {
          r = r.children[0];
        }
        return !!r.value.data.fullWidthPage;
      }),
    );
    this.userProfile = this.keycloakWrapperService.getProfileLinks();
    this.auth$ = this.keycloakWrapperService.getUserProfile();
    const authSubscription = this.auth$.subscribe(user => {
      const headerDataSubscription = this.headerService
        .getData()
        .subscribe(data => {
          this.data = data;
        });
      this.subscriptions.add(headerDataSubscription);
    });
    this.subscriptions.add(authSubscription);
    const screenSubscription = this.mediaBreakpointService.resizeBreakpoint$.subscribe(
      res => {
        this.navbar.smallScreen = ['md', 'sm', 'xs'].includes(res);
      },
    );
    this.subscriptions.add(screenSubscription);

    this.store
      .select('core')
      .pipe(
        pluck('config'),
        filter(data => data !== null),
      )
      .subscribe((data: IConfig) => {
        this.imagePath = data.storeUrl;
      });

    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((event: any) => {
        this.isDashboardUrl(event.url);
      });
  }

  ngOnInit() {
    this.store.select('core').subscribe(data => {
      this.appModules = data.config.modules;
      this.isCollapsedNav = data.isCollapsedNav;
      this.isWizardEnabled = data.config.additionalInfo.isWizardEnabled;
      this.manageProfileUrl = data.config.additionalInfo.manageProfileUrl;
    });
  }

  modulePermission(module) {
    return this.appModules.includes(module);
  }

  ngAfterViewInit() {
    this.dropdowns.changes.subscribe(elements => {
      elements.forEach(element => {
        if (
          element &&
          element.dropdown &&
          element.dropdown._elementRef.nativeElement.classList.contains(
            'dropdown-user',
          )
        ) {
          this.onboardingService.setUserDropdown(element.dropdown);
        }
        if (
          element &&
          element.dropdown &&
          element.dropdown._elementRef.nativeElement.classList.contains(
            'dropdown-links',
          )
        ) {
          this.onboardingService.setMenuDropdown(element.dropdown);
        }
      });
    });
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  logout() {
    this.keycloakWrapperService.logout();
  }

  preventClose($event: MouseEvent) {
    $event.stopPropagation();
  }

  initTour() {
    if (this.isDashboardUrl(window.location.href)) {
      this.onboardingService.initTour(this.data.dashboardCategories);
    } else {
      this.router.navigate(['/dashboard']);
      setTimeout(() => {
        this.onboardingService.initTour(this.data.dashboardCategories);
      }, 1000);
    }
  }

  checkHttpInUrl(url: string) {
    return url.includes('http://') || url.includes('https://');
  }

  isDashboardUrl(url: string) {
    return (this.showWizard = url.includes('dashboard') ? true : false);
  }

  isTilesByCategory(id: string) {
    return this.data.toolTiles.filter(tile => tile.category.includes(+id))
      .length
      ? true
      : false;
  }

  getTilesByCategory(id: string) {
    return this.data.toolTiles.filter(tile => tile.category.includes(+id));
  }

  redirectToUrl(url): void {
    if (url) {
      window.open(url, '_blank');
    }
  }
}
