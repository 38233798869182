import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NavService } from '@core/nav/nav.service';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { categoriesDict } from '@shared/interfaces/dashboard-categories.interface';
import * as introJs from 'intro.js/intro.js';
import { BsDropdownDirective } from 'ngx-bootstrap/dropdown';
import { Observable, Subscription } from 'rxjs';
import { filter, pluck } from 'rxjs/internal/operators';
import { delay } from 'rxjs/operators';

import { IConfig } from '../../../config/app.config.interface';
import { State } from '../../../store/app-reducers';

@Injectable()
export class OnboardingService {
  public clientCode: string;
  public isCollapsedNav: boolean;
  private translations$: Observable<any>;
  public tourOpened = false;
  public toggleStateSub: Subscription;
  private tourSubscription: Subscription;
  private userDropdown: BsDropdownDirective;
  private menuDropdown: BsDropdownDirective;

  constructor(
    private translateService: TranslateService,
    private router: Router,
    private store: Store<State>,
    private navService: NavService,
  ) {
    this.translations$ = this.translateService.get('onboarding');
    // TODO add  isCollapsedNav state to core store
    this.toggleStateSub = this.navService.getToggleState().subscribe(state => {
      this.isCollapsedNav = state;
    });
  }

  /***
   * TODO need to rebuilt this part and receive info from BE
   * Temprorary, will be deleted
   *  Get clientCode from config data "code" from store (value is uppercase)
   *  return: true/false
   */

  get isBSB(): boolean {
    this.store
      .select('core')
      .pipe(
        pluck('config'),
        filter(data => data !== null),
      )
      .subscribe((data: IConfig) => {
        this.clientCode = data.code;
      });
    return this.clientCode === 'BSB';
  }

  initTour(categories: any[], onExit?: any) {
    if (!this.tourOpened) {
      const orderedCategories = categories.map(cat => categoriesDict[cat.id]);

      this.router.navigate(['/dashboard'], {
        queryParams: { category: orderedCategories[0] },
      });

      this.tourSubscription = this.translations$
        .pipe(delay(10))
        .subscribe(translations => {
          const tour = introJs().setOptions({
            ...this.getOptions(translations),
            steps: this.getSteps(translations),
          });

          tour.start();
          this.tourOpened = true;
          this.handleChangeStep(tour, orderedCategories);
          this.handleExit(tour, onExit);
        });
    }
  }
  private handleChangeStep(tour: any, orderedCategories: string[]) {
    const header = <HTMLDivElement>document.querySelector('app-header .navbar');
    const sidebar = <HTMLDivElement>document.querySelector('div .app-sidebar');
    const disableBlock = <HTMLDivElement>(
      document.querySelector('.introjs-disableInteraction')
    );
    const newsWidget = <HTMLDivElement>(
      document.querySelector('app-news-widget .news-widget')
    );
    const contactWidget = <HTMLDivElement>(
      document.querySelector('app-contact-widget .contact-widget')
    );

    tour.onbeforechange(() => {
      if (tour._direction === 'forward') {
        if (tour._currentStep === 2) {
          this.router.navigate(['/dashboard'], {
            queryParams: { category: orderedCategories[1] },
          });
        }
        if (tour._currentStep === 3) {
          this.router.navigate(['/dashboard'], {
            queryParams: { category: orderedCategories[0] },
          });
        }
      }
      if (tour._direction === 'backward') {
        setTimeout(() => {
          tour.goToStepNumber(1);
          this.router.navigate(['/dashboard'], {
            queryParams: { category: orderedCategories[0] },
          });
        });
      }

      if (tour._currentStep === 4 || tour._currentStep === 5) {
        sidebar.style.position = 'static';
        sidebar.style.marginLeft = 'auto';
      } else {
        sidebar.removeAttribute('style');
        newsWidget.removeAttribute('style');
      }
      if (tour._currentStep === 4) {
        newsWidget.style.position = 'absolute';
        newsWidget.style.top = '100px';
        newsWidget.style.width = this.isCollapsedNav ? '500px' : '400px';
      }

      if (tour._currentStep === 5) {
        contactWidget.style.position = 'absolute';
        if (innerHeight < 900) {
          contactWidget.style.bottom = '30px';
        }
        contactWidget.style.width = this.isCollapsedNav ? '500px' : '400px';
      } else {
        contactWidget.removeAttribute('style');
      }

      if (tour._currentStep === 6 || tour._currentStep === 7) {
        header.style.position = 'static';
        header.style.marginTop = '40px';
      } else {
        header.removeAttribute('style');
      }
      if (tour._currentStep === 6) {
        setTimeout(() => {
          this.menuDropdown.show();
          this.menuDropdown.autoClose = false;
          this.menuDropdown['_elementRef'].nativeElement.style.pointerEvents =
            'none';
        });
      } else {
        setTimeout(() => {
          this.menuDropdown.hide();
          this.menuDropdown.autoClose = true;
          this.menuDropdown['_elementRef'].nativeElement.removeAttribute(
            'style',
          );
        });
      }
      if (tour._currentStep === 7) {
        setTimeout(() => {
          this.userDropdown.show();
          this.userDropdown.autoClose = false;
          this.userDropdown['_elementRef'].nativeElement.style.pointerEvents =
            'none';
        });
      } else {
        setTimeout(() => {
          this.userDropdown.hide();
          this.userDropdown.autoClose = true;
          this.userDropdown['_elementRef'].nativeElement.removeAttribute(
            'style',
          );
        });
      }
    });
  }
  private handleExit(tour: any, onExit?: any) {
    const header = <HTMLDivElement>document.querySelector('app-header .navbar');
    const sidebar = <HTMLDivElement>document.querySelector('div .app-sidebar');
    const newsWidget = <HTMLDivElement>(
      document.querySelector('app-news-widget .news-widget')
    );
    const contactWidget = <HTMLDivElement>(
      document.querySelector('app-contact-widget .contact-widget')
    );
    tour.onbeforeexit(() => {
      (<any>window).scrollTo(0, 0);
      this.tourOpened = false;
      header.removeAttribute('style');
      sidebar.removeAttribute('style');
      newsWidget.removeAttribute('style');
      contactWidget.removeAttribute('style');
      setTimeout(() => {
        this.menuDropdown.hide();
        this.menuDropdown.autoClose = true;
        this.menuDropdown['_elementRef'].nativeElement.removeAttribute('style');
        this.userDropdown.hide();
        this.userDropdown.autoClose = true;
        this.userDropdown['_elementRef'].nativeElement.removeAttribute('style');
      });
      if (this.tourSubscription) {
        this.tourSubscription.unsubscribe();
      }
      if (onExit) {
        onExit();
      }
    });
  }
  setUserDropdown(dropdown: BsDropdownDirective) {
    this.userDropdown = dropdown;
  }
  setMenuDropdown(dropdown: BsDropdownDirective) {
    this.menuDropdown = dropdown;
  }

  getOptions(translations: any) {
    return {
      showProgress: false,
      exitOnEsc: false,
      exitOnOverlayClick: false,
      showStepNumbers: false,
      disableInteraction: true,
      nextLabel: `
        <span class="start-text">${translations.startLabel}</span>
        <span class="next-text">${translations.nextLabel}</span>
      `,
      skipLabel: translations.skipLabel,
      doneLabel: translations.doneLabel,
      prevLabel: translations.prevLabel,
    };
  }
  getSteps(translations: any) {
    const steps = [];
    steps.push({
      element: '#step1',
      intro: `
        ${
          !this.isBSB
            ? ` <img src="./assets/images/step1.png" alt="step1">`
            : `<img src="./assets/images/step1_bsb.png" alt="step1">`
        }
        <p class="introjs-title introjs-title--pt">${
          translations[!this.isBSB ? 'NEDASCO' : 'BSB'].step1_title
        }</p>
        <p>${translations[!this.isBSB ? 'NEDASCO' : 'BSB'].step1_content}</p>`,
      tooltipClass: 'step1 introjs-placement introjs-placement-bottom',
    });

    steps.push({
      element: '#step23',
      intro: `
        <p class="introjs-title">${
          translations[!this.isBSB ? 'NEDASCO' : 'BSB'].step2_title
        }</p>
        ${
          !this.isBSB
            ? ` <p>${translations.NEDASCO.step2_content}<br>
            ${translations.NEDASCO.step2_content2}
            <i class="nd-open-in-new"></i>
            ${translations.NEDASCO.step2_content3}</p>`
            : `<p>${translations.BSB.step2_content}</p>`
        }`,
      tooltipClass: 'step2 introjs-placement introjs-placement-right',
    });

    steps.push({
      element: '#step23',
      intro: `
        <p class="introjs-title">${
          translations[!this.isBSB ? 'NEDASCO' : 'BSB'].step3_title
        }</p>
       ${
         !this.isBSB
           ? `<p>${translations.NEDASCO.step3_content}<br>
          ${translations.NEDASCO.step3_content2}
          <i class="nd-open-in-new"></i>
          ${translations.NEDASCO.step3_content3}</p>`
           : ` <p>${translations.BSB.step3_content}</p>`
       }`,
      tooltipClass: 'step3 introjs-placement introjs-placement-right',
    });
    steps.push({
      element: '#step4',
      intro: `
        <p class="introjs-title">${
          translations[!this.isBSB ? 'NEDASCO' : 'BSB'].step4_title
        }</p>
         ${
           !this.isBSB
             ? `<p>${translations.NEDASCO.step4_content}<br>
            ${translations.NEDASCO.step4_content2}
            <i class="nd-open-in-new"></i>
            ${translations.NEDASCO.step4_content3}</p>`
             : ` <p>${translations.BSB.step4_content}</p>`
         }`,
      tooltipClass: 'step4 introjs-placement introjs-placement-right',
    });

    steps.push({
      element: '#step5',
      intro: `
        <p class="introjs-title">${
          translations[!this.isBSB ? 'NEDASCO' : 'BSB'].step5_title
        }</p>
        <p>${translations[!this.isBSB ? 'NEDASCO' : 'BSB'].step5_content}</p>`,
      tooltipClass: !this.isCollapsedNav
        ? 'step5 introjs-placement introjs-placement-left'
        : 'step5 is-nav-collapsed introjs-placement introjs-placement-left',
    });

    steps.push({
      element: '#step6',
      intro: `
        <p class="introjs-title">${
          translations[!this.isBSB ? 'NEDASCO' : 'BSB'].step6_title
        }</p>
        <p>${translations[!this.isBSB ? 'NEDASCO' : 'BSB'].step6_content}</p>`,
      tooltipClass: !this.isCollapsedNav
        ? 'step6 introjs-placement introjs-placement-left'
        : 'step6 is-nav-collapsed introjs-placement introjs-placement-left',
    });

    steps.push({
      element: '#step7',
      intro: `
        <p class="introjs-title">${
          translations[!this.isBSB ? 'NEDASCO' : 'BSB'].step7_title
        }</p>
        <p>${translations[!this.isBSB ? 'NEDASCO' : 'BSB'].step7_content}</p>`,
      tooltipClass: ' step7 introjs-placement introjs-placement-left',
    });
    steps.push({
      element: '#step8',
      intro: `
        <p class="introjs-title">${
          translations[!this.isBSB ? 'NEDASCO' : 'BSB'].step8_title
        }</p>
        <p>${translations[!this.isBSB ? 'NEDASCO' : 'BSB'].step8_content}</p>`,
      tooltipClass: 'step8 introjs-placement introjs-placement-left',
    });
    return steps;
  }
}
