<div
  class="exceptional-alert"
  [ngClass]="{'nav-collapsed': isCollapsedNav}"
  *ngIf="exceptionalAlert">
  <span class="exceptional-alert__icon"></span>
  <div class="exceptional-alert__content"
    [innerHTML]="exceptionalAlert.content"
  ></div>
</div>


