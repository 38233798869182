// tslint:disable-next-line:import-name
import gql from 'graphql-tag';

export const footerQuery = gql`
  query footer {
    footerLinks {
      title
      link
      type
    }
  }
`;

export const customerQuery = gql`
  query customer {
    customer {
      title
      url
      code
    }
  }
`;
