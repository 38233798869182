import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostListener,
  Input,
  OnDestroy,
  TemplateRef,
} from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

import { IArticle } from './article.interface';

@Component({
  selector: 'app-article',
  templateUrl: './article.component.html',
  styleUrls: ['./article.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ArticleComponent implements OnDestroy, AfterViewInit {
  modalRef: BsModalRef;
  modalContent: {
    type: 'image' | 'video';
    src: string | SafeResourceUrl;
  } | null;
  // productRequestProcedure - we need upgrade this logic.
  @Input() isMutation = false;
  @Input() articleType = '';
  @Input() title: string;
  @Input() category: string;
  @Input() date: Date;
  @Input() standalone: string;
  @Input() set content(data: string | IArticle) {
    let _content: IArticle;
    if (typeof data === 'string') {
      try {
        _content = JSON.parse(data);
      } catch (e) {
        console.log('Content have invalid format');
      }
    } else {
      _content = data;
    }
    if (this.isMutation) {
      _content = _content.filter(
        (item: any) => item.type !== 'product-request-procedure',
      );
    }
    if (!this.isMutation) {
      _content = _content.filter(
        (item: any) => item.type !== 'niet-volmacht-procedure',
      );
    }
    this._content = _content.map(i => {
      if (i.layout === 'column_block') {
        i.items = i.items.map(item => {
          if (item.type === 'video') {
            item.url = this.sanitizeVideo(<string>item.url);
          }
          return item;
        });
      }

      return i;
    });
  }

  _content: IArticle;

  constructor(
    private modalService: BsModalService,
    private sanitizer: DomSanitizer,
    private elementRef: ElementRef,
  ) {}

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: Event): void {
    const target: any = event.target as HTMLElement;
    if (target.tagName === 'A' && target.href && target.href.includes('#')) {
      event.preventDefault();
      const [url, anchorId] = target.href.split('#');
      if (url === window.location.href || target.href) {
        this.scrollToElement(anchorId);
      }
      const currentURL = window.location.href;
      // const element = this.elementRef.nativeElement.querySelector(`#${anchorId}`);
      const currentURLSegment = currentURL.split('#')[0];
      const newURL = `${currentURLSegment}#${anchorId}`;
      window.history.replaceState({}, '', newURL);
    }
  }

  scrollToElement(anchorId: string): void {
    const element = this.elementRef.nativeElement.querySelector(`#${anchorId}`);
    if (element) {
      const offset = -100;
      const topOffset =
        element.getBoundingClientRect().top + window.pageYOffset;
      window.scrollTo({ top: topOffset + offset, behavior: 'smooth' });
    }
  }

  ngAfterViewInit(): void {
    const headings = this.elementRef.nativeElement.querySelectorAll(
      'h1, h2, h3, h4',
    );
    headings.forEach((heading, index) => {
      const anchorId = `nsn-anchor-${index + 1}`;
      heading.setAttribute('id', anchorId);
    });
    if (window.location.href.includes('#')) {
      const [url, anchorId] = window.location.href.split('#');
      this.scrollToElement(anchorId);
    }
  }

  public sanitizeVideo(url: string) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
  public getColSize(array: any[]) {
    return `col-${Math.ceil(12 / array.length)}`;
  }
  openModal(template: TemplateRef<any>, type: 'image' | 'video', src: string) {
    this.modalContent = { type, src };
    this.modalRef = this.modalService.show(template, { class: 'video-modal' });
  }
  hideModal() {
    if (this.modalRef) {
      this.modalRef.hide();
      this.modalContent = null;
    }
  }

  ngOnDestroy() {
    this.hideModal();
  }
}
