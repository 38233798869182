// tslint:disable-next-line:import-name
import gql from 'graphql-tag';

export const contactQuery = gql`
  query departments {
    accountManagers {
      id
      name
      image
      email
      phone
      linkedin
    }
    departments {
      id
      title
      items {
        title
        phone
        email
        webadres
        webadrestekst
      }
    }
  }
`;

export const mapContactQuery = gql`
  query customer {
    customer {
      companyName
      postAddress
      visitorAddress
      iban
      bic
      kvk
    }
  }
`;
