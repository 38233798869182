import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { map } from 'rxjs/operators';

import { IHeaderResponse } from './header.interface';
import { headerQuery } from './header.query';

@Injectable()
export class HeaderService {
  constructor(private apollo: Apollo) {}

  getData() {
    return this.apollo
      .watchQuery<IHeaderResponse>({
        query: headerQuery,
      })
      .valueChanges.pipe(
        map(response => response.data),
        map(data => {
          data.usefulLinks = data.usefulLinks.map(link => {
            const newLink = { ...link };
            if (
              link.type === '_blank' &&
              link.link_url &&
              link.link_url.includes(`${location.host}/`)
            ) {
              newLink.type = '_self';
              newLink.link_url = link.link_url.split(location.host)[1];
            }
            if (link.type === '_self') {
              newLink.link_url = `/support/${link.link_url}`;
            }
            return newLink;
          });
          return data;
        }),
      );
  }
}
