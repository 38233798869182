<tr>
  <th scope="row" class="pl-3 align-middle text-nowrap">
    <img
      height="30"
      width="30"
      class="media-image"
      [src]="product.insurance_company.logo"
      alt=""
    />
    {{ product.insurance_company.title | htmlString }}
  </th>

  <td class="align-middle text-nowrap" *ngIf="!isMutation">
    {{ product.title | htmlString }}
  </td>

  <td class="align-middle text-nowrap" *ngIf="!isMutation">
    {{ 'products_page.filters.type.' + product.type | translate }}
  </td>

  <td class="align-middle text-nowrap">
    <a
      class="btn btn-sm btn-block btn-outline-primary"
      [href]="product.proxy_url || product.request_url || product.general_proxy_url"
      target="_blank"
      *ngIf="product.type === 'proxy'"
      (click)="setViewedItem()"
    >
      {{
        'products_list.buttons.' +
          ((product.proxy_url || (!product.proxy_url && !product.request_url)) ? 'direct_redirect' : 'redirect') | translate
      }}
    </a>
    <a
      class="btn btn-sm btn-block btn-outline-primary"
      [routerLink]="['/products/product/', product.slug]"
      *ngIf="product.type === 'non_proxy'"
      (click)="setViewedItem()"
    >
      {{
        'products_list.buttons.' +
          (isMutation ? 'proxy_redirect_mutation' : 'proxy_redirect')
          | translate
      }}
    </a>
  </td>

  <td class="pr-2 align-middle text-right text-nowrap" width="150" *ngIf="!isMutation">
    <a
      class="product-row__read-more"
      *ngIf="true"
      href="javascript:void(0)"
      (click)="expanded = !expanded; expanded && setViewedItem()"
    >
      <!-- prettier-ignore -->
      {{
          'products_list.buttons.' +
            (!expanded
              ? 'open_details'
              : 'close_details')
          | translate
        }}
      <i
        [ngClass]="'nd-chevron-' + (!expanded ? 'down-small' : 'up-small')"
      ></i>
    </a>
  </td>
</tr>

<ng-container *ngIf="expanded">
  <tr
    style="height: 6px; box-shadow: inset 0 6px 3px -4px rgba(89,84,84,0.3)"
  ></tr>

  <tr
    app-product-item-expanded
    [product]="product"
    [fullPageSize]="fullPageSize"
  ></tr>

  <tr
    style="height: 6px; box-shadow: inset 0 -6px 3px -4px rgba(89,84,84,0.3)"
  ></tr>
</ng-container>
