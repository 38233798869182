import { AfterViewInit, Component, ElementRef, OnInit } from '@angular/core';
import { CssVariablesService } from '@core/services/css-variables.service';
import { LoadConfig } from '@core/store/core-actions';
import { environment } from '@env/environment';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Apollo } from 'apollo-angular';
import { filter, pluck } from 'rxjs/operators';

import { IConfig } from './config/app.config.interface';
import { State } from './store/app-reducers';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements AfterViewInit, OnInit {
  hubSpotScriptId: string;
  configLoaded = false;
  constructor(
    private cssVariablesService: CssVariablesService,
    private store: Store<State>,
    private translate: TranslateService,
  ) {}

  ngOnInit() {
    this.translate.setDefaultLang(environment.lang);
    this.translate.use(environment.lang);
    this.store.dispatch(new LoadConfig());

    // Get config data from store
    this.store
      .select('core')
      .pipe(
        pluck('config'),
        filter(data => data !== null && typeof data !== 'undefined'),
      )
      .subscribe((data: IConfig) => {
        this.configLoaded = true;
        this.setCssVariables(data.cssVariables);
        this.hubSpotScriptId = data.additionalInfo.hubSpotScriptId;
        this.setFavicon(data.storeUrl);
        this.setWebsiteTitle(data.title);
      });
  }

  ngAfterViewInit() {
    this.store
      .select('core')
      .pipe(
        pluck('config'),
        filter(data => data !== null && typeof data !== 'undefined'),
      )
      .subscribe((data: IConfig) => {
        const s = document.createElement('script');
        s.type = 'text/javascript';
        s.id = 'hs-script-loader';
        s.src = `//js.hs-scripts.com/${data.additionalInfo.hubSpotScriptId}.js`;
        document.getElementsByTagName('head')[0].appendChild(s);
      });
  }

  setCssVariables(cssVariables) {
    this.cssVariablesService.setGlobalCssVariables(cssVariables);
  }

  setFavicon(imagePath) {
    const favicon = document.getElementById('favicon');
    favicon.setAttribute('href', `${imagePath}content/images/favicon.ico`);
  }

  setWebsiteTitle(newTitle) {
    document.title = newTitle;
  }
}
