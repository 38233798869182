<div class="shortcuts container-fluid" [ngClass]="'bg-' + background">
  <div class="row pb-5">
    <div class="col-12">
      <div class="row">
        <div class="col-12 col-md-4 mb-4">
          <div class="card p-3 shortcut-item">
            <p class="h3">{{ 'shortcuts.highlighted' | translate }}</p>
            <div class="card-body p-0">
              <div class="shortcut-content">
                <p class="h2">{{ (data$ | async)?.featuredItem?.title }}</p>
                <div [innerHTML]="(data$ | async)?.featuredItem?.text"></div>
              </div>
              <a
                [href]="(data$ | async)?.featuredItem?.link_url"
                target="_blank"
                class="btn btn-primary"
              >
                <i class="nd-download"></i>
                {{ (data$ | async)?.featuredItem?.link_text }}
              </a>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-4 mb-4">
          <div class="card p-3 shortcut-item">
            <p class="h3">{{ 'shortcuts.last_news' | translate }}</p>
            <div class="card-body p-0">
              <div class="shortcut-content">
                <ul class="list-group list-group-flush tails-list">
                  <li
                    class="list-group-item"
                    *ngFor="let item of (data$ | async)?.news?.items"
                  >
                    <a *ngIf="item?.title"
                      [routerLink]="['/news', item.slug]"
                      [title]="item.title">
                      {{ shortenText(item.title) }}
                      <i class="nd-chevron-right-small"></i>
                    </a>
                  </li>
                </ul>
              </div>
              <a [routerLink]="['/news']" class="btn btn-outline-primary">
                {{ 'shortcuts.all_news' | translate }}
              </a>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-4 mb-4">
          <div class="card p-3 shortcut-item">
            <p class="h3">{{ 'shortcuts.contact' | translate }}</p>
            <div class="card-body p-0">
              <div class="shortcut-content">
                <p class="h3 text-light mb-0">
                  {{ 'shortcuts.questions' | translate }}
                </p>
                <a class="mb-3 text-dark" [routerLink]="['/faq']">
                  {{ 'shortcuts.faq_link' | translate }}
                </a>
                <p class="h3 text-light mb-0">
                  {{ 'shortcuts.talk_to_us' | translate }}
                </p>
                <a class="mb-3 text-dark" [routerLink]="['/contact']">
                  {{ 'shortcuts.contact_link' | translate }}
                </a>
              </div>
              <a [routerLink]="['/contact']" class="btn btn-outline-primary">
                {{ 'shortcuts.contact_button' | translate }}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
